<template>
    <div id="layoutDefault_content" class="bg-light">
        <NavBar/>
        <Header title="Notificaciones" />
        <section class="pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-1">
                        
                    </div>
                    <div class="col-md-7">
                        <div class="chatbox" >
                            <div id="chatview" class="p1">
                                 <h4 class="pt-2 text-center" v-if="!messages.items.length || messages.items.length==0">No tienes notificaciones</h4>
                            
                                <div ref="chatelement" id="chat-messages" class="animate scrollable">
                                    <div  v-for="item in messages.items" v-bind:key="item.id" class="overflow-hidden p-1"  style="background: white" >
                                        <b-row no-gutters class="pl-3 pr-3">
                                            <b-col sm="2" v-if="isLocalUser(item)" class="col-xs-2" style="display: flex;align-items: center;">
                                                <b-avatar :src="getImageSrc(item)" size="3rem" />
                                            </b-col>
                                            <b-col v-else class="col-xs-2" style="display: flex;align-items: center;">
                                            </b-col>
                                            <b-col sm="8" class="col-xs-10 p-1" style="font-size: .8rem">
                                                <div :class="!isLocalUser(item)? 'text-right':'text-left'"  style="padding-right:15px">
                                                <div  class="title" ><b>{{getUserName(item)}}</b></div>
                                                <div>
                                                    {{item.message_body}}
                                                </div>
                                                <div v-if="item.file">
                                                   <a style="cursor:pointer;" @click="showImg(item.file)" > {{item.file.label}}</a> 
                                                </div>
                                                <small>{{getCreationDate(item)}}</small>
                                                </div>
                                            </b-col>
                                            <b-col sm="2" v-if="!isLocalUser(item)" class="col-xs-2 text-right " style="display: flex;align-items: center;">
                                                <b-avatar class=" text-right" :src="getImageSrc(item)" size="3rem"  />
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>

                                <div id="sendmessage">
                                    <form  v-on:submit.prevent="sendCommentHandle()">
                                    <input v-if="saving" v-model="form_message.message_body" type="text" disabled style="color:#4b4b4b;width:80%" placeholder="Enviando el mensaje" />
                                    <input v-if="!saving" v-model="form_message.message_body" type="text" style="color:#4b4b4b;width:80%" placeholder="Escriba un mensaje" />
                                    <button type="submit" class="mt-2" :disabled="saving || !form_message.message_body" id="send" @click="sendCommentHandle()">
                                        <b-icon icon="arrow90deg-right" variant="light"></b-icon>
                                    </button>
                                    <span class="mt-2" v-if="saving">
                                        <b-spinner class="ml-auto" variant="primary" label="Spinning"></b-spinner>
                                    </span>
                                    
                                    <!--
                                    <span style="font-size: 1.7rem;">
                                        <b-icon icon="arrow90deg-right" class="rounded-circle bg-info p-2" variant="light"></b-icon>
                                    </span>
                                    -->
                                    <span class="pull-rigth">
                                        <!--
                                        <b-button style="display: inline-block" pill variant="outline-danger">
                                            <b-icon icon="paperclip" variant="success"> </b-icon>
                                        </b-button>
                                        -->
                                    </span>
                                    <div style="padding:10px 20px">
                                            <b-form-file v-model="image_doc" placeholder="Adjunte un archivo"  id="file_message" size="sm"  accept="image/*" @change="onFileChange($event)" ></b-form-file>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <!--
                        <div class="card p-1">
                            <b-button-group size="sm">
                                <b-button>Sin leer</b-button>
                                <b-button>leido</b-button>
                                <b-button>Todo</b-button>
                            </b-button-group>
                        </div>
                        <div class="notifications__content">
                       
                            <div class="overflow-hidden p-1"  v-for="item in messages_unread" v-bind:key="item.id" >
                                <b-row no-gutters class="pl-3 pr-3"  style="background: white">
                                    <b-col sm="2" class="col-xs-2" style="display: flex;align-items: center;">
                                        <b-avatar :src="getImageSrc(item)"  size="2rem" />
                                    </b-col>
                                    <b-col sm="10" class="col-xs-10 p-1" style="font-size: .8rem">
                                        <div class="title"><b>{{getUserName(item)}}</b></div>
                                        <div class="overflow-hidden" style="height:25px">
                                            {{item.message_body}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        -->
                        <div class="notifications__content">
                       
                            <div class="overflow-hidden p-1"  >
                                <b-row no-gutters class="pl-3 pr-3"  style="background: white">
                                    <b-col sm="2" class="col-xs-2" style="display: flex;align-items: center;">
                                        <b-avatar :src="getImageSrcSession()"  size="2rem" />
                                    </b-col>
                                    <b-col @click="getConversation(user.id_person)" sm="10" class="col-xs-10 p-1" style="font-size: .8rem">
                                        <div class="title"><b>Beluva</b></div>
                                        <div class="overflow-hidden" style="height:25px">
                                            Notificaciones
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>

                    <b-modal id="modal-docs" :title="file_selected ? file_selected.label : ''" size="lg" hide-footer>
                        <div >
                            <div class="clearfix text-center">
                                <b-card-img style="max-width:400px !important" :src="file_selected ? file_selected.complete_path : ''" fluid></b-card-img>
                            </div>
                        </div>
                        <b-button class="mt-3 btn-success" block @click="$bvModal.hide('modal-docs')">Cerrar</b-button>
                    </b-modal>

                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>

<script>
import NavBar from '@/views/template/NavBar.vue';
import {Footer, Header} from '@/views/template';
import { UtilFile, UtilDate } from '@/helpers';

import { mapState, mapActions } from 'vuex'

export default {
    components:{
        NavBar,
        Header,
        Footer
    },
    data(){
		return {
            image_doc: null,
            file_selected: null,
		}
	},
    computed: {
    ...mapState({
			user: state => state.account.user,
			person: state => state.Message.person,
			person_session: state => state.Message.person_session,
			messages: state => state.Message.messages,
			form_message: state => state.Message.form_message,
			saving: state =>state.Message.form_message.saving   
        })
  },
  created () {
        this.getConversation(this.user.id_person);
        setTimeout(()=> {
            this.readAll(this.user.id_person);
            this.autoScrollChat(), 2000
        });
        
  },
  methods: {
      ...mapActions('Message', [
			'getConversation',
            'sendComment',
            'readAll',
    ]),
    onFileChange: function(event) {
        UtilFile.choosenFileTobs64(event).then(rsc_file=> {
            this.form_message.file = rsc_file;
        });
    },
    sendCommentHandle() {
        if(this.form_message.message_body){
            const data = {...this.form_message};
            console.log('enviando mensaje...');
            this.sendComment(data).then(()=> {
                this.image_doc = null;
                setTimeout(()=> this.autoScrollChat());
            });
		}
	},

	getImageSrcSession() {
		return this.person_session.avatar || require('@/assets/img/logo-beluva.jpg');
	},
    getImageSrc: function (item) {
		const person = this.getUser(item); 
		if(person && person._id == this.user.id_person){
			return this.user.photo || require('@/assets/img/user.png');
		}else{
			return this.getImageSrcSession();
		}
    },
    getSideComment: function (item) {
        return this.isLocalUser(item)? "right" : "";
    },
    getcolorUser: function (item) {
        return item.recipient_id === this.person.id ? "colorRed" : "colorBlue";
    },
    getCreationDate: function (item) {
       return UtilDate.frontEndDateTimeFormat(item.created_at); 
    },
    getUser(item){
		return this.isLocalUser(item)?  this.person: this.person_session;
    },
    getUserName(item){
      const person =  this.getUser(item); 
      return person.name + ' ' + (person.lastname || '')+ ' ' + (person.second_lastname || '');
	},
	isLocalUser(item){
		return item.id_person_created === this.person.id;
    },

	autoScrollChat: function () {
        var objDiv = document.getElementsByClassName("scrollable");
        if (objDiv[0]) {
            var h = objDiv[0].scrollHeight;
            objDiv[0].scrollTop = h;
        }
    },
    showImg(file){
        this.file_selected=file;
        this.$bvModal.show('modal-docs');
    }
  }
};
</script>
<style>

 :root {
  /* --chat-bg-color: #2A3F54;*/
  --chat-bg-color: rgba(42,63,84,.92);
  /* --chat-width: 390px; */
  --chat-width: 100%;
}	

.notifications__content {

  border-radius: 5px;
  box-shadow: 0px 15px 20px 0px rgb( 0, 0, 0, .2 );

  transition: all .3s ease-in;
  cursor: pointer;
}



.chatbox {
	width: var(--chat-width);
	background: #fff;
	/* border-radius: 6px;*/
	overflow: hidden;
	height: 484px;
	/*position:absolute;
top:100px;
left:50%;
margin-left:-155px;*/
	display: inline-block;
	vertical-align: bottom;
	position: relative;
	/* margin: 0 5px;*/
	pointer-events: auto;
}


#chatview {
	width: var(--chat-width);
	height: 484px;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
}



#chat-messages {
	opacity: 0;
	margin-top: 30px;
	width: var(--chat-width);
	/* height: 270px;*/
	height: 350px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 20px;
	-webkit-transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	-moz-transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	-ms-transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	-o-transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
}

#chat-messages.animate {
	opacity: 1;
	margin-top: 0;
}


#sendmessage {
	height: 90px;
	border-top: 1px solid #e7ebee;
	position: absolute;
	bottom: 0;
	right: 0px;
	width: var(--chat-width);
	background: #fff;
	padding-bottom: 50px;
}

#sendmessage input {
    /*width: 90%;*/
    background: #fff;
    margin: 21px 0 0 21px;
    border: none;
    padding: 0;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400px;
    color: #aab8c2;
}

#sendmessage input:focus {
    outline: 0;
}

#sendmessage button {
    /*background: #fff url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/send.png") 0 -41px no-repeat;
    */
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    /*top: 23px;*/
    border: none;
    border-radius: 50%;
    background: rgba(167, 125,17, .9);
    color: rgb(138, 71, 71);
    box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.38);
}

#sendmessage button:hover {
    background: rgba(167, 125,17, 1);
    cursor: pointer;
    background-position: 0 0;
}

#sendmessage button:focus {
    outline: 0;
}

</style>