import { beneficiaryService, personService, cat_CatalogService } from '../services';
import { UtilDate } from '../helpers';//, Messaging

const user = JSON.parse(localStorage.getItem('user'));
const state = {
    message: {},
    person: {
        updating: false,
        item: null
    },
    message_error: '',
    //submitted: false,
    file_birth_certificate:{
    },
    base_file: '',
    cat_addres: { 
        requesting: false,
        cat_countries: [],
        cat_states: [],
        cat_municipies: [], 
        cat_localities: []
    },
    cat_relation: [],
    user: user,
    beneficiarys: {
        requesting: false,
        items: []
    },
    headline: {}
};

const actions = {    
    getCatCountry({ commit }) {
        commit('getCatCountryRequest');
        cat_CatalogService.getCat_CatalogByCodeType('SYST_TYPE_COUNTRY')
        .then(
            catalogs => commit('getCatCountrySuccess', catalogs),
            error => commit('getCatCountryFailure', error)
        );
        
    },
    getCatalogsAddresByPostCode({ commit }, postal_code) {
        commit('getCatalogsAddresByPostCodeRequest');

        cat_CatalogService.getCat_CatalogAddresByPostCode(postal_code)
            .then(
                data => commit('getCatalogsAddresByPostCodeSuccess', data),
                error => commit('getCatalogsAddresByPostCodeFailure', error)
            );
    },
    getPersonRelation({ commit, dispatch }, data) {
        dispatch('alert/success', {message: '', code: ''}, { root: true });
        personService.getPersonDefault(data.id, data.idHeadline)
            .then(
                async person => { 
                    if(!data.id && person.headline && person.headline.address  && person.headline.address.post_code){
                        cat_CatalogService.getCat_CatalogAddresByPostCode(person.headline.address.post_code)
                        .then(
                            data_cat => {
                                commit('getPersonSuccess', {person: person, id: data.id, url: person.url});
                                commit('getCatalogsAddresByPostCodeSuccess', data_cat);                                
                            },
                            error => commit('getCatalogsAddresByPostCodeFailure', error)
                        );
                    }else{
                        commit('getPersonSuccess', {person: person, id: data.id, url: person.url});
                    }             
                    
                },
                error => commit('getPersonFailure', error)
            );
    },
    async SaveBeneficiary({ dispatch, commit }, beneficiary) {
        return new Promise(resolve=> {
            commit('updateRequest', beneficiary);
            beneficiaryService.saveBeneficiary(beneficiary.id_person, beneficiary.beneficiary, beneficiary.file)
            .then(
                person => {
                    // this.updateFilePerson()
                    commit('updateSuccess', person); 
                    dispatch('alert/success', {message: 'Beneficiario registrado correctamente', code: ''}, { root: true });
                    dispatch('getallBeneficiarysByPerson', beneficiary.id_person);
                    resolve();
                },
                error =>{
                    dispatch('alert/error', { message: error, code: 'BENEFICIARY' }, { root: true });
                    commit('updateFailure', { error: error.toString() })
                }
            );
        });
    },
    getallBeneficiarysByPerson({ commit }, id) {
        commit('getBeneficiaryListRequest');
        beneficiaryService.getBeneficiarys(id)
            .then(
                beneficiarysList => {
                    commit('getBeneficiaryListSuccess', beneficiarysList);
                },
                error => commit('getPersonFailure', error)
            );
    },
    getCatRelationType({ commit }) {
        cat_CatalogService.getCat_CatalogByCodeType('SYST_TYPE_REL')
        .then(
            catalogs => commit('getCatRelationTypeSuccess', catalogs),
        );
    },
    async deleteBeneficiaryByPerson({ dispatch }, data) {
        beneficiaryService.deleteBeneficiary(data.id_person, data.id_beneficiary)
            .then(
                () => {
                    dispatch('alert/success', {message: 'Beneficiario eliminado correctamente', code: ''}, { root: true });
                    dispatch('getallBeneficiarysByPerson', data.id_person);
                },
                error => {
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },
    async getDefaultFilePerson({ commit, dispatch }, data) {
        personService.getDefaultFilePerson(data.file, data.stored_file)
            .then(
                files => commit('updateFilePersonSuccess', files),
                error =>dispatch('alert/error', { message: error, code: '' }, { root: true })   
            );
    },
    async updateFilePerson({ commit, dispatch }, data) {
        personService.updateFilePerson(data.id_person, data.file)
            .then(
                files => commit('updateDocumentPersonSuccess', files),
                error =>dispatch('alert/error', { message: error, code: '' }, { root: true })   
            );
    },    
    deleteFilePerson(){
        state.file_birth_certificate = {};
    },

    SOCKET_RELATIONPERSON_NEW({dispatch, rootState}) {
        const user = rootState.account.user;
        dispatch('getallBeneficiarysByPerson',user.id_person);
    },
    
};

const mutations = {
    getPersonRequest(state) {
        state.person.loading = true;
    },
    getPersonSuccess(state, data) {
        data.person.person.birthdate = data.person.person.birthdate ? UtilDate.frontEndDateFormat(data.person.person.birthdate) : '';
        data.person.person.address = data.person.person.address || {};
        //data.person.address.cat_locality =  data.person.person.address.cat_locality || {}
        data.person.person.category_relation = data.person.relation || {};

        // copiar dirección si es nuevo registro
        if(!data.id && data.person.headline.address){
            data.person.person.address = {
                ... data.person.headline.address,
                _id: null,
                created_at: null,
                updated_at: null,
                is_sync: false
            };
        }

        state.person = { item: data.person.person, updating: false };
        state.file_birth_certificate = data.person.file || {}; 
        state.base_file = data.url;
    },
    getPersonFailure(state) {
        state.person.updating = false;
    },

    updateRequest(state) {
        state.person.updating = true;
        state.message_error = "";
        //state.submitted = true;
    },
    // eslint-disable-next-line
    updateSuccess(state) {
        state.person.updating = false;
        //state.submitted = false;
    },
    updateFailure(state, error) {
        state.person.updating = false;
        state.message_error = error.error
        //state.submitted = false;
    },

     // eslint-disable-next-line
    getCatCountryRequest(state) {
        // state.cat_addres.requesting = true;
    },
    getCatCountrySuccess(state, data) {
        state.cat_addres.cat_countries = data;
    },
     // eslint-disable-next-line
    getCatCountryFailure(state) {
        // state.cat_addres.requesting = false;
    },
    
    getCatalogsAddresByPostCodeRequest(state) {
        state.cat_addres.requesting = true;
    },
    getCatalogsAddresByPostCodeSuccess(state, data) {
        if(data){
            const addres_api =  data[0] || {}; 
            state.cat_addres.cat_localities = data[1] || [];
            state.cat_addres.cat_municipies = data[2] || [];
            state.cat_addres.cat_states = data[3] || [];

            state.person.item.address.cat_country = state.cat_addres.cat_countries.find(x=> x.id === addres_api.id_cat_country);
            state.person.item.address.cat_state = state.cat_addres.cat_states.find(x=> x.id === addres_api.id_cat_state);
            state.person.item.address.cat_municipality = state.cat_addres.cat_municipies.find(x=> x.id === addres_api.id_cat_municipality);
            
            state.cat_addres.requesting = false;
        }        
    },
    getCatalogsAddresByPostCodeFailure(state) {
        state.cat_addres.cat_localities = [];
        state.cat_addres.cat_municipies = [];
        state.cat_addres.cat_states = [];

        state.person.item.address.cat_state = null;
        state.person.item.address.cat_municipality = null;
        state.person.item.address.cat_locality = null;

        state.cat_addres.requesting = false;
        // Messaging.makeToast(`No se encontro el codigo postal ${state.person.item.address.post_code}`,'Código postal' ,'warning');
    },


    getBeneficiaryListRequest(state) {
        state.beneficiarys.requesting = true;
    },

    getBeneficiaryListSuccess(state, beneficiarysList) {
        state.beneficiarys.items = [];
        if(beneficiarysList){
            beneficiarysList.forEach(element => {
                if(element.birthdate){
                    element.birthdate = UtilDate.frontEndDateFormat(element.birthdate);                    
                }
            });
            state.beneficiarys.items.push(...beneficiarysList);
            state.beneficiarys.requesting = false;
        } 
    },
    getCatRelationTypeSuccess(state, data) {
        state.cat_relation = data;// data ? data.filter(x => ["SYST_TYPE_REL_02","SYST_TYPE_REL_01","SYST_TYPE_REL_04"].includes(x.code)) : [];
    },
    updateDocumentPersonSuccess(state, files) {
        state.file_birth_certificate = files && files.file ? files.file : {};
        state.base_file = files && files.url ? files.url : '';
        /*state.person.item._files.forEach(element => {
            files.forEach(fil => {
                if(element.code == fil.cat_row_entity.code){
                    element.complete_path = element.server+'/'+fil.path_file;              
                }
            });            
        });*/
    },
    updateFilePersonSuccess(state, files){
        console.log("files acta nacimiento > ", files)
        state.file_birth_certificate = files && files.file ? files.file : {};
        state.base_file = files && files.url ? files.url : '';
    },

    // Socket events
    SOCKET_RELATIONPERSON_UPDATE(state, person) {
        let exist = state.beneficiarys.items.find(x=> x.id === person._id);
        if(exist)  {
            exist.birthdate = UtilDate.frontEndDateFormat(person.birthdate);
            exist.name_completed = person.name + ' ' + person.lastname + ' '+  person.second_lastname;
            exist.phone1 = person.phone1;
        }
    },
};

export const Beneficiary = {
    namespaced: true,
    state,
    actions,
    mutations
};