<template>
    <div class="seccion" id="vacantes">
        <h2>Vacantes</h2>

        <form @submit.prevent="handleSubmit" id="listadovacantes">        
        <p class="formfield">
            <label for="ciudadvacante">Ciudad </label>
            <multiselect 
            style="width:40%; display:inline-block"
            name="ciudadvacante" id="ciudadvacante"
            v-model="_places.place_selected"
            :options="places" 
            :loading="getting_places"
            :searchable="true"
            label="place" track-by="place" 
            placeholder="Seleccione una ciudad"></multiselect>
        </p>

        <div class="vacantes" v-if="_places.place_selected" >
            <div v-for="(vacant, i) of _places.place_selected.items" v-bind:key="i" class="vacante" id="careersresult">
                <div class="titulovacante">
                    <p class="formfield">                           
                        <b-form-radio v-model="vacant_selected"  name="some-radios" :value="vacant.id">{{vacant.position}}</b-form-radio>
                    </p>
                </div>
                <ul class="funciones">
                    <li class="funcion">
                        {{vacant.position}}
                    </li>
                </ul>

                <ul class="requerimientos">
                    <li v-for="requirement of getRequirments(vacant)" v-bind:key="requirement.id" class="requerimiento">
                        <i class="far fa-check-circle"></i>
                        {{requirement}}
                    </li>
                </ul>
            </div>
        </div>

        <h3 v-if="!getting_places && !(places && places.length)">No existen vacantes disponibles.</h3>

        <template v-if="places && places.length">
            <button class="default btn-file" id="adjuntarcurriculum" style="padding: 7px">
                <b-spinner v-show="sendingCurriculum" small type="grow"></b-spinner>
                <i class="fas fa-upload"></i> 
                <span  v-bind:style="{ color: submitted && !file_curriculum?'red':'', }" > Adjuntar curriculum</span>
                <b-form-file :disabled="sendingCurriculum" class="btn btn-primary btn-file" 
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                v-model="file_curriculum" plain></b-form-file>
                <!--
                <span style="font-size: .8em" v-if="file_curriculum" >{{file_curriculum.name}}</span>
                -->
            </button>
            <span v-if="!file_curriculum && vacant_selected" class="invalid-feedback" >  &nbsp;&nbsp; Agrege su curriculum</span>
            
            
            <button :disabled="sendingCurriculum" class="enviar" > 
                <b-spinner v-show="sendingCurriculum" small type="grow"></b-spinner>
                Enviar
            </button>
        </template>
        </form> 
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Messaging } from '@/helpers';

export default {
    data () {
        return {
            submitted: false,
            vacants: [],
            vacant_selected: '',
            file_curriculum: null,
        }
    },
    watch: {
		'_places.place_selected': function(){
			this.vacant_selected = ''; 
		}
	},
    computed: {
        ...mapState({
            getting_places: state => state.Vacant.places.loading,
            places: state => state.Vacant.places.items,
            _places: state => state.Vacant.places,
            sendingCurriculum: state => state.Vacant.sendingCurriculum,
        }),
        ...mapState('account', ['status', 'user']),
    },
    created () {
        this.getVacants();
    },
    methods: {
        ...mapActions('Vacant', ['getVacants', 'sendCurriculum']),
        getRequirments(vacant){
            return vacant.requirement ? vacant.requirement.split(',') :[];
        },
        handleSubmit () {
            this.submitted = true;
            if(!this.vacant_selected) {
                Messaging.makeToast('Seleccione una vacante','Error' ,'danger');
            }

            let vacant = this._places.place_selected.items.find(x=> x.id === this.vacant_selected);
            if (vacant && this.file_curriculum) {
                const data = { ...vacant, curriculum: this.file_curriculum};
                this.sendCurriculum(data).then(()=>{
                    this.vacant_selected = '';
                    this.file_curriculum = null;
                    this.submitted = false;
                });
            }
        }
    }
};
</script>