import { MessageService } from '../services';
import { Messaging } from '../helpers';

const state = {
    is_minimize: false,
    messages: { items: [] , pagination: {page: 1, row: 5, total_rows:0} },
    messages_unread: [],
    person: { user_image: ''},
    person_session: {},

    form_message: { 
        saving: false,
        id_cat_category_message: '',
        subject: 'Notificaión',
        id_person_created: '',
        label_from: '',
        code: '',
        recipients: []
    },
    form_recipent: {
        recipient_id: '',
        is_read: false,
        status: 'SENT',
    }
};

const actions = {
    
    getConversation({ commit, state }, {id_person, page}) {
        commit('getConversationRequest');
        const rows = state.messages.pagination.row;
        // page = page || state.messages.pagination.page;
        MessageService.getPaginateMessagePerson(page, rows, null, id_person)
            .then(
                data => commit('getConversationSuccess', {data, id_person}),
                error => commit('getConversationFailure', error)
            );
    },

    sendComment({ dispatch, commit, state }, data) {
        return new Promise(resolve => {
            commit('sendCommentRequest', data.message_body);
            
            MessageService.register({...state.form_message})
                .then(
                    _message => {
                        commit('sendCommentSuccess', _message); 
                        dispatch('getConversation', _message.id_person_created);
                        resolve();
                    },
                    error => commit('sendCommentFailure', { error: error.toString() })
                );
        });
    },  

    readAll({ dispatch}, id_person) {
        MessageService.readAll(id_person)
            .then(
                () => {
                    dispatch('getConversation',id_person);
                }
            );
    },  
    
    highlightMessage({ commit }, {item, action}) {
        const highlight = action == 'IMPORTANT' ? !item.recipients[0].is_remarkable: !item.recipients[0].is_read;
        MessageService.highlightMessage(item._id, highlight, action)
        .then(message => {
            commit('highlightMessageSuccess', {message, action});
        })  
    },

    // Socket events
    SOCKET_MESSAGE_NEW({dispatch, rootState}, message) {
        const user = rootState.account.user;
        const isforcurrent = message.recipients.find(x => x.recipient_id == user.id_person);
        if(isforcurrent)  {
            dispatch('getConversation',{id_person: user.id_person});
        } 
    },
    
};

const mutations = {
    getConversationRequest(state) {
        state.messages.loading = true;
    },
    getConversationSuccess(state, {data, id_person}) {
        state.messages.loading = false;
        state.messages.items = data.data; //.reverse();
        state.messages.pagination = data.pagination;
        // state.person = data.person;
        // state.person_session = data.person_session;
        state.messages_unread =  state.messages.items.filter(x=> x.recipients[0] && !x.recipients[0].is_read && x.id_person_created!=id_person);
    },
    getConversationFailure(state, error) {
        state.messages = { error };
    },

    sendCommentRequest(state, message_text){
        state.form_message.id_cat_category_message = '4028ea8264ae3f4d0164ae47005e0007';
        state.form_message.id_person_created = state.person._id;
        state.form_message.label_from = state.person.name+ '  ' + state.person.lastname + ' ' + state.person.second_lastname;
        state.form_message.message_body = message_text;

        state.form_recipent.recipient_id = state.person._id;
        state.form_message.recipients = [ state.form_recipent ];
        state.form_message.saving = true;
    },
    sendCommentSuccess(state) {
        state.form_message.message_body = '';
        state.form_message.file = null;
        state.form_message.saving = false;
    },
    sendCommentFailure(state, {error}) {
        state.form_message.saving = false;
        Messaging.makeToast(error, 'Mensaje', 'danger');
    },


    highlightMessageSuccess(state, {message, action}) {
        state.messages.items = state.messages.items.map(x=> x._id === message._id ? message : x );
        if(action=='IMPORTANT' && message.recipients[0].is_remarkable){
            Messaging.makeToast('Marcado como ' + (action=='IMPORTANT'? 'Importante': 'Leido'),'Mensaje ' ,'success');
        }else if(message.recipients[0].is_read){
            Messaging.makeToast('Marcado como ' + (action=='IMPORTANT'? 'Importante': 'Leido'),'Mensaje ' ,'success');
        }
    },
    
    /*
    // Socket events
    SOCKET_MESSAGE_NEW(state, message) {
        const isforcurrent = message.recipients.find(x => x.recipient_id == state.person._id);
        if(isforcurrent)  {
            const exist = state.messages.items.find(x=> x._id === message._id);
            if(!exist){
                state.messages.items.push(message);
                state.messages_unread.push(message);
            }
        }
        
    },*/
};

export const Message = {
    namespaced: true,
    state,
    actions,
    mutations
};
