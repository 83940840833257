<template>
    <div class="container">
        <footer>
            <div class="foot-logo">
                <img class="logo" src="@/assets/img/logobelu.png" alt="">
            </div>
            <div class="foot-menu">
                <nav>
                    <ul>
                        <li ><router-link to="/" class="simple-link"><b>Inicio</b></router-link></li>
                        <li><router-link to="/about" class="simple-link">Nosotros</router-link></li>
                        <li><router-link to="/positions" class="simple-link">Vacantes</router-link></li>
                        <li><router-link to="/contact" class="simple-link">Contacto</router-link></li>
                        <li><router-link to="/terms" class="simple-link">Terminos y condiciones</router-link></li>
                        <li><router-link to="/privacy" class="simple-link">Politicas de privacidad</router-link></li>
                    </ul>
                </nav>
            </div>
            <div class="social-icons">
                <ul>
                    <!--
                    <li><a :href="app_social_link.tw"><i class="fab fa-twitter"></i></a></li>
                    <li><a :href="app_social_link.fb"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a :href="app_social_link.ig"><i class="fab fa-instagram"></i></a></li>
                    <li><a :href="app_social_link.li"><i class="fab fa-linkedin-in"></i></a></li>
                    -->
                    <li v-for="item of cat_social_link" v-bind:key="item.id">
                        <a :href="item.description" target="_blank">
                            <i :class="item.alias1"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="cw">
                <p>Copyright © Beluva 2021</p>
            </div>
        </footer>

        <div><!-- no quitar se coloca para que las imagenes en los envio de correo aparezca la imagen -->
            <img src="@/assets/img/logo-gris.png" heigth="1"  width="1" alt="" loading="lazy">
        </div>
        <ChatConversation/>
    </div>
</template>

<script>
import ChatConversation from "@/components/ChatConversation.vue";
import { mapState, mapActions } from "vuex";

export default {
    components: {
        ChatConversation
    },
    computed: {
        ...mapState({
            cat_social_link: state => state.ContactMessage.cat_social_link.items,
        }),
    },
     mounted() {
        this.getCatSocialLinks();
    },
    methods: {
        ...mapActions("ContactMessage", ["getCatSocialLinks"]),
    }
}
</script>
<style  scoped>
/*
footer {
    position: fixed;
    height: 100px;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: blanchedalmond;
}*/
</style>