import Vue from "vue"
Vue.filter("toUpper", str => str? str.toUpperCase(): str)
Vue.filter("toLower", str => str? str.toLowerCase(): str)
Vue.filter("first4Chars", str => str.substring(0, 4))
Vue.filter("last4Chars", str => str.substring(str.length - 4))
Vue.filter('toCurrency', function (value) {
    if(!value) return '';
    
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('toAffiliationStatus', function (status) {
    if (status === "PROCESS" ||status ==="AUTHORIZED") {
        return "VIGENTE";
    }
    else if (status === "CLOSED" || status === "CERRADO") {
        return "FINALIZADO";
    }
    else return status;
});

Vue.filter('lendusDate', function (date) {
    return String(new Date(date).toISOString()).split('T')[0];
});

Vue.filter('sqlToCustomDate', function (sql_string) {
    const parts = sql_string? sql_string.split('-') : [];
    return parts.length> 1 ? parts[2]+ "/" +  parts[1] + "/" +  parts[0] :'';
});