import { HttpClient } from './HttpClient' 

export const personService = {
    register: (person) => HttpClient.post('/person', person),
    getAll: () => HttpClient.get(`/person`),
    getById: (id) => HttpClient.get(`/person/${id}`),
    update: (person) => HttpClient.put('/person', person),
    delete: (id) => HttpClient.delete(`/person/${id}`),
    updateImageProfile: (id_person, file) => { 
        let formData = new FormData()
        formData.append('avatar', file); 
        return HttpClient.putFormData(`/person/image/${id_person}`, formData)
    },
    getPersonProfile: (id) => HttpClient.get(`/person/profile/${id}`),
    updatePersonProfile: (person) => HttpClient.put(`/person/profile/${person._id}`, person),
    updateDocumentPerson: (id_person, id_catalog, file) => {
        let formData = new FormData();
        formData.append('document', file);
        return  HttpClient.putFormData(`/person/document/${id_person}/${id_catalog}`, formData);
    },
    deleteDocumentPerson: (id_person, id_catalog) => HttpClient.delete(`/person/document/${id_person}/${id_catalog}`),
    getPersonDefault: (id, idHeadline) => HttpClient.postJson('/person/relation/register', {id:id, idHeadline: idHeadline}),
    SyncPerson: (id) => HttpClient.get(`/sync/person/${id}`),
    updatePreferences: (person) => HttpClient.put(`/person/preferences/${person._id}`, person),
    updateFilePerson: (id_entity, file) => {
        let formData = new FormData();
        formData.append('document', file);
        return  HttpClient.putFormData(`/person/file/${id_entity}`, formData);
    },
    getDefaultFilePerson: (file) => {
        let formData = new FormData();
        formData.append('document', file);
        return  HttpClient.putFormData(`/person/file/default`, formData);
    },
    getPersonDataById: (id_person) => HttpClient.post(`/get/person/id`, {id: id_person}),
    // eslint-disable-next-line
    getFileWitoutSession: (file, stored_file) => {
        //console.log(stored_file);
        let formData = new FormData();
        formData.append('document', file);
        //formData.append('stored_file', stored_file);
        return  HttpClient.putFormData(`/person/file/session`, formData);
    },
};
