<template>
  <div v-if="alert && alert.message" :class="`alert alert-${alert.type} alert-dismissible`" role="alert">
    <span v-html="alert.message"></span>
    <button @click="alert.message= ''" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    alert: Object,
  },
}
</script>
