import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./filters"
import "./validators"

import Multiselect from 'vue-multiselect'

Vue.directive('uppercase', (el, binding) => {
  // let processedValue = el.value ? el.value.toUpperCase() :'';
  let processedValue = el.value.toUpperCase();
  el.value = processedValue;
  binding.value = el.value;
});

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// import 'bootstrap/dist/css/bootstrap.css' // COMENTAR CUANDO SE TENGA TODOS LOS CSS
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
Vue.config.productionTip = true

import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect)

import VueSocketIO from 'vue-socket.io'
import socketio from 'socket.io-client'

const SocketOptions = {
  secure: false,
  query: {
    user: window.localStorage.getItem('user')
  }
};

Vue.use(new VueSocketIO({
  debug: false,
  connection: socketio(`${process.env.VUE_APP_API_ENDPOINT}`, SocketOptions),
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
}));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
