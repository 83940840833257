<template >
  <button v-if="action == 'REGISTER'" :disabled="disabled || loading" @click="loginProvider()"  :class="provider_name=='google'? 'google btn':'fb btn'">
    <b-spinner v-show="loading" small type="grow"></b-spinner> 
    <i :class="icon"></i> Registrate con {{capitalizeFirstLetter(provider_name)}}
  </button>
  <a v-else :disabled="disabled || loading" @click="loginProvider()" :class="provider_name=='google'? 'google btn':'fb btn'">
    <b-spinner v-show="loading" small type="grow"></b-spinner> 
    <i :class="icon"></i> Inicia Sesión con {{capitalizeFirstLetter(provider_name)}}
  </a>
</template>

<script>
export default {
  data(){
    return {
      loading: false
    }
  },
  props: {
    icon: String,
    provider_name: String,
    disabled: Boolean,
    action: String
  },
  methods: {
    loginProvider() {
        this.loading = true;
        // this.$router.push('http://localhost:3001/api/auth/' + provider_name);
        window.location.href = `${process.env.VUE_APP_API_ENDPOINT}/api/auth/${this.provider_name}`;
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>
