const state = {
    variant: null,
    message: null,
    title: null,
    show: false,
};

const actions = {
    makeToast({ commit }, toast_data) {
        commit('makeToast', toast_data);
    },
};

const mutations = {
    makeToast(state, toast_data) {
        state.variant = toast_data.variant;
        state.message = toast_data.message;
        state.title = toast_data.title;
        state.show = true;
    },
};

export const toast = {
    namespaced: true,
    state,
    actions,
    mutations
};