<template>
  <div class="seccion" id="nosotros">
    <h2>Contrato</h2>
    <div class="wrapper">
      <div class="box-valores" style="max-width: 100%">
        <h3>Póliza Familiar de Prestación de Servicios Funerarios</h3>
        <p>
         Por medio del presente contrato se hacen vigentes las siguientes declaraciones:
        </p>
        <p>
          Cubre el gasto funerario del titular, padres, cónyuge del titular, hijos con capacidades diferentes que dependen al 100% del cuidado de sus padres o tutor sin límite de edad, hijos menores de 25 años (solteros y dependientes económicos), independientemente del número de servicios requeridos y modalidad elegida (cremación o entierro) durante la vigencia ANUAL renovable.
        </p>

        <p>
          INCLUYE LOS SIGUIENTES SERVICIOS EN FUNERARIAS CON CONVENIO VIGENTE:
        </p>
        <p>
          •	Servicio de carroza para recolección y traslado del cuerpo, con un recorrido máximo de 35 kilómetros, si fuera mayor a lo estipulado el costo será a cuenta del contratante con un costo de $35.00 pesos por kilómetro extra.<br />
          •	Sala de velación (según disponibilidad del municipio). <br />
          •	Servicio de cafetería básica (aplica en velación en instalaciones de funeraria).<br />
          •	Servicio de velación en funeraria.<br />
          •	Arreglo estético del cuerpo.<br />
          •	Embalsamamiento. <br />
          •	Asesoría en trámites municipales (los pagos son por cuenta del contratante).<br />
          •	Incluye urna o ataúd básico.<br />
          •	Registro a asentamiento de defunción (solo aplica en algunos municipios). <br />
        </p>
        <p>
          Para solicitar el servicio se requiere estar al corriente de sus pagos, haber transcurrido un lapso mínimo de 90 días a partir de la primera contratación, presentar el último recibo de pago y los documentos correspondientes.
        </p>
        <p>
         **De no renovar en tiempo y forma se cobrará renovación extemporánea para conservar antigüedad, de lo contrario se emitirá como nueva.
        </p>

        <br />
        <p>CLÁUSULAS</p>
        <p>
          <b>PRIMERA:</b> Prevención Familiar Beluva S.A de C.V en lo sucesivo BELUVA, se obliga a proporcionar los servicios enumerados al frente de la presente póliza a través de una funeraria con la que tenga convenio en el estado y/o municipio.
        </p>
        <p>
          <b>SEGUNDA:</b> Si el titular se encontrase en cualquiera de los siguientes casos relativos a su estado civil, casado, viudo, la póliza protege al titular, padres, cónyuge del titular, hijos con capacidades diferentes que dependen al 100% del cuidado de sus padres o tutor sin límite de edad, hijos menores de 25 años (solteros y dependientes económicos), para el caso de concubinato o unión libre presentar identificación o documento que acredite domicilio en común.
        </p>
        <p>
          <b>TERCERA:</b> BELUVA solicitará la siguiente documentación para otorgar el servicio que sea requerido
        </p>
        <p>
          •	El servicio deberá ser contratado personalmente por el titular, familiar cercano o contratante del servicio (por teléfono solo se dará asesoría).<br />
          •	Certificado médico de defunción.<br />
          •	Acta de matrimonio (según sea el caso).<br /> 
          •	Carta de concubinato. <br />
          •	Comprobación del concubinato.<br />
          •	Acta de nacimiento.<br />
          •	Identificación oficial.<br />
          •	Póliza de servicios funerarios vigente con su pago acreditado.<br />
        </p>
        <p>
          <b>CUARTA:</b> En caso de solicitar un servicio diferente al contratado, este tendrá un costo adicional, que dependerá de la funeraria con la que se lleve a cabo el servicio, el ataúd que se proporcionará es básico, en caso de requerir un ataúd diferente, tendrá un costo adicional para el titular. 
        </p>
        <p>
          <b>QUINTA:</b> Si el fallecimiento es por accidente, desde el momento de la contratación BELUVA proporcionará los servicios. En caso de muerte por enfermedad, preexistencia de esta o por muerte natural, tendrán que transcurrir noventa días desde la fecha de contratación para que BELUVA otorgue el servicio a través de un tercero. En ambos escenarios se tendrá que tener la cuota anual cubierta.
        </p>
        <p>
          <b>SEXTA:</b> BELUVA no tendrá ninguna responsabilidad con el titular y/o sus beneficiarios de la presente póliza en caso de guerra, insurrección, epidemias, terremotos o cualquier situación que suspenda las garantías individuales de los ciudadanos.
        </p>
        <p>
          <b>SEPTIMA:</b> La póliza tiene vigencia por un año a partir de su contratación y surte efecto al estar al corriente del pago de esta, de lo contrario la póliza quedará anulada. En toda renovación el presente formato de la póliza será el documento que acredite la formalidad convenida con BELUVA. Es responsabilidad del titular LEER en su totalidad la póliza de servicios funerarios para enterarse de la cobertura, beneficios y restricciones, así como renovar con anticipación a la fecha de término de vigencia de esta. 
        </p>
        <p>
          <b>OCTAVA:</b> BELUVA no efectuará ningún tipo de reembolso, ni asumirá responsabilidad o reclamo alguno, en caso de que el titular haya utilizado el servicio con alguna otra empresa del ramo y/o ya no requiera o desee utilizar el servicio.
        </p>
        <p>
          <b>NOVENA:</b> La póliza incluye solo 24hrs de velación en las instalaciones de la funeraria en caso de que la funeraria de convenio tenga sala o capilla de velación, de lo contrario el titular deberá hacerlo en domicilio. 
        </p>
        <p>
          <b>DÉCIMA:</b> La presente póliza se renovará de manera automática, con las mismas condiciones de pago seleccionada en el inicio de este. Este se podrá modificar en el perfil de USUARIO o bien solicitándolo por medio de contacto vía correo electrónico, teléfono, o WhatsApp.
        </p>
        <p>
          <b>DÉCIMA PRIMERA:</b> La presente pierde validez, si se presenta un atraso en cuotas de pago, igual o mayor a 3 meses.
        </p>
        <p>
          <b>DÉCIMA SEGUNDA:</b> La póliza subsecuente se podrá reactivar con los mismos beneficios adquiridos mencionados en la fundamentación, siempre y cuando se cumplan con el pago de las cuotas atrasadas más la renovación de la póliza. Esto sin que pase un tiempo mayor a 6 meses de retraso.
        </p>
        <p>
          <b>DÉCIMA TERCERA:</b> En caso de fallecimiento por COVID 19, SARS-CoV-2, ómicron o alguna variante de este virus, solo se cubrirá el 50 % del servicio de gastos funerarios a partir del día 31 al 89, después del día 90 se cubre el 100% del servicio.
        </p>
        <p>
          <b>DÉCIMA CUARTA:</b> En el caso de muerte por suicidio, solo se cubrirá el 50% del servicio al haber transcurrido 3 días después de la contratación.
        </p>
        <p>
          <b>DÉCIMA QUINTA:</b> El contratante o titular del servicio deberá ser menor a 75 años.
        </p>
        <p>
          <b>DÉCIMA SEXTA:</b> El presente servicio no incluye REPATRIACIÓN.
        </p>
        <p>
          <b>DÉCIMA SÉPTIMA:</b> BELUVA no se hace cargo de costos de la fosa (lotes en cementerios) y/o nichos en mausoleos municipales o privados.
        </p>
        <p>
          <b>DÉCIMA OCTAVA:</b> Si el beneficiario hijo/hija del titular sobrepasa la edad de 25 años y adquiere el servicio de gastos funerarios como titular, contará con todos los servicios sin ninguna restricción desde el día de su contratación.
        </p>
        <p>
          <b>DÉCIMA NOVENA:</b> Beluva podrá cambiar condiciones relativas a los límites, coberturas y precios en el momento que así se decida. Dichos cambios serán publicados.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      products: (state) => state.Home.products.items,
      person: (state) => state.Profil.person.item,
    }),
  },
  methods: {
    ...mapActions("Home", ["getProducts"]),
  },
  created() {
    this.getProducts();
  },
};
</script>