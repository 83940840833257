<template>
  <div class="seccion" id="comprar">
            <div class="comprar-wrapper">
                <img src="@/assets/img/pago-seguro-1.png" alt="" class="pagoseguro">

                  <ValidationObserver>
                    <form action="#" class="formulario" id="comprarplan" v-on:submit.prevent>
                        <AlertMessage />
                        <Toast />
                        <h3>Compra tu protección familiar</h3>
                        <section class="comprarpaquete">
                            <p class="formfield col-3">
                                <label for="agre-bene-nombre">Producto</label>
                                <input v-model="label_product" class="input-box" type="text" value="Plan Básico" name="agre-bene-nombre" required disabled>
                            </p>
                            <p class="formfield col-3">
                                <label for="comprar-plan">Plan</label>
                                <multiselect
                                v-model="period_current"
                                :options="lst_period"
                                :loading="loading_catalogs"
                                :searchable="true"
                                label="label_period"
                                track-by="id"
                                class="is-invalid"
                                placeholder="Seleccione una opción"
                              ></multiselect>
                              <span
                                  style="display: block"
                                  v-if="!period_current"
                                  class="invalid-feedback"
                                  >Seleccione una opción
                              </span>
                            </p>
                            <p class="formfield col-3">
                                <label for="comprar-cuota">Cuota</label>
                                <input v-model="amount_product" class="input-box" type="text" id="comprar-cuota" name="comprar-cuota" value="100" disabled>
                            </p>
                            <p class="formfield col-2">
                                <label for="comprar-nombre">Nombre</label>
                                <ValidationProvider
                                rules="required"
                                :bails="false"
                                v-slot="{ errors }"
                                >
                                  <input v-model="person.name" :class="{ 'is-invalid': errors[0] }" class="input-box" type="text" id="comprar-nombre" name="comprar-nombre" required>
                                  <span class="invalid-feedback">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </p>
                            <p class="formfield col-2">
                              <label for="comprar-apellidop">Apellido Paterno</label>
                              <ValidationProvider
                              rules="required"
                              :bails="false"
                              v-slot="{ errors }"
                              >
                              <input v-model="person.lastname" :class="{ 'is-invalid': errors[0] }" class="input-box" type="text" id="comprar-apellidop" name="comprar-apellidop" required>
                              <span class="invalid-feedback">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </p>
                            <p class="formfield col-2">
                                <label for="comprar-apellidm">Apellido Materno</label>
                                <input v-model="person.second_lastname" class="input-box" type="text" id="comprar-apellidm" name="comprar-apellidm">
                            </p>
                            <p class="formfield col-2">
                              <label for="comprar-fechanac">Fecha de Nacimiento</label>
                              <ValidationProvider
                              rules="required|adult"
                              :bails="false"
                              v-slot="{ errors }"
                              >
                                <input v-model="person.birthdate" :class="{ 'is-invalid': errors[0] }" class="input-box" type="date" id="comprar-fechanac" name="comprar-fechanac" max='9999-12-30' required>
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </p>
                            <p class="formfield col-2">
                              <label for="comprar-telefono">Teléfono</label>
                              <ValidationProvider
                              rules="required|length:10"
                              :bails="false"
                              v-slot="{ errors }"
                              >
                                <input v-model="person.phone1" :class="{ 'is-invalid': errors[0] }" class="input-box" type="tel" id="comprar-telefono" name="comprar-telefono" maxlength="10" required>
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </p>
                            <p class="formfield  col-2">
                              <label for="email">Email</label>
                              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                <input v-model="user.email" v-on:keyup="fillValidateEmail()" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="email" id="email" name="email" required>
                                <span class="invalid-feedback" >{{ errors[0] }}</span>
                              </ValidationProvider>
                            </p>
                            <h3>Domicilio</h3>
                            <p class="formfield col-2">
                                <label for="comprar-calle">Calle</label>
                                <ValidationProvider
                                rules="required"
                                :bails="false"
                                v-slot="{ errors }"
                                >
                                  <input v-model="person.address.main_street" :class="{ 'is-invalid': errors[0] }" class="input-box" type="text" id="comprar-calle" name="comprar-calle" required> 
                                  <span class="invalid-feedback">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </p>
                            <p class="formfield col-4">
                                <label for="comprar-noext">No. Exterior</label>
                                <ValidationProvider
                                rules="required"
                                :bails="false"
                                v-slot="{ errors }"
                                >
                                <input v-model="person.address.exterior_number" :class="{ 'is-invalid': errors[0] }" class="input-box" type="text" id="comprar-noext" name="comprar-noext" required>
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </p>
                            <p class="formfield col-4">
                                <label for="comprar-noint">No. Interior</label>
                                <input v-model="person.address.interior_number" class="input-box" type="text" id="comprar-noint" name="comprar-noint"> 
                            </p>
                            <p class="formfield col-2">
                                <label for="comprar-codigopostal">Código Postal</label>
                                <ValidationProvider
                                rules="required|length:5"
                                :bails="false"
                                v-slot="{ errors }"
                                >
                                <input v-model="person.address.post_code" v-on:keyup="filAddresByPostalCode()" :class="{ 'is-invalid': errors[0] }" class="input-box" type="text" id="comprar-codigopostal" name="comprar-codigopostal" maxlength ="5" required>
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </p>
                            <p class="formfield col-2" style="display:none">
                                <label for="comprar-estado">Pais</label>
                                <multiselect
                                :disabled="valid_post_code"
                                v-model="person.address.cat_country"
                                
                                :options="cat_countries"
                                :loading="loading_catalogs"
                                :searchable="true"
                                :close-on-select="true"
                                label="label"
                                track-by="id"
                                placeholder="Seleccione una opción"
                              ></multiselect>
                            </p>
                            <p class="formfield col-2">
                                <label for="comprar-estado">Estado</label>
                                <multiselect
                                :disabled="valid_post_code"
                                v-model="person.address.cat_state"
                                
                                :options="cat_states"
                                :loading="loading_catalogs"
                                :searchable="true"
                                label="label"
                                track-by="id"
                                placeholder="Seleccione una opción"
                              ></multiselect>
                            </p>
                            <p class="formfield col-2">
                                <label for="comprar-municipio">Municipio</label>
                                <multiselect
                                :disabled="valid_post_code"
                                v-model="person.address.cat_municipality"
                                
                                :options="cat_municipies"
                                :loading="loading_catalogs"
                                :searchable="true"
                                label="label"
                                track-by="id"
                                placeholder="Seleccione una opción"
                              ></multiselect>
                            </p>
                            <p class="formfield col-2">
                                <label for="comprar-localidad">Localidad</label>
                                <multiselect
                                v-model="person.address.cat_locality"
                                
                                :options="cat_localities"
                                :loading="loading_catalogs"
                                :searchable="true"
                                label="label"
                                track-by="id"
                                class="is-invalid"
                                placeholder="Seleccione una opción"
                              ></multiselect>
                              <span
                              style="display: block"
                              v-if="!person.address.cat_locality && valid_post_code"
                              class="invalid-feedback"
                              >Seleccione una opción</span
                            >
                            </p>
                            <p class="formfield">
                                <label for="agente">Asesor (Opcional)</label>
                                <input v-model="agent_code" v-on:keyup="fillValidateCodeAgent()" class="input-box" type="text" id="agente" name="agente" placeholder="Número del Asesor de ventas"> 
                            </p>
                            <div class="card" v-for="doc of person.files" v-bind:key="doc.id" >
                                <img :src="doc.complete_path || (doc.code == 'SYS_RSC_DOC_FRONT_P' ? identifications.front : doc.code == 'SYS_RSC_DOC_BACK_P' ? identifications.back : 'https://source.unsplash.com/QAB-WJcbgJk/300x300')"  alt="" class="ine">
                                <h4>{{doc.label}}</h4>
                                <button class="default btn-file"  :disabled="doc.updating" style="padding-bottom:10px">
                                  <b-spinner v-show="doc.updating" small type="grow"></b-spinner>
                                  <span>SUBIR</span>
                                  <b-form-file :disabled="doc.updating" class="btn btn-primary btn-file"  v-on:input="changeDoc(doc, image_doc)" v-model="image_doc" accept="image/*"  plain></b-form-file>
                                </button>
                            </div>
                            
                            <p class="formfield col-2">
                                <label for="aceptacion">
                                <ValidationProvider
                                  rules="required"
                                  :bails="false"
                                  v-slot="{ errors }"
                                >
                                  <b-form-checkbox
                                    v-model="acepted_terms"
                                    name="aceptacion"
                                  ><!-- :disabled="!data_person_valid" -->
                                    Acepto
                                    <a href="/contract" target="_blank"
                                      >contrato de servicio</a
                                    >
                                  </b-form-checkbox>

                                  <span class="invalid-feedback">{{ errors[0] }}</span>
                                </ValidationProvider>
                              </label>
                            </p>
                        </section>
                        
                        <div class="form-row" v-if="acepted_terms"><!-- v-if="!invalid && acepted_terms && product.agent_code_valid && data_person_valid" -->
                          <h4>Seleccione su método de pago:</h4>
                          <!-- <div class="pagos">
                              <i class="fab fa-cc-paypal"></i>
                              <i class="far fa-credit-card"></i>
                              <i class="far fa-money-bill-alt"></i>
                          </div> -->
                          
                          <PaymentGateway v-if="amounts_product.fee" 
                          :amount="amounts_product.fee"
                          v-on:payment_completed="pay"
                          v-on:generate_references="generateReferences"
                          :person="person"
                          :loading="saving_payment"
                          :on_finish_request="finish_pament"
                          :references="references"
                          :product="current_product"
                          :no_operations="!data_person_valid"
                          :amount_payment="amounts_product.fee"
                          v-on:payment_click="validate_data_person"
                          v-on:downloadReceipt="downloadReceipt" />
                        </div>
                    </form>
                  </ValidationObserver>
            </div>
        </div>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
import { mapState, mapActions } from "vuex";
import { Messaging } from '@/helpers';
import PaymentGateway from '@/components/PaymentGateway.vue';
import Toast from '@/components/Toast.vue';
import { UtilFile } from '@/helpers';

export default {
  components: {
    AlertMessage,
    //CreditCard,
    PaymentGateway,
    Toast
  },
  data() {
    return {
      id: this.$route.params.id,
      label_product: "",
      //amount_product: "",
      agent_code: "",
      acepted_terms: false,
      current_product: [],
      lst_period: [],
      period_current : {},
      //paypal
     /* paypal: {
        sandbox: `${process.env.VUE_APP_PAYPAL_SANBOX}`,
        production: `${process.env.VUE_APP_PAYPAL_PRODUCTION}`,
      },
      btn_paypay_style: {
        label: "paypal",
        size: "responsive",
        shape: "rect",
        color: "blue",
      },
      shipping: [],
      items: [],
      */
      //
      //modal de tarjeta de crédito
      translations: {
        name: {
          label: "Nombre",
          placeholder: "Nombre completo",
        },
        card: {
          label: "Número de tarjeta",
          placeholder: "Número de tarjeta",
        },
        expiration: {
          label: "Expiración",
        },
        security: {
          label: "Código de seguridad",
          placeholder: "Código",
        },
      },
      direction: 'row',
      modal: {
          show: false,
          provider: '',
      },
      card: [],
      //
      //conekta oxxo
      conekta: {
        public_key: `${process.env.VUE_APP_CONEKTA_OXXO_PUBLIC_KEY}`,
      },
      //
      //card_error: false
      data_person_valid: false,
      image_doc: null,
      saving_payment: false,
      finish_pament: false,
      current_provider: '',

      identifications: {
				front: '',
				back: '',
				profile: '',
			}
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState({
      products: (state) => state.Home.products.items,
      person: (state) => state.Profil.person.item,
      _person: (state) => state.Profil.person,
      cat_countries: (state) => state.Profil.cat_addres.cat_countries,
      cat_states: (state) => state.Profil.cat_addres.cat_states,
      cat_localities: (state) => state.Profil.cat_addres.cat_localities,
      cat_municipies: (state) => state.Profil.cat_addres.cat_municipies,
      loading_catalogs: (state) => state.Profil.cat_addres.requesting,
      product: (state) => state.ProductShow,
      amount_product: (state) => state.ProductShow.total_amount,
      amounts_product: (state) => state.ProductShow.amounts_product,
      references: (state) => state.ProductShow.references,
      exist_email: state => state.account.exist_email,
      source: state => state.account.source,
      //file: state => state.account.file,
    }),
    valid_post_code: function () {
      return this.person.address.post_code &&
        this.person.address.post_code.length == 5
        ? true
        : false;
    },
    valid_agent_code: function () {
      return this.agent_code && this.agent_code.length > 0 ? true : false;
    },
  },
  created() {
    this.identificationsDefault();
    this.getProducts();
    if (this.user) {
      this.getPerson(this.user);
    } else {
      this.newUser();
      this.newPerson();
    }
    
  },
  mounted() {
    this.clear();

    this.direction = window.innerWidth > 1000 ? 'row' : 'column';
    /*
    if (!this.status.loggedIn) {
      this.$router.push("/login");
    }
    */
   
    if (!this.status.loggedIn) {
      this.person.name = '';
      this.person.lastname = '';
      this.person.second_lastname = '';
      this.person.birthdate = '';
      this.person.phone1 = '';
      this.user.email = '';
      this.person.address.main_street = '';
      this.person.address.exterior_number = '';
      this.person.address.interior_number = '';
      this.person.address.post_code = '';
      this.person.address.cat_country = '';
      this.person.address.cat_state = '';
      this.person.address.cat_municipality = '';
      this.person.address.cat_locality = '';
      this.agent_code = '';
      this.person.files.forEach(file => {
        file.complete_path = null;
        file.path_file = null;
      });
    }
  },
  methods: {
    identificationsDefault(){
			this.identifications.front = require("@/assets/img/ineanverso.png");
			this.identifications.back = require("@/assets/img/inereverso.png");
			this.identifications.profile = "https://www.w3schools.com/howto/img_avatar.png";
		},
    ...mapActions("account", ["validateExistEmail", "newUser", "downloadPaymentReceipt"]),
    ...mapActions("toast", ["makeToast"]),
    ...mapActions("Home", ["getProducts"]),
    ...mapActions("ProductShow", [
      "validateCodeAgent",
      "payingProduct",
      "payingProductProvider",
      "getAmountsProduct",
      "generateReferenceProvider",
      "generateReferenceLendus",
      "saveAffiliation"
      ]),
    ...mapActions("alert", ["clear", "error"]),
    ...mapActions("Profil", [
      "getPerson",
      "updatePerson",
      "getCatCountry",
      "getCatalogsAddresByPostCode",
      "updateDocumentPerson",
      "deleteDocumentPerson",
      "changeTabEvent",
      "newPerson",
      "getFileWitoutSession"
    ]),
    filAddresByPostalCode() {
      if (this.valid_post_code) {
        this.getCatalogsAddresByPostCode(this.person.address.post_code);
      }
    },
    fillValidateCodeAgent() {
      if (this.valid_agent_code) {
        this.validateCodeAgent(this.agent_code);
      } else {
        this.product.agent_code_valid = true;
      }
    },
    async fillValidateEmail() {
      this.data_person_valid = false;
      if (this.user && this.user.email) {
        await this.validateExistEmail(this.user);
      }
    },
    fillValidateDataPerson(){
      let error_msg = true;

      if(!this.label_product || this.label_product.length <= 0){
        error_msg = false;
      }

      if(!this.period_current || this.period_current.length <= 0){
        error_msg = false;
      }

      if(!this.amount_product || this.amount_product.length <= 0){
        error_msg = false;
      }

      if(!this.person.name || this.person.name.length <= 0){
        error_msg = false;
      }

      if(!this.person.lastname || this.person.lastname.length <= 0){
        error_msg = false;
      }

      if(!this.person.birthdate || this.person.birthdate.length <= 0){
        error_msg = false;
      } else {
        const yearCurrent = new Date().getFullYear(); 
        let valueBirthDate = this.person.birthdate.split('-');
        let result = !((parseInt(yearCurrent) - (parseInt(valueBirthDate[0]))) < 18 );
        if (!result) {
          error_msg = false;
        }
      }

      if(!this.person.phone1 || this.person.phone1.length <= 0){
        error_msg = false;
      }

      if (this.user && this.user.email) {
        let regex = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";
        var regex_email = new RegExp(regex, 'i');
        error_msg = regex_email.test(this.user.email);
      }

      if(this.user && (!this.user.email || this.user.email.length <= 0)){
        error_msg = false;
      } else if (!this.user.email) {
        //console.log('1');
      } else {
        if (this.exist_email) {
          error_msg = false;
        }
      }

      if(!this.person.address.main_street || this.person.address.main_street.length <= 0){
        error_msg = false;
      }

      if(!this.person.address.exterior_number || this.person.address.exterior_number.length <= 0){
        error_msg = false;
      }
      
      if(!this.person.address.post_code || this.person.address.post_code.length <= 0){
        error_msg = false;
      }

      if(!this.person.address.cat_state || this.person.address.cat_state.length <= 0){
        error_msg = false;

      }

      if(!this.person.address.cat_municipality || this.person.address.cat_municipality.length <= 0){
        error_msg = false;
      }

      if(!this.person.address.cat_locality || this.person.address.cat_locality.length <= 0){
        error_msg = false;
      }

      this.person.files.forEach(item => {
        if (!item.complete_path && !item.path_file) {
          error_msg = false;
        }
      });

      if (this.agent_code) {
        if (!this.product.agent_code_valid) {
          error_msg = false;
        }        
      }

      if (!error_msg)  {
        this.data_person_valid = false;
      } else {
        this.data_person_valid = true;
      }      
    },
    currentProduct() {
      this.current_product = this.products && this.products.find((x) => (x.id == this.id));
      this.label_product = this.current_product ? this.current_product.label : "";
      //this.amount_product = this.current_product ? parseFloat(this.current_product.amount_fee).toFixed(2) : 0;

      this.lst_period = this.current_product && this.current_product.lst_configuration ? this.current_product.lst_configuration : [];
      this.period_current = this.lst_period.length > 0 ? this.lst_period[0] : '';
      this.items = [
        {
          name: this.label_product,
          quantity: "1",
          price: this.amount_product,
          currency: "MXN",
        },
      ];
    },
    handleSubmit() {},
    pay(provider, response_payment) {
			const amounts_product = {
				fee: this.amounts_product.fee,
				amount_with_discount: this.amounts_product.amount_with_discount,
				amount_discount: 0,
      }
      
			if(provider === 'PAYPAL'){
				const data = {
					provider,
					product: this.current_product,
					person: this.person,
          code_agent: this.agent_code,
          user: this.user,
					response_payment: response_payment,
					period_conf: this.period_current,
					amounts_product,
          disbursment_date: new Date().setHours(0,0,0,0),
          generate_user: true
        };
        
        this.payingProduct(data);
			}else {
				const data = {
					provider,
					product: this.current_product,
					person: this.person,
					code_agent: this.agent_code,
					user: this.user,
					card: response_payment,
					period_conf: this.period_current,
					amounts_product,
          disbursment_date: new Date().setHours(0,0,0,0)
				};
        
				this.finish_pament = null;
        this.saving_payment = true;
        

				this.payingProductProvider(data).then(()=>{
					this.saving_payment = false;
					this.finish_pament = true;
					// this.$emit('close_modal_payment', true);
        }).catch(()=>{
          this.saving_payment = false;
        });
			}
		},
    sync() {
      const data = { ...this.person, agent_code: this.agent_code };
      let send_data = true;

      if (!this.valid_post_code) {
        this.error({ message: 'Ingrese un código postal valido.', code: '' });
        send_data = false;
      }

      if (!data.address.cat_locality || data.address.cat_locality.length <= 0) {
        this.error({ message: 'Seleccione una localidad.', code: '' });
        send_data = false;
      }

      if (send_data) {
        this.updatePerson({ person: data, sync: true });
      }
    },
    validate_data_person(show_message = true) {
      
      this.fillValidateDataPerson();
      let error_msg = '';
      //let error_msg = true;
      this.data_person_valid = false;

      if(!this.label_product || this.label_product.length <= 0){
        error_msg += '*El producto es requerido<br>';
        //error_msg = false;
      }

      if(!this.period_current || this.period_current.length <= 0){
        error_msg += '*El plan es requerido<br>';
        //error_msg = false;
      }

      if(!this.amount_product || this.amount_product.length <= 0){
        error_msg += '*El total es requerido<br>';
        //error_msg = false;
      }

      if(!this.person.name || this.person.name.length <= 0){
        error_msg += '*El nombre es requerido<br>';
        //error_msg = false;
      }

      if(!this.person.lastname || this.person.lastname.length <= 0){
        error_msg += '*El apellido paterno es requerido<br>';
        //error_msg = false;
      }

      /*if(!this.person.second_lastname || this.person.second_lastname.length < 0){
        error_msg += 'El apellido materno es requerido';
      }*/

      if(!this.person.birthdate || this.person.birthdate.length <= 0){
        error_msg += '*La fecha de nacimiento es requerido<br>';
        //error_msg = false;
      } else {
        const yearCurrent = new Date().getFullYear(); 
        let valueBirthDate = this.person.birthdate.split('-');
        let result = !((parseInt(yearCurrent) - (parseInt(valueBirthDate[0]))) < 18 );
        if (!result) {
          error_msg += '*Debe tener al menos 18 años<br>';
        }
      }
     
      if(!this.person.phone1 || this.person.phone1.length < 10){
        error_msg += '*Ingrese un número de teléfono valido<br>';
      }else{
        if(isNaN(this.person.phone1)){
          error_msg += '*Formato de número de teléfono incorrecto<br>';
        }
      }

      if (this.user && this.user.email) {
        let regex = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";
        var regex_email = new RegExp(regex, 'i');
        let is_valid_email = regex_email.test(this.user.email);
        if (!is_valid_email) {
          error_msg += '*El correo no tiene un formato correcto<br>';
        }
      }

      if(this.user && (!this.user.email || this.user.email.length <= 0)){
        error_msg += '*El correo es requerido<br>';
      } else if (!this.user.email) {
        //console.log('1');
      } else {
        if (this.exist_email) {
          error_msg += '*El correo ingresado ya existe<br>';
        }
      }

      if(!this.person.address.main_street || this.person.address.main_street.length <= 0){
        error_msg += '*La calle es requerida<br>';
        //error_msg = false;
      }

      if(!this.person.address.exterior_number || this.person.address.exterior_number.length <= 0){
        error_msg += '*El número exterior es requerido<br>';
        //error_msg = false;
      }
      
      if(!this.person.address.post_code || this.person.address.post_code.length <= 0){
        error_msg += '*El código postal es requerido<br>';
        //error_msg = false;
      }

      if(!this.person.address.cat_state || this.person.address.cat_state.length <= 0){
        error_msg += '*El estado es requerido<br>';
        //error_msg = false;
      }

      if(!this.person.address.cat_municipality || this.person.address.cat_municipality.length <= 0){
        error_msg += '*El municipio es requerido<br>';
        //error_msg = false;
      }

      if(!this.person.address.cat_locality || this.person.address.cat_locality.length <= 0){
        error_msg += '*La localidad es requerido<br>';
        //error_msg = false;
      }
      /*
      this.person.files.forEach(item => {
        if (!item.complete_path && !item.path_file) {
          let label_item = item.label.toLowerCase();
          error_msg += '*Es necesario para continuar que ingrese la fotografía de ' + label_item + '<br>';
        }
      });*/

      if (this.agent_code) {
        if (!this.product.agent_code_valid) {
          //error_msg = false;
          error_msg += '*La clave del agente que ingreso no se encuentra registrada, verifique que sea la correcta<br>';
        }        
      }

      if(!this.acepted_terms){
        error_msg += '*Acepte el contrato del servicio<br>';
        //error_msg = false;
      }

      //if (!error_msg)  {
      if (error_msg && error_msg.length > 0)  {
        //this.error({ message: error_msg, code: '' });
        //Messaging.makeToast(error_msg,'Datos incompletos' ,'danger');
        if (show_message) {
          this.makeToast( {
            variant: 'danger',
            message: error_msg,
            title: 'Datos incompletos'
          });
        }
        
        this.data_person_valid = false;
        return false;
      } else {
        let date = new Date();
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        let current_date = date.toLocaleDateString("es-ES", options);
        options = { hour12 : true, hour:  "numeric", minute: "numeric",seconds:"numeric" }
        let current_time = date.toLocaleTimeString("es-ES",options);
        this.current_date = current_date + ' a las ' + current_time;

        this.data_person_valid = true;
      }
      
      return true;
    },
    changeDoc(doc, file) {
			const id_person = this.user.id_person;
      const id_cat_catalog_document = doc.cat_row_entity.id;
      if(id_person && id_person != '') {
        this.updateDocumentPerson({id_person, id_cat_catalog_document, file});
        setTimeout(() => {
          this.fillValidateDataPerson();
        }, 1300);
      } else {
        UtilFile.FileTobs64(file).then(rsc_file=> {
            doc.complete_path = rsc_file.path_file;
        });
        this.getFileWitoutSession({id_person: '', file, stored_file: doc});
      }
      
    },
    confirmDeleteDoc(doc) {
      const id_person = this.user.id_person;
			if (id_person && id_person != '') {
        if (doc.complete_path) {
          this.$bvModal.msgBoxConfirm('Confirme que desea eliminar la imagen.', Messaging.confirmConfig())
          .then(confirmed => {
            if(confirmed)
              this.deleteDocumentPerson({id_person: this.person._id, id_catalog:doc.cat_row_entity.id})
          })
        }
      } else {
        this.image_doc = null;
        doc.path_file = null;
        let files = this.person.files;
        this.person.files = [];
        files.forEach(file => {
          this.person.files.push(file);
        });
        //this.person.files[0].path_file = null;//.forEach(x=>);
      }
    },
    generateReferences(){
      const data = {
				provider: 'REFERENCES',
				product: this.current_product,
				person: this.person,
				code_agent: this.agent_code,
				user: this.user,
				period_conf: this.period_current,
				amounts_product: this.amounts_product,
				payment_code: 'PAYMENT_BUYPRODUCT',
        disbursment_date: new Date().setHours(0,0,0,0),
        generate_user: true
      };
      
      this.payingProduct(data).then(()=>{
        // this.getBalance(this.user.id_person);
        console.log('paid');
      }).catch(() =>{
        Messaging.makeToast('Ocurrio un error al guardar la referencia','Error' ,'danger');
      });
    },
    downloadReceipt(provider){
      this.current_provider = provider;
      let current_reference_provider = this.references.find(x=>x.reference_provider == provider);

      let data = {
        reference: current_reference_provider.reference_number,
        provider: this.current_provider,
        user: this.user,
        product: this.current_product,
        person: this.person,
        amounts_product: this.amounts_product,
        current_date: this.current_date,
      };
      this.downloadPaymentReceipt(data);
    }
  },
  watch: {
    products() {
      this.currentProduct();
    },
    person() {
      if (this.person && this.person.affiliation.length > 0) {
        this.changeTabEvent(2);
        this.$router.push('/profile');
      }
      this.fillValidateDataPerson();
    },
    acepted_terms() {/*checked*/
      this.clear();
      this.validateExistEmail(this.user);
      this.validate_data_person(false);
    },
    period_current(){
      const data = {
          period_current: this.period_current
      };
      if(this.period_current){
          this.getAmountsProduct(data);
      }

    },
    exist_email(){
      if (!this.exist_email) {
        this.data_person_valid = true;
      } else {
        this.data_person_valid = false;
      }
    },
    source(){
      const linkSource = this.source;
      const downloadLink = document.createElement("a");
      const fileName = this.current_provider + ".pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
};
</script>
