<template>
  <table id="historial-pagos">
    <thead>
      <tr>
      <th scope="col"># Transacción</th>
      <th scope="col">Fecha</th>
      <th scope="col">Fuente</th>
      <th scope="col">Importe</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(made, index) in made_payments" v-bind:key="made.id">
      <td scope="row" data-label="# Transacción">N {{ index + 1 }}</td>
      <td data-label="Fecha">{{made.payment_date | sqlToCustomDate}}</td>
      <td data-label="Fuente">{{made.source_name_aux}}</td>
      <td data-label="Importe">{{made.amount | toCurrency}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  props: {
    made_payments: { type: Array }
  }
}
</script>
