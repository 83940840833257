<template>
  <table id="historial-pagos">
    <thead>
      <tr>
      <th scope="col">Fecha</th>
      <th scope="col">Importe</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(cal)  of calendars" v-bind:key="cal.id">
      <td scope="row" data-label="Fecha">{{cal.due_date | sqlToCustomDate}}</td>
      <td data-label="Importe">{{cal.balance_total | toCurrency}}</td>
      </tr>

    </tbody>
  </table>
</template>

<script>

export default {
  props: {
    calendars: { type: Array }
  }
}
</script>
