<template>
  <div id="agregarbeneficiario" >
    <h2 class="titulo__tab">
        Agregar Beneficiario
    </h2>
    <form action="#" class="formulario" id="agregarbenef" v-if="!_person.loading" autocomplete="on" @submit.prevent="">
        <section class="datosprimarios">
            <p class="formfield">
                <label for="agre-bene-nombre">Nombre</label>
                <input v-model="person.name" v-uppercase :class="[{ 'is-invalid': submitted && (!person.name || (person.name && !person.name.length)) },'input-box']" type="text" id="agre-bene-nombre" name="agre-bene-nombre" required>
                <span v-show="submitted && (!person.name || (person.name && !person.name.length))" class="invalid-feedback">Nombre es requerido</span>
            </p>
            <p class="formfield">
                <label for="agre-bene-apellidop">Apellido Paterno</label>
                <input class="input-box" v-model="person.lastname" v-uppercase type="text" id="agre-bene-apellidop" name="agre-bene-apellidop" required>
                <span v-show="submitted && (!person.lastname || (person.lastname && !person.lastname.length))" class="invalid-feedback">Apellido paterno es requerido</span>
            </p>
            <p class="formfield">
                <label for="agre-bene-apellidm">Apellido Materno</label>
                <input v-model="person.second_lastname" v-uppercase class="input-box" type="text" id="agre-bene-apellidm" name="agre-bene-apellidm">
            </p>
            <p class="formfield">
                <label for="agre-bene-fechanac">Fecha de Nacimiento</label>
                <input v-model="person.birthdate" class="input-box" type="date" id="agre-bene-fechanac" name="agre-bene-fechanac" max='9999-12-30'>
                <span v-show="submitted && birth_date_error.length" class="invalid-feedback">{{birth_date_error}}</span>
            </p>
            <p class="formfield">
                <label for="agre-bene-telefono">Teléfono</label>
                <input v-model="person.phone1" class="input-box" type="tel" id="agre-bene-telefono" name="agre-bene-telefono" maxlength="10">
            </p>
            <p class="formfield">
                <label for="agre-bene-relacion">Relación</label>
                <multiselect 
                v-model="person.category_relation"
                :options="cat_relation" 
                :loading="loading_catalogs"
                :searchable="true"
                label="label" track-by="id" 
                placeholder="Seleccione una opción"
                :class="{ 'is-invalid': submitted && !person.category_relation.id }"
                ></multiselect>
                <span v-if="submitted && !person.category_relation.id" class=" invalid-feedback" >Seleccione una opción</span>
            </p>

            <h3 class="direccionh3 col-2">Dirección</h3>
            <p class="formfield col-2">
                <input v-on:click.prevent="clearAddress" type="button" class="button borrarform" value="Borrar Datos Ubicación">
            </p>

            <p class="formfield col-3">
                <label for="agre-bene-calle">Calle</label>
                <input v-model="person.address.main_street" v-uppercase  class="input-box" type="text" id="agre-bene-calle" name="agre-bene-calle"> 
            </p>
            <p class="formfield col-2">
                <label for="agre-bene-noext">No. Exterior</label>
                <input v-model="person.address.exterior_number" class="input-box" type="text" id="agre-bene-noext" name="agre-bene-noext">
            </p>
            <p class="formfield col-2">
                <label for="agre-bene-noint">No. Interior</label>
                <input v-model="person.address.interior_number" class="input-box" type="text" id="agre-bene-noint" name="agre-bene-noint"> 
            </p>
            <p class="formfield">
                <label for="agre-bene-codigopostal">Código Postal</label>
                <input v-model="person.address.post_code" v-on:keyup="filAddresByPostalCode()" class="input-box" type="number" id="agre-bene-codigopostal" name="agre-bene-codigopostal"  placeholder="C.P." maxlength ="5">
            </p>
            <p class="formfield" style="display:none">
                <label for="agre-bene-estado">Pais</label>
                <multiselect 
                :disabled="valid_post_code"
                v-model="person.address.cat_country"
                :options="cat_countries" 
                :loading="loading_catalogs"
                :searchable="true" :close-on-select="true"
                label="label" track-by="id" 
                placeholder="Seleccione una opción"></multiselect>
            </p>
            <p class="formfield">
                <label for="agre-bene-estado">Estado</label>
                <multiselect 
                :disabled="valid_post_code"
                v-model="person.address.cat_state"
                :options="cat_states" 
                :loading="loading_catalogs"
                :searchable="true"
                label="label" track-by="id" 
                placeholder="Seleccione una opción"></multiselect>
            </p>
            <p class="formfield">
                <label for="agre-bene-municipio">Municipio</label>
                <multiselect 
                :disabled="valid_post_code"
                v-model="person.address.cat_municipality"
                :options="cat_municipies" 
                :loading="loading_catalogs"
                :searchable="true"
                label="label" track-by="id" 
                placeholder="Seleccione una opción"></multiselect>
            </p>
            <p class="formfield">
                <label for="agre-bene-localidad">Localidad</label>
                <multiselect 
                v-model="person.address.cat_locality"
                :options="cat_localities" 
                :loading="loading_catalogs"
                :searchable="true"
                label="label" track-by="id" 
                placeholder="Seleccione una opción"></multiselect>
            </p>
            
        </section>
        <p class="acciones actanacimiento">
            <button class="danger btn-file" style="margin-top: 0px">
              <span>{{files && files.path_file ? 'ACTUALIZAR ARCHIVO' : 'SUBIR ACTA DE NACIMIENTO'}}</span>
              <b-form-file id="file-upload" class="btn-file file-hidden"  v-on:input="changeDoc(image_doc)" v-model="image_doc" accept="image/*"  plain></b-form-file>
            </button>
        </p>
        <div class="buttons-bot">
            <p>
              <button v-on:click="cancelRegister" class="button cancelar">Cancelar</button>
            </p>
            <p class="acciones guardarbutton">
                <input class="button" type="submit" @click="handleSubmit" value="Guardar">
                <!--<button class="button" type="submit" value="Guardar">
                  <b-spinner v-show="_person.updating" small type="grow"></b-spinner>
                  Guardar
                </button> -->
            </p>
        </div>
    </form>
    
    <!--
    <b-modal id="modal-docs-bene" title="Documento" size="lg" hide-footer>
        <div v-if="files && files.path_file">
          <div class="clearfix text-center">
            <b-card-img style="max-width:400px !important" :src="base_file + files.path_file" fluid></b-card-img>
          </div>
        </div>
        <b-button class="mt-3 btn-success" block @click="$bvModal.hide('modal-docs-bene')">Cerrar</b-button>
    </b-modal>
    -->
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UtilDate } from '@/helpers';
import { Messaging } from '@/helpers';

export default {
  components: {
  },
  props:{
    id:{
      type: String,
      required: false
    },
  },
  data() {
    return {
      tabIndex: 0,
      submitted: false,
      birth_date_error: '',
      image_doc: null,
    };
  },
  created() {
  },
  mounted(){
    //this.clear();
    this.getPersonRelation({id: this.id, idHeadline: this.user.id_person});
    this.getCatCountry();
    this.getCatRelationType();
  },
  methods: {
    ...mapActions("Beneficiary", [
      "getCatCountry",
      "getCatalogsAddresByPostCode",
      "getPersonRelation",
      "SaveBeneficiary",
      "getCatRelationType",
      "updateFilePerson",
      "deleteFilePerson",
      "getDefaultFilePerson"
    ]),
    ...mapActions("alert", ["clear"]),
    filAddresByPostalCode () {
			if(this.valid_post_code) {
				this.getCatalogsAddresByPostCode(this.person.address.post_code);
			}
    },
    handleSubmit () {
      this.submitted = false;
      if(!this.submitted){
        this.submitted = true;

        this.birth_date_error = '';
        if(!this.person.name){
            Messaging.makeToast('Verifique los datos','Error' ,'danger');
            this.submitted = false;
            return;
        }

        if(!this.person.category_relation || (this.person && !this.person.category_relation.code)){
          Messaging.makeToast('Seleccione un tipo de relación','Error' ,'danger');
          this.submitted = false;
          return;
        }
        
          let valueBirthDate = this.person.birthdate ? this.person.birthdate.split('-') : [];

          if(this.person.category_relation && this.person.category_relation.code == "SYST_TYPE_REL_02"){//padre
            let years = UtilDate.calculateAge(valueBirthDate[0], valueBirthDate[1], valueBirthDate[2]);
            if(years < 18){
              this.birth_date_error = 'La persona debe tener al menos 18 años';
              Messaging.makeToast(this.birth_date_error,'Error' ,'danger');
              this.submitted = false;
              return;
            }
          }

          if(this.person.category_relation && this.person.category_relation.code == "SYST_TYPE_REL_01"){//conyuge  
            let years = UtilDate.calculateAge(valueBirthDate[0], valueBirthDate[1], valueBirthDate[2]);
            if(years < 18){
              this.birth_date_error = 'La persona debe tener al menos 16 años';
              Messaging.makeToast(this.birth_date_error,'Error' ,'danger');
              this.submitted = false;
              return;
            }
          }

        if(this.person.category_relation && this.person.category_relation.code == "SYST_TYPE_REL_04"){//hijo
          if(this.person.birthdate){
            //let valueBirthDate = this.person.birthdate.split('-');
            let years = UtilDate.calculateAge(valueBirthDate[0], valueBirthDate[1], valueBirthDate[2]);
          
            if(years < 0 || years >= 25){
              this.birth_date_error = 'La persona debe tener menos de 25 años';
              Messaging.makeToast(this.birth_date_error,'Error' ,'danger');
              this.submitted = false;
              return ;
            }
          }else{
            this.birth_date_error = 'Debe selecciona una fecha de nacimiento';
            Messaging.makeToast(this.birth_date_error,'Error' ,'danger');
            this.submitted = false;
            return ;
          }
        }else{
          if(this.person.birthdate){
              //let valueBirthDate = this.person.birthdate.split('-');
              let years = UtilDate.calculateAge(valueBirthDate[0], valueBirthDate[1], valueBirthDate[2]);
              
              if(years < 0){
                this.birth_date_error = 'La fecha de nacimiento no puede ser mayor a hoy';
                this.submitted = false;
                return ;
              }
          }
        }      
          const data = {...this.person};
          if(this.person.name && this.person.lastname && this.person.category_relation.id && !this.birth_date_error.length){
            this.savePersonRelation(data);
          }		

      }
    },
    savePersonRelation(data){
      let beneficiary = {id_person: this.user.id_person, beneficiary: data, file: this.files};
      this.SaveBeneficiary(beneficiary).then(()=>{
        this.submitted = false;
        this.$emit("updateList", {});
      });
    },
    cancelRegister(){
      this.$emit("cancelRegister", {});
    },
    clearAddress(){
      if(this.person.address){
        this.person.address.main_street = '';
        this.person.address.interior_number = '';
        this.person.address.exterior_number = '';
        this.person.address.post_code = '';
        this.person.address.cat_state = {};
        this.person.address.cat_locality = {};
        this.person.address.cat_municipality = {};
      }    
    },
    changeDoc(file) {
      if(!this.person){
        this.person.name = '';
      }
			const id_person = this.person._id ? this.person._id : 'NA';
      this.getDefaultFilePerson({id_person, file});
		},
    async deleteFileConfirm(){
      let ts = this;
      this.$bvModal.msgBoxConfirm("¿Realmente desea eliminar el archivo?", Messaging.confirmConfig())
			.then(confirmed => {
        if(confirmed){
          ts.deleteFilePerson();
        }
			})
      
    }
  },
  watch: {
    'message_error': function() {
      if(this.message_error && this.message_error){
        Messaging.makeToast(this.message_error,'Error' ,'danger');
      }
    }
  },
  computed: {
    ...mapState({
      person: state => state.Beneficiary.person.item,
      // _person: state => state.Profil.person,
      _person: state => state.Beneficiary.person,
      cat_countries: state => state.Beneficiary.cat_addres.cat_countries,
			cat_states: state => state.Beneficiary.cat_addres.cat_states,
      cat_localities: state => state.Beneficiary.cat_addres.cat_localities,
      cat_municipies: state => state.Beneficiary.cat_addres.cat_municipies,
      cat_relation: state => state.Beneficiary.cat_relation,
      loading_catalogs: state => state.Beneficiary.cat_addres.requesting,
      user: state => state.account.user,
      files: state => state.Beneficiary.file_birth_certificate,
      base_file: state => state.Beneficiary.base_file,
      message_error: state => state.Beneficiary.message_error
    }),
    valid_post_code: function() {
      return true;
      //return this.person.address.post_code &&  this.person.address.post_code.length == 5? true : false;
    }
  },
};
</script>
