import { userService, cat_CatalogService } from '../services';
import { Messaging } from '../helpers';

const state = {
    cat_social_link: {},
    cat_wahtsapp: {},
    status: {},
    organization: {}
};

const actions = {
    getCatWahtsapp({ commit }) {
        // commit('getCatWahtsappRequest');
        cat_CatalogService.getCat_CatalogByCodeType('SYS_CAT_WHATSAPP_CONTACT')
        .then(  catalogs => commit('getCatWahtsappSuccess', catalogs) ); 
    },
    getOrganization({ commit }) {
        cat_CatalogService.getOrganization()
        .then(  organization => commit('getOrganizationSuccess', organization) ); 
    },
    sendEmail({ dispatch, commit }, user) {
        return new Promise((resolve, reject)=>{
            commit('startRequest', user);
    
            userService.sendEmail(user)
            .then(
                user => {
                    commit('endRequestSuccess', user);
                    resolve();
                },
                error => {
                    commit('endRequestFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                    reject();
                }
            );
        });
    },
    getCatSocialLinks({ commit }) {
        // commit('getCatWahtsappRequest');
        cat_CatalogService.getCat_CatalogByCodeType('SYS_CAT_SOCIAL_LINKS')
        .then(  catalogs => commit('getCatSocialLinksSuccess', catalogs) ); 
    },
};

const mutations = {
    startRequest(state) {
        state.status = { loading: true };
    },
    endRequestSuccess(state, data) {
        state.status = { items: data };
        Messaging.makeToast('Su mensaje se ha enviado correctamente','Contacto ' ,'success');
    },
    endRequestFailure(state, error) {
        state.status = { error };
        Messaging.makeToast(error,'Contacto ' ,'danger');
    },
    getCatWahtsappSuccess(state, data) {
        state.cat_wahtsapp = { items: data };
    },
    getOrganizationSuccess(state, data) {
        state.organization = data && data.length > 0 ? data[0] : {}; 
    },
    getCatSocialLinksSuccess(state, data) {
        state.cat_social_link = { items: data };
    }
};

export const ContactMessage = {
    namespaced: true,
    state,
    actions,
    mutations
};