import { fin_ProductService } from '../services';
import { Messaging } from '../helpers';

const state = {
    affiliation_detail: null,
    repuchasing: false,
    requesting: false,
    contract: {},
    calendars: [],
    madePayments:[],
    product: {},
    contract_detail: {},
    lst_history_credit: [],
    payment_pending: [],

    modal_history: false,
    loading_history: false,
    selected_history: {},

    // Recompras
    repuchase: {
        alert: null,
        getting: false,
        saving: false,
        modal: false,
        data: {
            id_product: '',
            id_frecuency: '',
            id_credit_account: '',
        },
        products: [],
        // selected
        product: {},
        period: {},
        amount: {
            fee: 0
        },

        payment_provider: ''
    }
};

const actions = {
    
    getBalance({ commit }, id_person) {
        commit('getBalanceRequest');
        fin_ProductService.getCurrentContractInfo(id_person)
        .then(
            data => commit('getBalanceSuccess', data),
            error => commit('getBalanceFailure', error)
        );
        
    },
    getBalanceByCreddit({ commit }, {id_credit_account, id_credit_account_detail}) {
        commit('getBalanceByCredditRequest');
        fin_ProductService.getContractInfo(id_credit_account, id_credit_account_detail)
        .then(
            data => commit('getBalanceByCredditSuccess', data),
            error => commit('getBalanceByCredditFailure', error)
        );
    },

    getRepuchaseData({ commit }, id_credit_account) {
        commit('getRepuchaseDataRequest');
        fin_ProductService.getRepuchaseData(id_credit_account)
        .then(
            data => commit('getRepuchaseDataSuccess', data),
            error => commit('getRepuchaseDataFailure', error)
        );
        
    },

    saveRepuchase({ commit, /* dispatch */}, repuchase) {
        return new Promise((resolve, reject)=> {
            commit('saveRepuchaseRequest');
            fin_ProductService.saveRepuchase(repuchase)
            .then(data => {
                    commit('saveRepuchaseSuccess', data)
                    resolve(data);
                },
                error => { 
                    reject(error);
                    commit('saveRepuchaseFailure', error);
                    // dispatch('alert/error', 'Ocurrio un error al guardar la renovación', { root: true });
                }
            );
        });
    },

    getRepuchaseAmountsProduct({ commit, dispatch }, { period_current }) {
        if(!period_current){
            dispatch('alert/error', 'Seleccione un periodo', { root: true });
        }else{
            fin_ProductService.getProductConfigurarionPublish(period_current.id_product, period_current.id)
            .then(
                data => {
                    if(data){
                        commit('UpdateAmountPayment', data)
                    }else{
                        commit('UpdateAmountPayment', {})
                        dispatch('alert/error', 'Ocurrio un error al obtener el monto a pagar', { root: true });
                    }
                },
                error => { 
                    commit('payingProductFailure', error);
                    dispatch('alert/error', 'Ocurrio un error al obtener el monto a pagar', { root: true });
                }
            );
        }
    },

};

const mutations = {
    getBalanceRequest(state) {
        state.requesting = true;
    },
    getBalanceSuccess(state, data) {
        const _balance = data.balance;
        state.affiliation_detail = data.affiliation_detail;
        if(_balance){
            state.contract = _balance[0] || {};
            state.calendars = _balance[5] || [];
            state.calendars = state.calendars.filter(cal => cal.comment !== 'EXCEDENTE');
            state.payment_pending = _balance[13] || [];
            state.madePayments = _balance[6] || [];
            state.product = _balance[10] || {};
            state.lst_history_credit = _balance[11] || [];
            state.contract_detail = _balance[12] || {};
        }
        state.requesting = false;
    },
    getBalanceFailure(state) {
        state.requesting = false;
    },

    getBalanceByCredditRequest(state) {
        state.modal_history = true;
        state.loading_history = true;
    },
    getBalanceByCredditSuccess(state, data) {
        state.selected_history = {
            contract: data[0] || {},
            calendars: data[5] || [],
            madePayments: data[6] || [],
            product: data[10] || {},
            lst_history_credit: data[11] || [],
            contract_detail: data[12] || {},
        }
        state.loading_history = false;
    },
    getBalanceByCredditFailure(state) {
        state.loading_history = false;
    },


    getRepuchaseDataRequest(state) {
        state.repuchase.getting = true;
        state.repuchase.modal = true;
    },
    getRepuchaseDataSuccess(state, data) {
        state.repuchase.products = data[0] || [];
        const product_selected = data[1];
        const repuchase_data = data[3] || {};
        state.repuchase.data = repuchase_data;
        if(product_selected){
            state.repuchase.product = state.repuchase.products.find(x=> x.id === product_selected.id);
            if(repuchase_data.id_frecuency){
                state.repuchase.period = state.repuchase.product.lst_configuration.find(x=> x.id === repuchase_data.id_frecuency);
            }
        }
        
        state.repuchase.getting = false;
    },
    getRepuchaseDataFailure(state) {
        state.repuchase.getting = false;
    },


    saveRepuchaseRequest(state) {
        state.repuchase.alert = null;
        state.repuchase.data.id_product = state.repuchase.product.id;
        state.repuchase.data.id_frecuency = state.repuchase.period.id;
        state.repuchase.data.amortizaion_amount = state.repuchase.amount.fee;
        state.repuchase.saving = true;
    },
    saveRepuchaseSuccess(state) {
        state.repuchase.saving = false;
        state.repuchase.modal = false;
    },
    saveRepuchaseFailure(state, error) {
        state.repuchase.alert = Messaging.createMessage(error, 'danger');
        state.repuchase.saving = false;
    },

    UpdateAmountPayment(state, data){
        state.repuchase.amount = data && data[0] || {};
    },
};

export const Balance = {
    namespaced: true,
    state,
    actions,
    mutations
};