<template>
  <div class="container">
  <header>
          <nav class="main-nav">
                <a href="#" class="hide-desktop" @click="showMobilmenu = !showMobilmenu">
                    <img src="@/assets/img/ham.svg" alt="toggle menu" class="menu" id="menu">
                </a>
                <ul :class="['show-desktop', {'hide-mobile': !showMobilmenu}]" id="nav" >
                    <li id="exit" class="exit-btn hide-desktop" @click="showMobilmenu = !showMobilmenu">
                        <img src="@/assets/img/exit.svg" alt="exit menu">
                    </li>
                    <li v-for="item of menu" :key="item.id" @click="showMobilmenu = !showMobilmenu">
                      <router-link :to="item.route" class="nav-link">
                      <i :class="item.icon"></i> {{item.label}}
                      </router-link>
                    </li>
                </ul>
            </nav>
            <img src="@/assets/img/logobelu.png" alt="" class="logo">
            <div class="nav-icons" v-if="status.loggedIn">
              <AppDropdown>
                <template slot="toggler">
                  <i class="far fa-bell fa-2x dropbtn" id="perfilicon"></i>
                </template>
                <div id="notifications" class="dropdown-content show">
                  <a href="#" v-for="item of messages_unread" v-bind:key="item.id" >
                    <i class="far fa-bell fa-1x"></i> {{item.subject}}
                  </a>
                <div @click="goToNotifications()" class="dropdown-noti-todas"> Ver todas</div>
                </div>
              </AppDropdown>

              <AppDropdown>
                <template slot="toggler">
                  <b-avatar  v-if="user && user.photo" class="dropdown-user-img " :src="user && user.photo || '@/assets/img/img_avatar.png'" alt="perfil" size="2rem" /><!--@/assets/img/user.png-->
                  <!--<i v-else class="fas fa-user-circle fa-2x  dropbtn perfilicon"  id="perfilicon" ></i>-->
                  <b-avatar v-else size="2rem"><img class="dropbtn btnprof " src="@/assets/img/img_avatar.png" alt="perfil"></b-avatar>
                </template>
                <div id="userdropdown" class="logout dropdown-content show">
                  <a href="/profile"><i class="far fa-user-circle"></i>Mi Cuenta</a>
                  <a href="#" @click="logoutMenu"><i class="fas fa-sign-out-alt"></i>Salir</a>
                </div>
              </AppDropdown>
            </div>
            <div class="nav-icons" v-else>
                <b-avatar href="/login"  size="2rem"><img class="dropbtn btnprof " src="@/assets/img/img_avatar.png" alt="perfil"></b-avatar>
            </div>
              
        </header>
        </div> 
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { UtilDate } from '@/helpers';
import AppDropdown from '@/components/AppDropdown.vue';
export default {
    components: {
      AppDropdown
    },
    data () {
      return {
        showMobilmenu: false,
        menu: [
          {label: 'Inicio', icon: 'fa fa-home', route: '/'},
          {label: 'Nosotros', icon: 'fa fa-users', route: '/about'},
          {label: 'Vacantes', icon: 'fa fa-briefcase', route: '/positions'},
          {label: 'Contacto', icon: 'fa fa-headphones-alt', route: '/contact'},
        ]
      }
    },
    props:{
        menu_active: String,
        title: String,
    },
    computed: {
        ...mapState('account', ['status', 'user']),
        ...mapState({
            messages: state => state.Message.messages,
            messages_unread: state => state.Message.messages_unread, 
        }),
    },
    created () {
      if(this.status.loggedIn)
        this.getConversation(this.user.id_person);
    },
    methods: {
        ...mapActions('account', ['logout']),
        ...mapActions('Message', ['getConversation']),
        ...mapActions('Profil', ['goToNotifications']),
        logoutMenu() {
            this.logout();
            location.reload(true);
        },
        showMessages() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        },
        getImageSrcSession() {
            return require('@/assets/img/logo-346x80.png');
        },
        getCreationDate: function (item) {
            return UtilDate.frontEndDateTimeFormat(item.created_at);
        }
    }
};

</script>