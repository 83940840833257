import Vue from 'vue'
import VueRouter from 'vue-router'
import { AddMetadata } from './AddMetadata';

import HomePage from '../views/home/HomePage.vue'
import LoginPage from '../views/login/LoginPage.vue'
import RegisterPage from '../views/register/RegisterPage.vue'
import RedirectPage from '../views/login/RedirectPage.vue'
import ConfirmEmailPage from '../views/register/ConfirmEmailPage.vue'
import AboutPage from '../views/about/AboutPage.vue'
import PositionPage from '../views/position/PositionPage.vue'
import ContactPage from '../views/contact/ContactPage.vue'
import RecoverAccount from '../views/register/RecoverAccount.vue'
import ConfirmRecoverAccount from '../views/register/ConfirmRecoverAccount.vue'
import ProducShowPage from '../views/products/ProducShowPage.vue'
import PrivacyPage from '../views/privacy/PrivacyPage.vue'
import TermsPage from '../views/terms/TermsPage.vue'
import ProfilePage from '../views/account/ProfilePage.vue'
import ContractPage from '../views/products/ContractPage.vue'
import NotificationPage from '../views/notification/NotificationPage.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: HomePage, meta: {title: 'Beluva'}},
  { path: '/home', component: HomePage, meta: {
    title: 'Beluva',
      metaTags: [
        {
          name: 'description',
          content: 'Solución de servicios y gastos funerarios.'
        },
        {
          property: 'og:description',
          content: 'Solución de servicios y gastos funerarios.'
        }
      ]
    } 
  },
  { path: '/login', component: LoginPage, meta: { title: 'Beluva - Login'} },
  { path: '/register', component: RegisterPage, meta: { title: 'Beluva - Registro'}},
  { path: '/provider_redirect/:token/:id', component: RedirectPage, meta: { title: 'Beluva'}},
  { path: '/confirm_email/:token', component: ConfirmEmailPage, meta: { title: 'Beluva'}},
  { path: '/about', name: 'Nosotros', component: AboutPage,  meta: { title: 'Beluva - Nosotros'}},
  { path: '/positions', name: 'Puestos', component: PositionPage, meta: { title: 'Beluva - Puestos'} },
  { path: '/contact', name: 'Contacto', component: ContactPage, meta: { title: 'Beluva - Contacto'} },
  { path: '/recover/account', component: RecoverAccount, meta: { title: 'Beluva - Cuenta'} },
  { path: '/confirm/recover/account/:token', component: ConfirmRecoverAccount, meta: { title: 'Beluva'}},
  { path: '/product/:id', component: ProducShowPage, meta: { title: 'Beluva - Producto'}},
  { path: '/privacy', component: PrivacyPage, meta: { title: 'Beluva - Politicas de privacidad'} },
  { path: '/terms', component: TermsPage, meta: { title: 'Beluva - Terminos'} },
  { path: '/profile', component: ProfilePage, meta: { title: 'Beluva - Cuenta', requiresAuth: true } },
  { path: '/contract', component: ContractPage },
  { path: '/notification', component: NotificationPage },
  { path: '/validate/account', component: LoginPage },
  
  // otherwise redirect to home
  { path: '*', redirect: '/' }

]

const router = new VueRouter({
  linkActiveClass: 'open active',
  mode: 'history',
  routes: routes,
  base: process.env.BASE_URL,
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let data = localStorage.getItem('user');
    if (data == null || (data && !JSON.parse(data).token)) {
        next({
            path: '/login',
            params: { nextUrl: to.fullPath }
        })
    } else {
      next()
    }
  }else {
      next()
  }
})

AddMetadata(router);

export default router
