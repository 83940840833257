<template>
	<div>
		<div class="card_wrapper">
			<div class="card-small">
				<h2 class="titulo__tab">
					Su Prima
				</h2>
				<p>
					{{paymentAmmount || 0 | toCurrency}}
				</p>
			</div>
			<div class="card-small">
				<h2 class="titulo__tab">
					Su Saldo
				</h2>
				<p>
					{{balance.contract.total_amount_balance || 0 | toCurrency}}
				</p>
			</div>
			<div class="card-small card-small--big">
				<h2 class="titulo__tab">
					Su próximo vencimiento 

						<div class="warnning-status" v-if="balance && balance.contract_detail && balance.contract_detail.status == 'SUSPENDED'">AFILIACIÓN SUSPENDIDA</div>
					<div class="success-status" v-if="balance && balance.contract_detail && balance.contract_detail.status == 'PROCESS'">AFILIACIÓN ACTIVA</div>
				
				</h2>
				<p>
					{{nextPayment.due_date | sqlToCustomDate}}
				</p>
				<div class="pagos" style="max-width: none;">
					<h4 v-if="providers_actives.length > 0 && !isRepuchase" style="font-weight: bold;">
						{{this.balance.contract_detail && this.balance.contract_detail.status && this.balance.contract_detail.status == 'SUSPENDED' ? 
						'AFILIACIÓN SUSPENDIDA:' : 'Pagar ahora:'}}
						{{(balance && balance.payment_pending && balance.payment_pending.length > 0 ? (balance.payment_pending[balance.payment_pending.length -1].balance_total ) : 
							(balance && balance.affiliation_detail ? balance.affiliation_detail.amount_amortization : '')) | toCurrency}}			
					</h4>
					<h4 v-if="isRepuchase">Recompra:</h4>
					<PaymentGateway
					:amount="paymentAmmount ? paymentAmmount : (balance && balance.affiliation_detail ? balance.affiliation_detail.amount_amortization : 0)"
					v-on:payment_completed="pay"
					v-on:generate_references="generateReferences"
					:person="person ? person : {}"
					:loading="saving_payment"
					:on_finish_request="finish_pament"
					:references="references"
					:product="balance.product" 
					:no_operations="isRepuchase"
					:amount_payment="balance && balance.affiliation_detail ? balance.affiliation_detail.amount_amortization : 0"
					:affiliation_detail="balance && balance.affiliation_detail ? balance.affiliation_detail : ''"
					v-on:payment_click="showModalRepuchase"
					:providers="isRepuchase ? ['PAYPAL', 'OPENPAY', 'REFERENCES'] : providers_actives"
					v-on:downloadReceipt="downloadReceipt" />
				</div>
		
			</div>
		</div>

		<h2>
			Pagos pendientes
		</h2>
		<PaymentPlanList 
		v-if="balance.payment_pending && balance.payment_pending.length" 
		:calendars="balance.payment_pending"></PaymentPlanList>
		<h2>
			Historial de pagos
		</h2>
		<PaymentDoneList :made_payments="balance.madePayments" />

		<div v-show="repuchase.modal">
			<b-modal v-model="repuchase.modal" size="lg" header-bg-variant="primary" :no-close-on-backdrop="true">
			<template class="header" #modal-header="{ close }">                
				<h5 class="text-light">Renovación</h5>
				<b-icon icon="x-circle" class="text-light" style="cursor:pointer;" @click="close()"></b-icon>
			</template>
				<div class="text-center p-2" v-if="repuchase.getting">
					<b-spinner class="ml-auto" v-show="repuchase.getting" variant="primary" label="Spinning"></b-spinner>
				</div>
				<AlertMessageG :alert="repuchase.alert"/>
				<div class="m-1" v-if="!repuchase.getting">
					<div class="container">
						<div class="row ">
							<div class="col-md-12 text-center ">
								<h3 class="text-primary"> {{repuchase.label}}</h3> 
							</div>
						</div>
						<div v-if="!repuchase.product" class="row z-1 justify-content-center" id="products">
							<ProductCard 
							v-on:selected="(p)=>current_product=p" 
							:product="product" 
							v-for="(product) of repuchase.products" 
							:key="product.id" 
							class="col-lg-4 mb-2 " />
						</div>
						<div v-else class="row" id="products">
							<div class="col-md-4">
								<ProductCard :product="repuchase.product" />
							</div>
							<div class="col-md-8">
								<ValidationObserver >
								<form @submit.prevent="handleSubmitRepuchase">
									<p class="formfield col-3">
										<label for="comprar-plan">Plan</label>
										<multiselect
											v-model="repuchase.period"
											:options="repuchase.product.lst_configuration"
											:searchable="false"
											label="label_period"
											track-by="id"
											class="is-invalid"
											placeholder="Seleccione una opción"
											:allow-empty="false"
										></multiselect>
										<span
											style="display: block"
											v-if="!repuchase.period"
											class="invalid-feedback"
											>Seleccione una opción
										</span>
									</p>
									
									<p class="formfield col-3">
										<label for="comprar-cuota">Cuota 1/{{repuchase ? repuchase.period.number_payment : ''}}</label>
										<input class="input-box" type="text" id="comprar-cuota" name="comprar-cuota" :value="repuchase.amount.fee | toCurrency" disabled>
									</p>
									
									<p class="formfield col-3">
										<input
										type="hidden"
										class="form-control"
										id="product_disbursment_date"
										v-model="repuchase.data.disbursment_date"
										/>
									</p>
								
								</form>
								</ValidationObserver>
							</div>
						</div>
						
					</div>
				</div>
				<template #modal-footer>
					<PaymentGateway
					v-if="repuchase.product && repuchase.payment_provider != 'REFERENCES'"
					:amount="repuchase.amount.fee ? repuchase.amount.fee : 0"
					v-on:payment_completed="payRepuchase"
					:person="person ? person : {}"
					:loading="saving_payment"
					:on_finish_request="finish_pament"
					:references="references"
					:product="current_product" 
					:providers="[repuchase.payment_provider]"
					:is_modal = "true" />
					<!--
					<div v-else class="form-group  float-right">
						<button :class="['btn', 'custom-btn success', 'btn-block', 'btn-sm', saving_payment ? 'disabled': '']" style="padding: 0.37rem 0.5rem" @click="payRepuchase(repuchase.payment_provider)"><b-icon-card-text/> Pagar en tienda</button>
					</div>
					-->
					<i v-else>
						<span style="background: rgb(56 169 85);
    color: rgb(255, 255, 255);
    padding: 6.2px 64.88px;
    border-radius: 4px;
    font-size: 18px;" class="far fa-money-bill-alt" @click="payRepuchase(repuchase.payment_provider)"></span>
					</i>
				</template>	
			</b-modal>
		</div>
		
	</div>           
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AlertMessageG from '@/components/AlertMessageG.vue';
import PaymentGateway from '@/components/PaymentGateway.vue';
import PaymentPlanList from '@/components/PaymentPlanList.vue';
import PaymentDoneList from '@/components/PaymentDoneList.vue';
import ProductCard from '@/components/ProductCard.vue';
import { Messaging } from '@/helpers';

export default {
	data() {
		return {
			// saving_payment: false,
			finish_pament: false,
			current_product: null,
			current_provider: '',
			providers_actives: [],
		};
	},
	components:{
		AlertMessageG,
		PaymentGateway,
		PaymentPlanList,
		PaymentDoneList,
		ProductCard 
    },
    computed: {
        ...mapState({
			balance: state => state.Balance,
			repuchase: state => state.Balance.repuchase,
			person: (state) => state.Profil.person.item,
			references: (state) => state.ProductShow.references,
			products: (state) => state.Home.products.items,
			products_loading: (state) => state.Home.products.loading,
			amount_product: (state) => state.ProductShow.total_amount,
			saving_payment: (state) => state.ProductShow.loading,
			source: state => state.account.source,
        }),
		...mapState('account', ['user']),
		nextPayment(){
			const diffdate = new Date();
			const afterdates = this.balance.payment_pending.filter(d=> 
				new Date(d.due_date) - new Date(diffdate) > 0
			);
			let new_date = afterdates && afterdates.length > 0 ? afterdates[0] : {};
			
			return new_date;
			// return afterdates[0] ? {...afterdates[0], total: this.balance.contract.amount_amortization} :{};
		},
		paymentAmmount(){
			let amount_period = this.nextPayment.total || (this.balance.calendars[0]? this.balance.calendars[0].total: 0);
			if(this.balance.contract && this.balance.contract.total_amount_balance < amount_period) {
				amount_period = this.balance.contract.total_amount_balance;
			}
			return amount_period;
			//return process.env.NODE_ENV === 'development' && this.balance.contract.total_amount_balance? this.balance.contract.total_amount_balance : amount_period; 
		},
		isRepuchase(){
			//return true;
			if ((this.balance.contract_detail && this.balance.contract_detail.status && this.balance.contract_detail.status == 'SUSPENDED') || (this.balance.affiliation_detail && this.balance.affiliation_detail.status == 'PREAUTHORIZED')) {
				return false;
			} else {
				return (this.balance.contract_detail && this.balance.contract_detail.id && this.balance.contract_detail.status != 'PROCESS') || (this.balance.contract.total_amount_balance <= 0);
			}
		},
		/*
		providers_actives(){
			console.log(this.person);
			console.log(this.person.affiliation[0]);

			this.providers = ['PAYPAL', 'OPENPAY', 'REFERENCES'];
			if (this.person && !this.person.affiliation[0]) {
				this.providers = [];
				console.log(1);
			}
			if (this.person && this.person.affiliation[0]) {
				console.log(2);
				console.log(this.balance);
				console.log(this.balance.affiliation_detail);
				if (this.balance && this.balance.affiliation_detail && this.balance.affiliation_detail.status == 'PROCESS') {
					this.providers = ['PAYPAL', 'OPENPAY', 'REFERENCES'];
					console.log(3);
				} else {
					this.providers = [null, null, 'REFERENCES'];
					console.log(4);
				}
			}
			
			return this.providers;
		}
		*/
    },
    async created () {
		if (this.person) {
			for(const aff of this.person.affiliation) {
				if(!aff.is_sync){
					await this.SyncPerson(this.user.id_person);
					break;	
				}
			}	
		}
		
		if(this.user.id_person){
			this.getPerson(this.user);
			this.getBalance(this.user.id_person);
		}	
    },
    methods: {
		...mapActions('Balance', ['getBalance', 'getBalanceByCreddit', 'getRepuchaseData', 'saveRepuchase', 'getRepuchaseAmountsProduct']),
		...mapActions("ProductShow", ["payingProduct", "payingProductProvider", "generateReferenceProvider", "generateReferenceLendus"]),
		...mapActions("Home", ["getProducts"]),
		...mapActions("Profil", ["SyncPerson", "getPerson"]),
		...mapActions("ProductShow", [
		"generateReferenceProvider",
		"generateReferenceLendus",
		]),
		...mapActions("account", ["downloadPaymentReceipt"]),

		showHistoryPayments(contract_detail){
			this.getBalanceByCreddit({id_credit_account: contract_detail.client_religion, id_credit_account_detail:contract_detail.id_credit_account_detail});
		},
		showModalRepuchase(provider){
			if (provider == 'PAYPAL') {
				setTimeout(() => {
					this.repuchase.payment_provider = provider;
					/*setTimeout(() => {
						document.getElementsByClassName('zoid-outlet')[0].style.width = "10px";
					}, 500);*/
				}, 1000);
			} else {
				this.repuchase.payment_provider = provider;
			}
			this.getRepuchaseData(this.balance.contract.id_credit_account);
		},
		pay(provider, response_payment) {
			console.log("llegoooooo a pay")
			const amounts_product = { 
				fee: this.paymentAmmount,
				amount_with_discount: this.paymentAmmount,
				amount_discount: 0,
			}

			let affiliation_detail_id = '';
			let product = {};
			let period_conf;
			
			if(!this.isRepuchase && this.balance.affiliation_detail && this.balance.affiliation_detail._id){
				amounts_product.amount_with_discount= this.balance.affiliation_detail.total_amount;
                amounts_product.amount_discount= this.balance.affiliation_detail.amount_discount;
                amounts_product.fee= this.balance.affiliation_detail.amount_amortization;
				affiliation_detail_id = this.balance.affiliation_detail._id;
				product.id = this.balance.affiliation_detail.id_product;
				product.label = this.balance.affiliation_detail.label;
				period_conf = {};
				period_conf.id_relation =  this.balance.affiliation_detail.id_frecuency;
                period_conf.number_payment =  this.balance.affiliation_detail.number_payment;
                period_conf.days_grace =  this.balance.affiliation_detail.days_of_grace;
			}else{
				affiliation_detail_id = this.balance.contract_detail.id_credit_account_detail;
				product = this.balance.product
				period_conf = this.balance.contract.frecuency_label;
			}

			const data = {
				affiliation_id: this.balance.contract ? this.balance.contract.id_credit_account: '',
				affiliation_detail_id: affiliation_detail_id,
				provider,
				product: product,
				person: this.person,
				code_agent: this.person.agent_code,
				user: this.user,
				period_conf:period_conf,
				amounts_product,
				payment_code: 'PAYMENT_CONTRACT'
			};

			//console.log('data: ', data);

			if(provider === 'PAYPAL'){
				data.response_payment = response_payment;
				this.payingProduct(data).then(()=>{
					this.getBalance(this.user.id_person);
				}).catch(() =>{
					Messaging.makeToast('Ocurrio un error al procesar el pago','Error' ,'danger');
				});
			}else {
				data.card = response_payment;
				this.finish_pament = null;
				this.saving_payment = true;
				this.payingProductProvider(data).then(()=>{
					this.saving_payment = false;
					this.finish_pament = true;
					this.getBalance(this.user.id_person);
				}).catch(() =>{
					Messaging.makeToast('Ocurrio un error al procesar el pago','Error' ,'danger');
				});
			}
		},
		payRepuchase(provider, response_payment) {console.log("llego a recompra")
			const amounts_product = this.repuchase.amount;
			
			const data = {
				affiliation_id: null,
				provider,
				product: this.balance.product,
				person: this.person,
				code_agent: this.person.agent_code,
				user: this.user,
				period_conf: this.repuchase.period,
				amounts_product,
				payment_code: 'REPUCHASE',
				disbursment_date: this.repuchase.data.disbursment_date
			};

			if(provider === 'PAYPAL'){
				data.response_payment = response_payment;
				this.payingProduct(data).then(()=>{
					this.repuchase.modal = false;
					this.getBalance(this.user.id_person);
				}).catch(() =>{
					Messaging.makeToast('Ocurrion un error al procesar el pago','Error' ,'danger');
				});
			} else if(provider === 'REFERENCES'){
				this.payingProduct(data).then(()=>{
					this.repuchase.modal = false;
					this.getBalance(this.user.id_person);
				}).catch(() =>{
					Messaging.makeToast('Ocurrion un error al guardar la referencia','Error' ,'danger');
				});
			} else {
				data.card = response_payment;
				this.finish_pament = null;
				this.saving_payment = true;
				this.payingProductProvider(data).then(()=>{
					this.saving_payment = false;
					this.finish_pament = true;
					this.repuchase.modal = false;
					this.getBalance(this.user.id_person);
				}).catch(() =>{
					Messaging.makeToast('Ocurrion un error al procesar el pago','Error' ,'danger');
				});
			}
		},
		generateReferences(){
			if (this.balance.affiliation_detail && this.balance.affiliation_detail._id) {					
					const data = {
						provider: 'all',
						id_credit_account: this.balance.affiliation_detail._id,
						balance: this.balance
					}

					this.generateReferenceLendus(data);
			}
		},
		downloadReceipt(provider){
			this.current_provider = provider;
			const amounts_product = {
				fee: this.balance.affiliation_detail.amount_amortization,
				amount_with_discount: this.paymentAmmount,
				amount_discount: 0,
			}
			let current_reference_provider = this.references.find(x=>x.reference_provider == provider);
			let date = new Date();
			let options = { year: 'numeric', month: 'long', day: 'numeric' };
			let current_date = date.toLocaleDateString("es-ES", options);
			options = { hour12 : true, hour:  "numeric", minute: "numeric",seconds:"numeric" }
			let current_time = date.toLocaleTimeString("es-ES",options);
			this.current_date = current_date + ' a las ' + current_time;

			let data = {
				reference: current_reference_provider.reference_number,
				provider: this.current_provider,
				user: this.user,
				product: {label: this.balance.affiliation_detail.label},
				person: this.person,
				amounts_product: amounts_product,
				current_date: this.current_date,
			};
			this.downloadPaymentReceipt(data);
		},
		payment_providers(){
			if (this.person && this.balance) {
				if (
					(this.balance.contract_detail && this.balance.contract_detail.status && this.balance.contract_detail.status == 'SUSPENDED')
				|| (this.balance && this.balance.affiliation_detail && (this.balance.affiliation_detail.status == 'AUTHORIZED' || this.balance.affiliation_detail.status == 'PROCESS'))) {
					this.providers_actives = ['PAYPAL', 'OPENPAY', 'REFERENCES'];
					/*setTimeout(() => {
						document.getElementsByClassName('zoid-outlet')[0].style.width = "10px";
					}, 1000);*/
				} else {
					this.providers_actives = ['PAYPAL', 'OPENPAY', 'REFERENCES'];
					//this.providers_actives = [null, null, 'REFERENCES'];
				}
			}
		}
	},
	watch: {
		'balance.affiliation_detail': function () {
			//this.generateReferences();
			this.payment_providers();
		},
		'repuchase.period': function () {
			if(this.repuchase.period){
				this.getRepuchaseAmountsProduct({period_current: this.repuchase.period});
			}
		},
		source(){
			const linkSource = this.source;
			const downloadLink = document.createElement("a");
			const fileName = this.current_provider + ".pdf";

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		},
	}
};
</script>