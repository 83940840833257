<template>
  <!--
  <div data-aos="fade-up"  data-aos-delay="100">
      <div class="card pricing h-100" @click="buying(product)">
          <div class="card-body p-5">
              <div class="text-center">
                  <div class="badge badge-light badge-pill badge-marketing badge-sm">{{product.label}}</div>
                  <div class="pricing-price">
                      $</sup>{{product.amount_fee}}<span class="pricing-price-period"></span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  -->

  <b-card  class="text-center card" style="cursor:pointer" @click="buying(product)">
    <b-card-text>
      <div class="badge badge-success badge-pill badge-marketing badge-sm ">{{product.label}}</div>
    </b-card-text>
    <b-card-text>
      <div class="pricing-price">
        <h3>{{product.amount_fee | toCurrency}}<span class="pricing-price-period"></span></h3>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>

export default {
  props: {
    product: { type: Object, required: true }
  },
  methods: {
    buying(product){
      this.$emit('selected', product);
    }
  }
}
</script>
