import { Con_VacantService } from '../services';
import { JsOperation } from '../helpers';
import { Messaging } from '../helpers';

const state = {
    sendingCurriculum: false,
    places: { 
        loading: false,
        place_selected: null,
        items: []
    }
};

const actions = {
    
    getVacants({ commit }) {
        commit('getVacantsRequest');
        Con_VacantService.getVacants()
        .then(
            data => commit('getVacantsSuccess', data),
            error => commit('getVacantsFailure', error)
        );  
    },

    sendCurriculum({commit }, data) {
        return new Promise((resolve) => {
            commit('sendCurriculumRequest', data);
            Con_VacantService.sendCurriculum(data)
                .then(
                    () => { 
                        commit('sendCurriculumSuccess') 
                        resolve()
                    },
                    error => commit('sendCurriculumFailure', { error: error.toString() })
                );
        })
    },

};

const mutations = {
    getVacantsRequest(state) {
        state.places.loading = true;
    },
    getVacantsSuccess(state, data) {
        const vacants = data || [];
        let _places = vacants? JsOperation.groupBy(vacants, 'place') :[];
        state.places.loading= false;
        state.places.items= _places;
        state.places.place_selected = _places[0] || null;
    },
    getVacantsFailure(state, error) {
        state.places = { error };
    },
    
    sendCurriculumRequest(state){
        state.sendingCurriculum = true;
    },
    sendCurriculumSuccess(state) {
        state.sendingCurriculum = false;
        Messaging.makeToast('Hemos recibido tu curriculum, en breve uno de nuestros reclutadores te contactará', 'Vacante', 'success');
    },
    sendCurriculumFailure(state, {error}) {
        state.sendingCurriculum = false;
        Messaging.makeToast(error, 'Vacante', 'danger');
    },
};

export const Vacant = {
    namespaced: true,
    state,
    actions,
    mutations
};