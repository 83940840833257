<template>
    <div class="section" id="contrasenaolv">
        
        <ValidationObserver v-slot="{ invalid }" >
        <form @submit.prevent="handleSubmit" class="recuperarcon" id="recuperarcon">  
            <h2>Recuperar Contraseña</h2>
           
           <div class="width-full">
                <AlertMessage/>
           </div>
            <p class="formfield ">
                <label for="registro-email">Email</label>
                <ValidationProvider rules="required|email" :bails="false" v-slot="{ errors }">
                    <input  v-model="user.email" :class="{ 'is-invalid':  errors[0] }" class="input-box" type="email" id="registro-email" name="registro-email" required>
                    <span class="invalid-feedback" >{{ errors[0] }}</span>
                </ValidationProvider>
            </p>
            <p class="formfield">
                <vue-recaptcha :sitekey="captchaKey" @verify="onVerify" @expired="onExpired" :loadRecaptchaScript="true"></vue-recaptcha>
            </p>
        
        <button class="enviar" @click="recoverAccount()" :disabled="invalid || !captcha">Enviar</button>
        </form> 
        </ValidationObserver>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AlertMessage from '@/components/AlertMessage.vue';
import VueRecaptcha from 'vue-recaptcha';

export default {
    components:{
        AlertMessage,
        VueRecaptcha
    },
    data () {
        return {
            user: {
                email: "",
            },
            captcha: false,
            captchaKey: `${process.env.VUE_APP_RECAPTCHA}`
        }
    },
    computed: {
       ...mapState("account", ["status"]),
    },
    created () {
    },
    mounted(){
        this.clear();
    },
    methods: {
        ...mapActions("account", ["recover_account"]),
        ...mapActions("alert", ["clear"]),
        handleSubmit () {
        },
        recoverAccount() {
            this.submitted = true;
            const { user, captcha } = this;

            if (this.isEmailValid()) {
                this.recover_account({user, captcha})
            }
        },
        isEmailValid() {
            this.user.email = this.user.email.trim();
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.user.email);
        },
        onVerify(){
            this.captcha = true;
        },
        onExpired(){
            this.captcha = false;
        }
    }
};
</script>