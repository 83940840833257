<template>
    <div class="section" id="contacto">
        <h2>Contacto</h2>
        <div class="wrapper">
            <div v-for="cat of cat_contact" v-bind:key="cat.id" class="card-contacto">
                <div class="contacto-titulo">
                    <div class="whatsapp" > 
                        <i class="fab fa-whatsapp"></i>
                    </div>
                    <h4>{{cat.label}}</h4>
                </div>
                <div class="texto-card">
                    <p v-html="cat.description"></p>
                </div>
                <div class="contactanos">
                    <a href="#content" class="custom-btn-gold" @click="whatsapp(cat)">Contáctanos</a>
                </div>
            </div>
        </div>

        <div class="formulario-container">
            <h2>Escríbenos</h2>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form autocomplete="on"  @submit.prevent="handleSubmit(onSubmit)"  class="formulario">
                <!-- <AlertMessage v-if="sendEmail"/> -->
            
                <section class="contacto-form">
                    <p class="formfield">
                        <label for="nombre">Nombre</label>
                        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                            <input v-model="data.name"  :class="{ 'is-invalid':  errors[0] }" class="input-box" type="text" placeholder="Escriba su Nombre Completo" id="contactonombre" name="nombre" required>
                            <span class="invalid-feedback" >{{ errors[0] }}</span>
                        </ValidationProvider>
                    </p>
                    <p class="formfield">
                        <label for="telefono">Teléfono</label>
                        <ValidationProvider rules="required|length:10" :bails="false" v-slot="{ errors }">
                            <input v-model="data.phone" :class="{ 'is-invalid':  errors[0] }" class="input-box" type="tel" placeholder="Escriba su teléfono" id="contactotelefono" name="telefono" required>
                            <span class="invalid-feedback" >{{ errors[0] }}</span>
                        </ValidationProvider>
                    </p>
                    <p class="formfield">
                        <label for="email">Email</label>
                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                            <input v-model="data.email" :class="{ 'is-invalid':  errors[0] }" class="input-box" type="email" id="contactoemail" name="email" placeholder="Escriba su correo" required>
                            <span class="invalid-feedback" >{{ errors[0] }}</span>
                        </ValidationProvider>
                    </p>
                    <p class="formfield">
                        <label for="mensaje">Mensaje</label>
                        <!--
                        <ValidationProvider name="message" rules="required|min:50" v-slot="{ errors }">
                            <textarea v-model="data.message" :class="{ 'is-invalid':  errors[0] }" placeholder="Escribe tu mensaje" rows="5" cols="33" class="input-box" id="contactomensaje" name="mensaje" required></textarea>
                            <span class="invalid-feedback" >{{ errors[0] }}</span>
                        </ValidationProvider>-->
                        <textarea v-model="data.message" placeholder="Escribe tu mensaje" rows="5" cols="33" class="input-box" id="contactomensaje" name="mensaje"></textarea>
                    </p>
                    <p class="formfield">
                        <ValidationProvider :rules="captcha? '':'required'" v-slot="{ errors }">
                        <vue-recaptcha :sitekey="captchaKey" @verify="captcha=true" @expired="captcha=false" :loadRecaptchaScript="true" :class="{ 'is-invalid':  errors[0] }" ></vue-recaptcha>
                        <span v-if="!captcha" class="invalid-feedback" >{{ errors[0] }}</span>
                        </ValidationProvider>
                    </p>
                    <button class="enviar custom-btn-gold" type="submit" >
                        <b-spinner v-show="status.loading" small type="grow"></b-spinner>
                        Enviar
                    </button>
                </section>
            </form>
            </ValidationObserver>

            <br>
             <div class="row text-right" style="font-size: 12px; color: gray;"><i class="fa fa-phone-square"></i> {{organization.long_code}} | <i class="fa fa-envelope-square"></i> {{organization.alias3}}  </div>

        </div>


       
    </div>
</template>

<script>
// import AlertMessage from '@/components/AlertMessage.vue'
import { mapState, mapActions } from "vuex";
import VueRecaptcha from 'vue-recaptcha';

export default {
    components:{
        // AlertMessage,
        VueRecaptcha
    },
    data () {
        return {
            data: {
                name: '',
                phone: '',
                email: '',
                message: ''
            },
            captcha: false,
            captchaKey: `${process.env.VUE_APP_RECAPTCHA}`
        }
    },
    computed: {
        ...mapState({
			status: state => state.account.status,
			user: state => state.account.user,
            person: state => state.Profil.person.item,
            cat_contact: state => state.ContactMessage.cat_wahtsapp.items,
            organization: state => state.ContactMessage.organization,
        }),
    },
    mounted() {
        this.clear();
        this.getCatWahtsapp();
        this.getOrganization();
        if(this.status.loggedIn){
            this.getPerson(this.user);
            this.data.name = this.user.username;
            this.data.email = this.user.email;
            setTimeout(() => {
                if(this.person){
                    this.data.name = (this.person.name || '') + ' ' + (this.person.lastname || '') + (this.person.second_lastname || '');
                    this.data.phone = this.person.phone1;
                }
            }, 2000);
        }
    },
    methods: {
        ...mapActions("ContactMessage", ["sendEmail", "getCatWahtsapp","getOrganization"]),
        ...mapActions("Profil", ["getPerson"]),
        ...mapActions("alert", ["clear", "error"]),
        onSubmit () {
            const { data } = this;
            this.sendEmail(data).then(() => {
                this.data.name = '',
                this.data.phone = '',
                this.data.email = '',
                this.data.message = '',
                // this.captcha = false,
                this.$refs.observer.reset()
            });
        },
        whatsapp(cat){
            if (cat.long_code) {
                let country_code = cat.long_code.length> 10 ? '': '52';
                let to = country_code+cat.long_code;
                let message = "Contacto " + cat.label;
                window.open(`https://api.whatsapp.com/send?phone=${to}&text=${message}`);
            }
        },
    }
};
</script>