import { personService, cat_CatalogService } from '../services';
import { UtilDate, Messaging } from '../helpers';
import router from '../router'

const state = {
    message: {},
    person: {
        loading: false,
        updating: false,
        updating_img_profile: false,
        item: null
    },
    cat_addres: { 
        requesting: false,
        cat_countries: [],
        cat_states: [],
        cat_municipies: [],
        cat_localities: []
    },
    profile: {
        tabindex: 0,
    }
};

const actions = {
    
    getCatCountry({ commit }) {
        cat_CatalogService.getCat_CatalogByCodeType('SYST_TYPE_COUNTRY')
        .then( catalogs => commit('getCatCountrySuccess', catalogs) );
    },

    getCatalogsAddresByPostCode({ commit }, postal_code) {
        commit('getCatalogsAddresByPostCodeRequest');

        cat_CatalogService.getCat_CatalogAddresByPostCode(postal_code)
            .then(
                data => commit('getCatalogsAddresByPostCodeSuccess', data),
                error => commit('getCatalogsAddresByPostCodeFailure', error)
            );
    },
    
    getPerson({ commit }, user) {
        commit('getPersonRequest');

        personService.getPersonProfile(user && user.id_person ? user.id_person : '')
            .then(
                person => { 
                    person.email = user.email; 
                    person.user_id = user.id; 
                    commit('getPersonSuccess', person)
                },
                error => {
                    commit('getPersonFailure', error)
                }
            );
    },

    updatePerson({ dispatch, commit }, {person, sync}) {
        commit('updateRequest', person);

        personService.updatePersonProfile(person)
            .then(
                _person => {
                    commit('updateSuccess', _person); 
                    Messaging.makeToast('Los datos han sido guardado correctamente','Perfil ' ,'success');
                    //dispatch('alert/success', {message: 'Perfil actualizado correctamente', code: 'profile'}, { root: true });
                    dispatch('account/refresh_usersession_email', person.email, { root: true });
                    if(sync){
                        dispatch('SyncPerson', person._id);
                    }
                },
                error =>{ 
                    commit('updateFailure', { error: error.toString() })
                    Messaging.makeToast(error.toString(),'Error ' ,'danger');
                    //dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },

    updateImageProfile({ dispatch, commit }, data) {
        commit('updateImageProfileRequest', data.file);
        personService.updateImageProfile(data.id_person, data.file)
            .then(
                _person => {
                    commit('updateImageProfileSuccess', _person); 
                    dispatch('account/refresh_usersession_photo', _person.avatar, { root: true });
                },
                error =>{ 
                    commit('updateImageProfileFailure', { error: error.toString() })
                }
            );
    },

    updateDocumentPerson({ commit }, data) {
        commit('updateDocumentPersonRequest', data.id_cat_catalog_document);
        personService.updateDocumentPerson(data.id_person, data.id_cat_catalog_document, data.file)
            .then(
                _person => commit('updateDocumentPersonSuccess', _person),
                error => commit('updateDocumentPersonFailure', { error: error.toString() })  
            );
    },

    deleteDocumentPerson({commit }, {id_person, id_catalog}) {
        commit('deleteDocumentPersonRequest', id_catalog);

        personService.deleteDocumentPerson(id_person, id_catalog)
            .then(
                () => commit('deleteDocumentPersonSuccess', id_catalog),
                error => commit('deleteDocumentPersonFailure', { error: error.toString() })
            );
    },

    async SyncPerson({ commit, dispatch }, id_person) {
        // commit('SyncPersonRequest');
        personService.SyncPerson(id_person)
            .then(
                person =>{ 
                    dispatch('Balance/getBalance', id_person, { root: true });
                    commit('SyncPersonSuccess', person)
                },
                // error => commit('SyncPersonFailure', error)
            );
    },

    async SyncPersonProductReference({ commit, dispatch }, { id_person, reference_data}) {
        // commit('SyncPersonRequest');
        personService.SyncPerson(id_person)
            .then(
                person =>{ 
                    //dispatch('Balance/getBalance', id_person, { root: true });
                    dispatch('ProductShow/generateReferenceLendus', reference_data, { root: true });
                    commit('SyncPersonSuccess', person)
                },
                // error => commit('SyncPersonFailure', error)
            );
    },

    updatePreferences({ dispatch, commit }, person) {
        commit('updatePreferencesRequest', person);

        personService.updatePreferences(person)
            .then(
                _person => {
                    commit('updatePreferencesSuccess', _person); 
                    // dispatch('alert/success', {message: 'Preferencias actualizadas', code: 'notification'}, { root: true });
                    dispatch('account/refresh_usersession_email', person.email, { root: true });
                },
                error =>{ 
                    commit('updatePreferencesFailure', { error: error.toString() })
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },

    changeTabEvent({ commit }, indextab) {
        commit('changeTabEvent', indextab);
    },

    goToNotifications({ commit }) {
        commit('changeTabEvent', 4);
        if (!router.currentRoute.fullPath.includes('profile')) {
            router.push('/profile');
        } 
    },

    newPerson({commit}){
        commit('newPerson');
        personService.getPersonDataById('').then((person) => {
            commit('newPersonSuccess', person);
        });
    },

    async getFileWitoutSession({ commit, dispatch }, data) {
        personService.getFileWitoutSession(data.file, data.stored_file)
            .then(
                files => commit('getFileWioutSessionSuccess', { file: files, stored_file: data.stored_file} ),
                error =>dispatch('alert/error', { message: error, code: '' }, { root: true })   
            );
    },
};

const mutations = {
    getPersonRequest(state) {
        state.person.loading = true;
    },
    getPersonSuccess(state, person) {
        person.birthdate = UtilDate.frontEndDateFormat(person.birthdate);
        person.image_profile = person.image_profile || {};
        person.address = person.address || {};
        person.address.cat_locality =  person.address.cat_locality || {}
        person.files = person.files || [];
        state.person.item = person;
        state.person.loading = false;
    },
    getPersonFailure(state, error) {
        state.person.loading = false;
        state.person.error = error;
    },

    updateRequest(state) {
        state.person.updating = true;
    },
    // eslint-disable-next-line
    updateSuccess(state, person) {
        state.person.updating = false;
        state.mess = 'Perfil actualizado correctamente';
    },
    updateFailure(state) {
        state.person.updating = false;
    },

    getCatCountrySuccess(state, data) {
        state.cat_addres.cat_countries = data;
    },
    
    getCatalogsAddresByPostCodeRequest(state) {
        state.cat_addres.requesting = true;
    },
    getCatalogsAddresByPostCodeSuccess(state, data) {
        const addres_api =  data[0] || {}; 
        state.cat_addres.cat_localities = data[1] || [];
        state.cat_addres.cat_municipies = data[2] || [];
        state.cat_addres.cat_states = data[3] || [];

        state.person.item.address.cat_country = state.cat_addres.cat_countries.find(x=> x.id === addres_api.id_cat_country);
        state.person.item.address.cat_state = state.cat_addres.cat_states.find(x=> x.id === addres_api.id_cat_state);
        state.person.item.address.cat_municipality =  state.cat_addres.cat_municipies.find(x=> x.id === addres_api.id_cat_municipality);
        state.person.item.address.cat_locality =  state.cat_addres.cat_localities.find(x=> x.id === addres_api.id_cat_locality);
        state.cat_addres.requesting = false;
    },
    getCatalogsAddresByPostCodeFailure(state) {
        state.cat_addres.cat_localities = [];
        state.cat_addres.cat_municipies = [];
        state.cat_addres.cat_states = [];

        state.person.item.address.cat_state = null;
        state.person.item.address.cat_municipality = null;
        state.person.item.address.cat_locality = null;
        state.cat_addres.requesting = false;
        Messaging.makeToast(`No se encontro el codigo postal ${state.person.item.address.post_code}`,'Código postal' ,'warning');
    },

    //Subir imagen de perfil
    updateImageProfileRequest(state){
        state.person.updating_img_profile = true;
    },
    updateImageProfileSuccess(state, person) {
        state.person.item.avatar = person.avatar;
        state.person.updating_img_profile = false;
    },
    updateImageProfileFailure(state, {error}) {
        state.person.updating_img_profile = false;
        Messaging.makeToast(error, 'Imágen Perfil', 'danger');
    },

    //Subir documento de identificcaión
    updateDocumentPersonRequest(state, id){
        // add 'updating:true' property to user being updating
        state.person.item.files = state.person.item.files.map(item =>
            item.cat_row_entity.id === id
                ? { ...item, updating: true }
                : item
        )
    },
    updateDocumentPersonSuccess(state, person) {
        state.person.item.files = person.files;
    },
    updateDocumentPersonFailure(state, {error}) {
        state.person.item.files.forEach(x=> x.updating = false);
        Messaging.makeToast(error, 'Documento', 'danger');
    },

    // Delete docmuent
    deleteDocumentPersonRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.person.item.files = state.person.item.files.map(item =>
            item.cat_row_entity.id === id
                ? { ...item, deleting: true }
                : item
        )
    },
    deleteDocumentPersonSuccess(state, id) {
        // remove deleted item from state
        // state.person.item.catalog_documents = state.person.item.files.filter(x => x.id_cat_catalog_document !== id)
        let cat = state.person.item.files.find(x => x.cat_row_entity.id === id)
        cat.complete_path = null;
        cat.deleting = false;
    },
    deleteDocumentPersonFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to item 
        state.person.item.files = state.person.item.files.map(item => {
            if (item.cat_row_entity.id === id) {
                // make copy of item without 'deleting:true' property
                // eslint-disable-next-line
                const { deleting, ...userCopy } = item;
                // return copy of item with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return item;
        })
    },

    SyncPersonSuccess(state, person) {
        state.person.item.code_client = person.code_client;
    },

    changeTabEvent(state, tabindex) {
        state.profile.tabindex = tabindex;
    },
    

    updatePreferencesRequest(state) {
        state.person.updating = true;
    },
    // eslint-disable-next-line
    updatePreferencesSuccess(state, person) {
        state.person.updating = false;
        Messaging.makeToast('Su configuración de notificasiones se ha guardada correctamente','Configuración ' ,'success');
    },
    updatePreferencesFailure(state) {
        state.person.updating = false;
    },

    
    // Socket events
    SOCKET_PERSON_UPDATE(state, person) {
        const isforcurrent = state.person.item._id === person._id;
        if(isforcurrent)  {
            const old_person = {...state.person.item};
            person.birthdate = UtilDate.frontEndDateFormat(person.birthdate);
            state.person.item = {
                ...person, 
                files: old_person.files,
                email: old_person.email,
            };

            if(person.files){
                for(const doc of state.person.item.files){
                    const changed = person.files ? person.files.find(x=> x._id===doc.id && x.is_active) : null;
                    if(changed){
                        doc.complete_path = changed.complete_path;
                    }
                }
            }
        }
    },

    newPerson(){
        //console.log('generando una persona nueva');
    },
    newPersonSuccess(state, person) {
        person.birthdate = UtilDate.frontEndDateFormat(person.birthdate);
        person.image_profile = person.image_profile || {};
        person.address = person.address || {};
        person.address.cat_locality =  person.address.cat_locality || {}
        person.files = person.files || [];
        state.person.item = person;
        /*
        
        state.person.loading = false;
        */
    },
    getFileWioutSessionSuccess(state, data){

        let files = state.person.item.files;
        state.person.item.files = [];
        files.forEach(file => {
            if (file.id == data.stored_file.id) {
                file.description = data.file.description;
                file.path_file = data.file.path_file;
                file.extention = data.file.extention;
                file.is_blob = data.file.is_blob;
                file.is_image = data.file.is_image;
                file.cat_mime_type = data.file.cat_mime_type;
                file.content_type = data.file.content_type;
                file.alias3 = data.file.alias3;
                file.is_active = data.file.is_active;
                file.default_file = data.file.default_file;
            }
            state.person.item.files.push(file);
        });
    }
};

export const Profil = {
    namespaced: true,
    state,
    actions,
    mutations
};
