<template>
    <div class="section" id="login">
            <div class="wrapper-login">
                <div><!-- class="login-container" -->
                    <form action="/action_page.php" @submit.prevent="handleSubmit">
                      <AlertMessage/>
                      <div class="row" style="text-align:center;">
                        <img src="@/assets/img/logobelu.png" alt="" width="200" />
                        <!--<div class="vl">
                          <span class="vl-innertext">o</span>
                        </div> -->
                  
                        <!-- <div class="col"
                          <BtnLoginProvider :disabled="status.loggingIn" icon="fab fa-facebook-f" provider_name="facebook" action="LOGIN" /> &nbsp;
                          <BtnLoginProvider :disabled="status.loggingIn" icon="fab fa-google" provider_name="google" action="LOGIN" />
                          <div role="alert" class="alert alert-danger alert-dismissible"  v-show="resend_email">
                              <span style="font-size:12px">Puedes 
                                  solicitar reenviar el email <a href="" style="color:#e16656;font-weight: bold;font-size: 11px;" v-on:click.prevent="resendEmailConfirmation">aquí</a>, o busca en la carpeta de spam.</span>
                              <button @click="hiddenConfirm" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                        </div> -->
                  
                        <div class="row" style="padding: 20px;"><!-- class="col"
                          <div class="hide-md-lg">
                            <p>O inicia sesión manualmente:</p>
                          </div>  -->
                  
                          <input v-model="username" type="text" name="username" placeholder="Usuario" required>
                          <div v-show="submitted && !username" class="invalid-feedback">Email es requerido</div>
                          <input v-model="password" type="password" name="password" placeholder="Contraseña" required>
                          <div v-show="submitted && !password" class="invalid-feedback">Contraseña es requerido</div>
                          <input type="submit" :disabled="status.loggingIn" value="Iniciar Sesión">
                            <!--
                            <button type="submit" class="btn btn-primary " :disabled="status.loggingIn">
                                <b-spinner v-show="status.loggingIn" small type="grow"></b-spinner>
                                Iniciar Sesión
                            </button>
                            -->
                        </div>
                        <div role="alert" class="alert alert-danger alert-dismissible"  v-show="resend_email">
                              <span style="font-size:12px">Puedes 
                                  solicitar reenviar el email <a href="" style="color:#e16656;font-weight: bold;font-size: 11px;" v-on:click.prevent="resendEmailConfirmation">aquí</a>, o busca en la carpeta de spam.</span>
                              <button @click="hiddenConfirm" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                  
                      </div>
                    </form>
                  </div>
                  
                  <div class="bottom-container">
                    <div class="row">
                      <div class="col">
                        <a href="/register" style="color:white" class="btn">Registrarse</a>
                      </div>
                      <div class="col">
                        <a href="/recover/account" style="color:white" class="btn">Olvidaste Password?</a>
                      </div>
                    </div>
                  </div>
            </div>

    </div>
    
</template>

<script>
//import BtnLoginProvider from '@/components/BtnLoginProvider.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import { mapState, mapActions } from 'vuex'

export default {
    components:{
        //BtnLoginProvider,
        AlertMessage
    },
    data () {
        return {
            username: '',
            password: '',
            submitted: false,
            type_input_pass: 'password',
            icon_eye: true
        }
    },
    computed: {
        ...mapState('account', ['status']),
        ...mapState({
            resend_email: state => state.account.resend_email,
        })
        
    },
    created () {
         if(this.status.loggedIn){
            this.$router.push('/home');
        }
    },
    mounted(){
        this.clear();
        this.verifiyLoginStatus();
    },
    methods: {
        ...mapActions('account', ['login', 'logout','resendEmailConfirmation','verifiyLoginStatus','hiddenConfirm']),
        ...mapActions("alert", ["clear"]),
        ...mapActions({
          clearAlert: 'alert/clear' 
        }),
        handleSubmit () {
            this.submitted = true;
            const { username, password } = this;
            if (username && password) {
                this.login({ username, password })
            }
        },
        showPassword(){
            this.type_input_pass = this.type_input_pass == 'text' ? 'password': 'text';
            if(this.type_input_pass == 'text'){
                this.icon_eye = false;
            }else{
                this.icon_eye = true;
            }
        }
    }
};
</script>

<style>
.field-icon {
  float: right;
  margin-right: 8px;
  margin-top: -30px;
  position: relative;
  /*z-index: 2;*/
  cursor:pointer;
}

.hidde{
    display: none;
}
</style>