<template>
    <div class="section content">
        <!-- Login form-->
        <form @submit.prevent="handleSubmit" class="form-data">
            <AlertMessage/>
            <h2 class="title">Recuperar Contraseña</h2>
            <p class="formfield col-2">
                <label class="small mb-1 text-dark" for="inputNewPassword">Contraseña nueva</label>
                <input class="input-box" type="password" placeholder="Ingresa tu nueva contraseña" v-model="user.new_password">
            </p>

            <p class="formfield col-2">
                <label class="small mb-1 text-dark" for="inputConfirmNewPassword">Confirma la nueva contraseña</label>
                <input class="input-box" id="inputConfirmNewPassword" type="password" placeholder="Confirma tu nueva contraseña" v-model="user.confirm_password">
                <span v-show="submitted && !user.confirm_password.length" class="invalid-feedback">Confirmación de contraseña es requerido</span>
            </p>

            <!-- Form Group (login box)-->
            <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                <button class="btn-green" @click="recoverAccountConfirm()">Enviar</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AlertMessage from '@/components/AlertMessage.vue';

export default {
    components:{
        AlertMessage
    },
    data () {
        return {
            user: {
                token: this.$route.params.token,
                new_password: "",
                confirm_password: ""
            },
            submitted: false,
        }
    },
    computed: {
       ...mapState("account", ["status"]),
    },
    created () {
    },
    mounted(){
        this.clear();
    },
    methods: {
        ...mapActions("account", ["confirm_recover_account"]),
        ...mapActions("alert", ["clear"]),
        handleSubmit () {
        },
        recoverAccountConfirm() {
            this.submitted = true;
            const { user } = this;
            this.confirm_recover_account({user})
        }
    }
};
</script>