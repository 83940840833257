<template>
  <div @click="toggle">
    <slot name="toggler">
      <button>Toggle</button>
    </slot>
    <slot v-if="this.sharedState.active" />
  </div>
</template>

<script>
  export default {
    name: 'AppDropdown',
    provide () {
      return {
        sharedState: this.sharedState
      }
    },
    data () {
      return {
        sharedState: {
          active: false
        }
      }
    },
    methods: {
      toggle () {
        this.sharedState.active = !this.sharedState.active
      }
    }
  }
  // https://medium.com/@ronnow/dropdowns-in-vue-the-right-way-8ff9e8f36d4a
</script>