<template>
   <div id="layoutDefault_content">
        <section class="pt-10">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <!-- Basic login form-->
                        <div class="card border-0 rounded-lg mt-5 mb-5">
                            <div class="card-body">
                                <div class="row justify-content-center align-items-center">
                                    La cuenta de Email ha sido confirmada con éxito
                                </div>
                                <div class="row justify-content-center align-items-center">                                   
                                    <img src="@/assets/img/gif/email.gif" class="d-inline-block align-top img_with" loading="lazy">
                                </div>
                                <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <a class="btn-green" href="/login">Iniciar sesión</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>	                	
        </section>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    data () {
        return {
            token: this.$route.params.token,
            state:{
                status: ''
            }
        }
    },
    computed: {
       ...mapState("account", ["status"]),
    },
    created () {
    },
    mounted(){
        const { state, token } = this;
        this.confirm_email({state, token});
    },
    methods: {
      ...mapActions("account", ["confirm_email"])
    }
};
</script>

<style scoped>
    .img_with{
        width: 350px;
    }
</style>