<template>
	<div >
		<h2 class="titulo__tab">
			Seguridad
		</h2>
		<div class="seguridad-wrapper">
			<div class="cambiocontrasena">
				<form class="seguridad" @submit.prevent="updatePasswordAccount()" >
				<AlertMessage v-if="alert.code == 'security'"/>
				<section class="datoscuenta">
					<Modal/>
					<p class="formfield">
						<label for="passwordactual">Contraseña actual</label>
						<input v-model="_user.password" :class="[{ 'is-invalid': state.submitted && (_user.password == undefined || _user.password.length <= 0) }, 'input-box']" type="password" id="passwordactual" name="passwordactual">
						<span v-show="state.submitted && (_user.password == undefined || _user.password.length <= 0)" class="invalid-feedback">Contraseña actual es requerido</span>
					</p>
					<p class="formfield">
						<label for="nuevopassword">Nueva contraseña</label>
						<input v-model="_user.new_password" :class="[{ 'is-invalid': state.submitted && (_user.new_password == undefined || _user.new_password.length <= 0) }, 'input-box']" type="password" id="nuevopassword" name="nuevopassword">
						<span v-show="state.submitted && (_user.new_password == undefined || _user.new_password.length <= 0)" class="invalid-feedback">Nueva contraseña es requerido</span>
					</p>
					<p class="formfield">
						<label for="confirmarpassword">Confirma la contraseña</label>
						<input v-model="_user.confirm_password" :class="[{ 'is-invalid': state.submitted && (_user.confirm_password == undefined || _user.confirm_password.length <= 0) },  'input-box']" type="password" id="confirmarpassword" name="confirmarpassword">
						<span v-show="state.submitted && (_user.confirm_password == undefined || _user.confirm_password.length <= 0)" class="invalid-feedback">Confirmación de contraseña es requerido</span>
					</p>
					<input :disabled="_updating_password" class="button" type="submit" value="Guardar"  @click="updatePasswordAccount()">
					<!-- <button :disabled="_updating_password" class="button" @click="updatePasswordAccount()">Guardar</button> -->
					
				</section>
				</form>
			</div>
			<div class="eliminarcuenta">
				<h3>
					Eliminar cuenta
				</h3>
				<AlertMessageG :alert="state.alert_removeacc"/>
				<p class="justify-text">
					Borrar la cuenta es una acción permanente que no puede deshacerse. Si estás seguro de querer borrar tu cuenta oprime el botón abajo.
				</p>

				<button @click="deleteAccount()" :disabled="state.removing_account" class="button">
					<b-spinner v-show="state.removing_account" small type="grow"></b-spinner> Entiendo. Deseo borrar mi cuenta
				</button>
			</div>

			<NotificationSetting/>
				
		</div>

	</div>
               
</template>

<script>
import AlertMessage from '@/components/AlertMessage.vue'
import AlertMessageG from '@/components/AlertMessageG.vue';
import { Messaging } from '@/helpers';
import Modal from '@/components/Modal.vue'
import { mapState, mapActions } from 'vuex'
import NotificationSetting from '@/views/account/NotificationSetting';

export default {
	components:{
		AlertMessage,
		AlertMessageG,
		Modal,
		NotificationSetting
    },
    computed: {
		...mapState({
			_user: state => state.account.user,
			_updating_password: state => state.account.updating_password,
			_modal: state => state.modal,
			state: state => state.account,
			alert: (state) => state.alert,
		}),
    },
    created () {
		//console.log('this._user: ', this._user);
	},
	mounted(){
		this.clear();
	},
    methods: {
		...mapActions("account", ["update_password_account", "remove_account"]),
		...mapActions("alert", ["clear", "error"]),
		...mapActions("modal", ["primary"]),
		updatePasswordAccount() {
			this.clear();
            this.submitted = true;
			let { user } = this;
			user = this._user;

			let valid = true;
			let error = '';

			if (this._user.password == undefined || this._user.password.trim() == '') {
				error += 'Ingrese la contraseña actual. <br>';
				valid = false;
			}
			if (this._user.new_password == undefined || this._user.new_password.trim() == '') {
				console.log("Ingrese  la nueva contraseña")
				error += 'Ingrese la nueva contraseña. <br>';
				valid = false;
			}

			if (this._user.confirm_password == undefined || this._user.confirm_password.trim() == '') {
				error += 'Ingrese la confirmación de la contraseña.';
				valid = false;
			}

			if (valid) {
				this.update_password_account({user});
			} else {
				this.error({message: error, code: 'security'});
			}
		},
		deleteAccount() {
			// this.primary({title: 'Borrar cuenta', message: 'Borrar la cuenta es una acción permanente que no puede deshacerse. ¿Estas seguro de querer borrar tu cuenta?', btn_ok: 'Sí'});

			this.$bvModal.msgBoxConfirm('Borrar la cuenta es una acción permanente que no puede deshacerse. ¿Estas seguro de querer borrar tu cuenta?', Messaging.confirmConfig())
			.then(confirmed => {
				if(confirmed)
					this.remove_account(this._user)
			})
		}
    }
};
</script>