export const JsOperation = {
    groupBy,
    toFormData
};

function groupBy(array, key){
    const result = {}
    array.forEach(item => {
      if (!result[item[key]]){
        result[item[key]] = []
      }
      result[item[key]].push(item)
    })

    let result_array = [];
    for (let _key in result) {
      let item = {items: result[_key]}
      item[key] = _key;
      result_array.push(item);
    }
    return result_array
}

function toFormData(item){
  var form_data = new FormData();
  for ( let key in item ) {
      form_data.append(key, item[key]);
  }
  return form_data;
}