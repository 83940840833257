<template>
  <div class="slider">
    <VueSlickCarousel  v-bind="slickOptions">
      <!--
      <div v-for="slide of sliders" v-bind:key="slide.id" class="slides" v-bind:style="{ backgroundImage: 'url(' + slide.source + ')' }">
        <h2>{{slide.description}}</h2>
      </div>
      -->
      <div class="slides slide1"><h2></h2></div>
      <div class="slides slide2"><h2></h2></div>
      <div class="slides slide3"><h2></h2></div>
      <div class="slides slide4"><h2></h2></div>
    </VueSlickCarousel>
  </div>
  <!--
  <div class="carousel-wrapper">
    <VueSlickCarousel :arrows="false" :dots="false">
      <div v-for="slide of sliders" v-bind:key="slide.id" class="img-wrapper">
        <img :src="slide.source" style="width:90px" />
      </div>
    </VueSlickCarousel>
  </div>
  -->
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  export default {
    props: {
      sliders: Array,
      options: {type: Object, required: false}
    },
    components: { VueSlickCarousel },
    data() {
      return {
        slide: 0,
        sliding: null,

        slickOptions: {
          infinite: true,
          // slidesToShow: 3,
          // slidesToScroll: 1,
          autoplay: true,
          speed: 3000,
          autoplaySpeed: 6000,
          cssEase: "linear",
          arrows: false,
          dots: false
        }
      }
    },
    methods: {
      getImageSource(src) {
        try{
          if(!src.startsWith('http'))
            return require("'"+src+"'");
          else
            return src;
        } catch(e) {
          return "";
        }
      },
    }
  }
</script>

<style>
.carousel-wrapper {
  padding: 40px;
}
.img-wrapper img {
  margin: auto;
  width: 200px;
  height: 100px;
  background-image: linear-gradient(gray 100%, transparent 0);
}
</style>
