<template>
    <div>
        <h2 class="text-center">Por favor espere ...</h2>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            token: this.$route.params.token,
            id_provider: this.$route.params.id
        }
    },
    computed: {
        ...mapState('account', ['status', 'buyingProduct'])
    },
    created () {
        // reset login status
        this.logout();
       
        const { id_provider, token } = this;
        this.loginProvider({id_provider, token});
    },
    methods: {
        ...mapActions('account', ['logout','loginProvider']),
    }
};
</script>