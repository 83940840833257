import Vue from "vue"
import { ValidationProvider, ValidationObserver , extend } from 'vee-validate';
import { required/*, email */} from 'vee-validate/dist/rules';

// Validation rules
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('required', {
  ...required,
  message: 'Este campo es requerido'
});
extend('email', {
  /*
  ...email,
  message: 'El Email debe ser válido'
  */
  validate(value) {
    let regex = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";
    var regex_email = new RegExp(regex, 'i');
    return value && regex_email.test(value);
  },
  message: 'El Email debe ser válido'
});
/*
extend('phone', {
  ...phone,
  message: 'Télefono debe ser válido'
});*/

extend('adult', {
  validate(value) {
    const yearCurrent = new Date().getFullYear(); 
    if (value) {
      let valueBirthDate = value.split('-');
      return !((parseInt(yearCurrent) - (parseInt(valueBirthDate[0]))) < 18 )
    } else {
      return false;
    }
  },
  message: 'Debe tener al menos 18 años'
});
extend('max25', {
  validate(value) {
    const yearCurrent = new Date().getFullYear(); 
    let valueBirthDate = value.split('-');
    return ((parseInt(yearCurrent) - (parseInt(valueBirthDate[0]))) < 25 )
  },
  message: 'Debe tener menos de 25 años'
});
extend('positive', value => {
  if (value >= 0) {
    return true;
  }

  return 'Este campo debe ser un número positivo';
});
extend('length', {
  validate(value, args) {
    return value? value.length == args.length: false;
  },
  params: ['length'],
  message: 'El campo debe tener {length} carácteres'
});
extend('min', {
  validate(value, args) {
    return value? value.length >= args.length : false;
  },
  params: ['length'],
  message: 'El campo debe tener al menos {length} caracteres' // {_field_}
});
extend('minmax', {
  validate(value, { min, max }) {
    return value? value.length >= min && value.length <= max :false;
  },
  params: ['min', 'max'],
  message: 'The {_field_} field must have at least {min} characters and {max} characters at most'
});

extend('required_cat', {
  validate(cat) {
    return cat && cat.id? true: false;
  },
  message: 'Campo requerido'
});

extend('phone', {
  validate(value) {
    /*const phone_regex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/ 
    var re = new RegExp(phone_regex); 
    console.log("re >", re)
    return value && re.test(value);*/
    if (value && !Number.isNaN(value) && value.length == 10 && value > 0){
      //console.log("if")
      return true;
    }else{
      //console.log("else")
      return false;
    }

  },
  message: 'El teléfono debe ser válido(10 digitos)'
});