import { userService } from '../services';
// import { router } from '../helpers';
import router from '../router'
import { Messaging } from '../helpers';


const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user, buyingProduct: { status: false, id_product: '' }, resend_email: false, email: '', submitted: false, exist_email: false, removing_account: false, alert_removeacc: null, source: null, file: null}
    : { status: { }, user: null, buyingProduct: { status: false, id_product: '' }, resend_email: false, email: '', submitted: false, exist_email: false, removing_account: false, alert_removeacc: null, source: null, file: null};


const actions = {
    login({ dispatch, commit }, { username, password }) {
        dispatch('alert/success', {message: '', code: 'register'}, { root: true });
        state.resend_email = false;
        state.email = '';
        commit('loginRequest', { username });

        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    if (state.buyingProduct.status) {
                        state.buyingProduct.status = false;
                        router.push('/product/' + state.buyingProduct.id_product);                        
                    } else {
                        router.push('/profile');
                    }
                },
                error => {
                    if(error.option == 3){
                        state.resend_email = true;
                        state.email = error.email;
                        dispatch('alert/error', {message: error.message, code: 'login'}, { root: true });
                    }else{
                        dispatch('alert/error', {message: error.message, code: 'login'}, { root: true });
                    }
                    commit('loginFailure', error);                
                }
            );
    },
    loginProvider({ dispatch, commit }, {id_provider, token}) {
        if(id_provider == 'INVALID_EMAIL'){
            let data_error = JSON.stringify({message: 'La cuenta ya ha sido registrada, inicie sesión con: '+ token});
            localStorage.setItem('INVALID_EMAIL', data_error);
            router.push('/login');
        }else{
            commit('loginRequest', { id_provider });
            userService.loginProvider(id_provider)
            .then(
                user => {
                    commit('loginSuccess', user);

                    let buying = JSON.parse(localStorage.getItem('buying'));
                    if (buying && buying.status) {
                        localStorage.removeItem('buying');
                        router.push('/product/' + buying.id_product);                                              
                    } else {
                        router.push('/profile');
                    }
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
        }
    },
    
    refreshLocalUser({ commit, state }) {
        if(state.user){
            userService.getSessionUser(state.user.id)
            .then( user => {commit('loginSuccess', user)});
        }
    },

    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);
    
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', {message: 'Usuario registrado correctamente', code: 'register'}, { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },
    register_email({ dispatch, commit }, {user, person, captcha}) {
        commit('registerRequest', user);
        userService.register_email(user, person, captcha)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', {message: 'Hemos hecho tu registro. Te enviamos un correo para completar tu registro', code: 'register_email'}, { root: true });
                    })  
                },
                error => {
                    //commit('registerFailure', error);
                    dispatch('alert/error', { message: error, code: 'register_email' }, { root: true });
                }
            );
    },
    confirm_email({ dispatch, commit }, {state, token}) {
        commit('registerRequest', state);
        userService.confirm_email(token)
            .then(
                state => {
                    commit('registerSuccess', state);
                    //router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', {message: 'Email confirmado correctamente', code: ''}, { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },
    recover_account({ dispatch, commit }, {state, user, captcha}) {
        commit('recoverAccountRequest', state);
        userService.recover_account(user, captcha)
            .then(
                state => {
                    commit('recoverAccountSuccess', state);
                    //router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', {message: 'Se ha enviado un mensaje a tu correo para recuperar tu contraseña', code: ''}, { root: true });
                    })

                    setTimeout(() => {
                        router.push('/login');
                    }, 5000);
                },
                error => {
                    commit('recoverAccountFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },
    confirm_recover_account({ dispatch, commit }, {user}) {
        commit('recoverAccountRequest', state);
        
        userService.confirm_recover_account(user)
            .then(
                state => {
                    commit('recoverAccountSuccess', state);
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', {message: '¡Tu cuenta ha sido restaurada correctamente!', code: ''}, { root: true });
                    })

                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', {message: 'En breve seras redireccionado', code: ''}, { root: true });
                    }, 3500)

                    setTimeout(() => {
                        router.push('/login');
                    }, 5000);
                },
                error => {
                    commit('recoverAccountFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );
    },
    update_password_account({ dispatch, commit }, {user}) {
        commit('updatePasswordAccount', user);
        userService.update_password_account(user)
            .then(
                user => {
                    commit('updatePasswordAccountSuccess', user);
                    dispatch('alert/success', {message: 'Se ha actualizado tu contraseña correctamente!', code: 'security'}, { root: true });
                },
                error => {
                    commit('updatePasswordAccountFailure', error);
                    dispatch('alert/error', {message: error, code: 'security'}, { root: true });
                }
            );
    },
    delete_account({ dispatch, commit }, {user}) {
        commit('modal/info', '1', '2', { root: true });
        dispatch('modal/info', '1', '2', { root: true });
        commit('deleteUserSuccess', user);
        /*commit('updatePasswordAccount', user);
        userService.delete_account(user)
            .then(
                user => {
                    commit('updatePasswordAccountSuccess', user);
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('modal/info', 'Se ha actualizado tu contraseña correctamente!', { root: true });
                    })
                },
                error => {
                    commit('updatePasswordAccountFailure', error);
                    dispatch('alert/error', { message: error, code: '' }, { root: true });
                }
            );*/
    },

    remove_account({ commit }, user) {
        commit('remove_account', user);
        userService.removeAccount(user.id).then(user => {
                    commit('remove_accountSuccess', user);
                    localStorage.removeItem('user');
                    // setTimeout(()=> router.push('/login'), 2000);
                    setTimeout(()=>  location.reload(), 2000);
                },
                error => {
                    commit('remove_accountFailure', error);
                }
            );
    },

    refresh_usersession_email({ commit }, email) {
        commit('refresh_usersession_email', email);
    },

    refresh_usersession_photo({ commit }, photo) {
        commit('refresh_usersession_photo', photo);
    },

    userBuying({ commit }, buyingProduct) {
        commit('userBuying', buyingProduct);
    },

    resendEmailConfirmation({ dispatch }) {
        dispatch('alert/success', {message: 'Enviando correo electrónico ...', code: 'login'}, { root: true });
        state.resend_email = false;    
        userService.resendEmailConfirmation(state.email)
            .then(
                data => {
                    state.resend_email = false;
                    if(data){
                        dispatch('alert/success', {message: 'El correo fue enviando a: '+ state.email +' para su confirmación', code: 'login'}, { root: true });
                    }
                },
                error => {
                    state.resend_email = false;
                    dispatch('alert/error', {message: error, code: 'login'}, { root: true });
                }
            );
    },
    verifiyLoginStatus({dispatch}) {
        if(localStorage.getItem('INVALID_EMAIL')){
            let message = JSON.parse(localStorage.getItem('INVALID_EMAIL'));
            localStorage.removeItem('INVALID_EMAIL')
            dispatch('alert/error', {message: message.message, code: 'login'}, { root: true });
        }        
    },
    validateExistEmail({ commit }, user) {
        commit('validateExistEmail');
        userService.validateExistEmail(user)
        .then(
            async data => {
                commit('validateExistEmailSuccess', data);
            },
            error => {
                commit('validateExistEmailFailure', error);
            }
        );     
    },
    newUser({commit}) {
        commit('newUser');
        userService.getUserDataById('').then((person) => {
            commit('newUserSuccess', person);
        });
    },
    downloadPaymentReceipt({ commit }, data_receipt) {
        commit('downloadPaymentReceiptRequest', data_receipt);
        Messaging.makeToast('Generando referencia de pago, espere un momento por favor', "Referencia", 'success');
        //console.log('downloadPaymentReceipt');
        userService.downloadPaymentReceipt(data_receipt)
            .then(data => {
                    commit('downloadPaymentReceiptSuccess', data)
                },
                error => {
                    commit('downloadPaymentReceiptFailure', error)
                }
            );
    },
    hiddenConfirm({ commit }) {
        commit('hiddenConfirm');
    },
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
        state.user = null;
    },
    recoverAccountRequest(state) {
        state.status = { registering: true };
    },
    recoverAccountSuccess(state) {
        state.status = {};
    },
    recoverAccountFailure(state) {
        state.status = {};
        state.user = null;
    },
    updatePasswordAccount(state) {
        state.updating_password = true;
        state.submitted = true;
    },
    updatePasswordAccountSuccess(state) {
        state.updating_password = false;
        state.user.password = '';
        state.user.new_password = '';
        state.user.confirm_password = '';
        state.submitted = false;
    },
    updatePasswordAccountFailure(state) {
        state.updating_password = false;
        state.submitted = true;
    },
    refresh_usersession_property(state, key, value) {
        if(state.user && key) {
            state.user[key] = value;
            localStorage.setItem('user', JSON.stringify(state.user));
        }
    },
    refresh_usersession_email(state, email) {
        // this.refresh_usersession_property(state, 'email', email)
        state.user.email = email;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    refresh_usersession_photo(state, photo) {
        // this.refresh_usersession_property(state, 'photo', photo)
        state.user.photo = photo;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    deleteUserSuccess(state) {
        state.updating_password = false;
    },
    deleteUserFailure(state) {
        state.deleting_user = false;
    },
    userBuying(state, buyingProduct) {
        state.buyingProduct.status = buyingProduct.status;
        state.buyingProduct.id_product = buyingProduct.id_product;
        localStorage.setItem('buying', JSON.stringify(buyingProduct));
    },
    validateExistEmail(state){
        state.exist_email = false;
    },
    validateExistEmailSuccess(state, exist_email) {
        state.exist_email = exist_email;
    },
    validateExistEmailFailure(state) {
        state.exist_email = false;
    },
    newUser(){
    },
    newUserSuccess(state, user) {
        state.user = user;
    },
    remove_account(state){
        state.alert_removeacc = null;
        state.removing_account = true;
    },
    remove_accountSuccess(state, data) {
        state.removing_account = false;
        state.alert_removeacc = null;
        // state.alert_removeacc = Messaging.createMessage(data.message, 'success');
        Messaging.makeToast(data.message, "Borrar cuenta", 'success');
    },
    remove_accountFailure(state, error) {
        state.removing_account = false;
        // state.alert_removeacc = Messaging.createMessage(error || "Se ha producido un error intentelo más tarde", 'danger');
        Messaging.makeToast(error || "Se ha producido un error intentelo más tarde", "Borrar cuenta", 'danger');
    },
    downloadPaymentReceiptRequest(state) {
        state.request = { loading: true };
    },
    downloadPaymentReceiptSuccess(state, data) {
        state.source = data.receipt;
    },
    downloadPaymentReceiptFailure(state, error) {
        state.request = { error };
    },
    hiddenConfirm(state) {
        state.resend_email = false;
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};