import { fin_ProductService } from '../services';
import router from '../router'
import { Messaging } from '../helpers';

const state = {
    product: {},
    agent_code_valid: true,
    card_error: false,
    loading: false,
    total_amount: 0,
    amounts_product: {},
    references: [],
    agent_data: [],
};

const actions = {

    validateCodeAgent({ commit }, code_agent) {
        commit('validateCodeAgentRequest');
        fin_ProductService.validateCodeAgent(code_agent)
        .then(
            data => commit('validateCodeAgentSuccess', data),
            error => commit('validateCodeAgentFailure', error)
        );
    },

    payingProduct({ commit, dispatch }, { provider, product, person, code_agent, user, response_payment, period_conf, amounts_product, payment_code, affiliation_detail_id, disbursment_date, generate_user}) {
        return new Promise((resolve, reject) => {
            commit('payingProductRequest');
            
            fin_ProductService.payingProduct(provider, product, person, code_agent, user, response_payment, period_conf, amounts_product, payment_code, affiliation_detail_id, disbursment_date, generate_user, state.agent_data)
            .then(async data => { 
                    commit('payingProductSuccess', data);
                    dispatch('account/refreshLocalUser',null, { root: true });
                    if (provider == 'REFERENCES') {
                        let current_product = data.affiliation[0].details.find((x) => x.id_product == product.id);
                        const reference_data = {
                            provider: 'all',
                            id_credit_account: current_product._id,
                        }
                        dispatch('generateReferenceLendus', reference_data);
                    }
                    
                    if(!router.currentRoute.fullPath.includes('profile')){
                        setTimeout(() => {
                            let id = user._id || user.id;
                            // display success message after route change completes
                            let message = `¡Pago realizado correctamente!`;
                            if (provider == 'REFERENCES') {
                                message = `¡Producto comprado correctamente!`;
                            }
                            message += !id ? ', revise su correo para más detalles sobre la compra realizada' : '';
                            Messaging.makeToast(message,'Producto comprado' ,'success');
                            //dispatch('alert/success', {message: '¡Pago realizado correctamente!', code: ''}, { root: true });
                        })

                        if (provider != 'REFERENCES') {
                            setTimeout(() => {
                                // display success message after route change completes
                                //dispatch('alert/success', {message: 'En breve seras redireccionado', code: ''}, { root: true });
                                Messaging.makeToast(`En breve seras redireccionado`,'Producto comprado' ,'success');
                            }, 3500)
    
                            setTimeout(() => {
                                dispatch('Profil/changeTabEvent', 2, { root: true });
                                router.push('/profile');
                            }, 5000);
                        }
                    }
                    setTimeout(() => resolve())
                },
                error => { 
                    reject(error);
                    commit('payingProductFailure', error);
                    dispatch('alert/error', { message: error ? error : 'Ocurrio un error al efectuar el pago', code: '' }, { root: true });
                }
            );
        });        
    },

    payingProductProvider({ commit, dispatch }, { provider, product, person, code_agent, user, card, period_conf, amounts_product,payment_code ,affiliation_detail_id, disbursment_date}) {
        return new Promise((resolve, reject) => {
            commit('payingProductProviderRequest');
            console.log(card)
            console.log(amounts_product)
            console.log(affiliation_detail_id)
            fin_ProductService.payingProductProvider(provider, product, person, code_agent, user, card, period_conf, amounts_product, payment_code, affiliation_detail_id, disbursment_date, state.agent_data)
            .then(async data => {
                    console.log('data: ', data);

                    if (data && data.payment_method && data.payment_method.url) {
                        location.href = data.payment_method.url;
                    } else {
                        commit('payingProductProviderSuccess', data);
                        dispatch('account/refreshLocalUser',null, { root: true });
                        let id = user._id || user.id;
                        // display success message after route change completes
                        let message = `¡Pago realizado correctamente!`;
                        if (provider == 'REFERENCES') {
                            message = `¡Producto comprado correctamente!`;
                        }
                        message += !id ? ', revise su correo para más detalles sobre la compra realizada' : '';
                        Messaging.makeToast(message,'Producto comprado' ,'success');
                        //dispatch('alert/success', {message: '¡Pago realizado correctamente!', code: ''}, { root: true });
                    
                        if(!router.currentRoute.fullPath.includes('profile')){
    
                            if (provider != 'REFERENCES') {
                                setTimeout(() => {
                                    // display success message after route change completes
                                    //dispatch('alert/success', {message: 'En breve seras redireccionado', code: ''}, { root: true });
                                    Messaging.makeToast(`En breve seras redireccionado`,'Producto comprado' ,'success');
                                }, 3500)
    
                                setTimeout(() => {
                                    //router.push('/profile');
                                    dispatch('Profil/changeTabEvent', 2, { root: true });
                                    router.push('/profile');
                                    
                                }, 5000);
                            }
                        }
                    }
                    
                    setTimeout(() => resolve())
                },
                error => { 
                    reject(error);
                    commit('payingProductProviderFailure', error);
                    dispatch('alert/error', { message: error ? error: 'Ocurrio un error al efectuar el pago', code: '' }, { root: true });
                }
            );
        });
    },

    getAmountsProduct({ commit, dispatch }, { period_current }) {
        if(!period_current){
            dispatch('alert/error', { message: 'Seleccione un periodo', code: '' }, { root: true });
        }else{
            fin_ProductService.getProductConfigurarionPublish(period_current.id_product, period_current.id)
            .then(
                data => {
                    if(data){
                        commit('UpdateAmountPayment', data)
                    }else{
                        commit('UpdateAmountPayment', {})
                        dispatch('alert/error', { message: 'Ocurrio un error al obtener el monto a pagar', code: '' }, { root: true });
                    }
                },
                error => { 
                    commit('payingProductFailure', error);
                    dispatch('alert/error', { message: 'Ocurrio un error al obtener el monto a pagar', code: '' }, { root: true });
                }
            );
        }
    },

    generateReferenceProvider({ commit, dispatch }, { provider, person, product, code_agent, period_conf, amounts_product }) {
        commit('generateReferenceProviderRequest');

        //console.log('payingProductProvider productShow', provider, product, person, code_agent, card);
        fin_ProductService.generateReferenceProvider(provider, person, product, code_agent, period_conf, amounts_product)
        .then(data => {
                commit('generateReferenceProviderSuccess', data);
                //dispatch('Profil/SyncPerson', person.id, { root: true });
            },
            error => { 
                commit('generateReferenceProviderFailure', error);
                dispatch('alert/error', { message: 'Ocurrio un error al generar la referencia de pago', code: '' }, { root: true });
            }
        );
    },

    generateReferenceLendus({ commit, dispatch }, { provider, id_credit_account, balance }) {
        commit('generateReferenceLendusRequest');
        fin_ProductService.generateReferenceLendus(provider, id_credit_account)
        .then(data => {
                let references = '<b>';
                if (data[0].status == "SUCCESS") {
                    data[1].forEach((ref, index) => {
                        references += ref.label + ': ';
                        references += ref.reference_number.substr(0, 4) + '-' + ref.reference_number.substr(4, 4) + '-' + ref.reference_number.substr(8, 4) + '-' + ref.reference_number.substr(12, 4);
                        if (index < data[1].length-1) {
                            references += ', ';
                        }
                    });
                }
                references += '</b>';
                
                if (balance) {
                    
                    //console.log('balance.affiliation_detail: ', balance.affiliation_detail);
                    if (balance.affiliation_detail && balance.affiliation_detail.status == 'PREAUTHORIZED') {
                        let message = 'Actualmente tiene un producto en proceso de pago, puede realizar el pago con las siguientes referencias: ' + references;
                        dispatch('alert/warning', {message: message, code: 'reference'}, { root: true });
                    }
                    
                }
                commit('generateReferenceLendusSuccess', data);
                //dispatch('Profil/SyncPerson', person.id, { root: true });
            },
            error => { 
                commit('generateReferenceLendusFailure', error);
                dispatch('alert/error', { message: 'Ocurrio un error al generar la referencia de pago', code: '' }, { root: true });
            }
        );
    },

    saveAffiliation({ commit, dispatch }, { product, person, code_agent, period_conf, amounts_product }) {
        commit('saveAffiliationRequest');

        fin_ProductService.saveAffiliation(product, person, code_agent, period_conf, amounts_product)
        .then(async data => {
                
                let current_product = data.affiliation.find((x) => x.id_product == product.id);
                const reference_data = {
                    provider: 'all',
                    id_credit_account: current_product._id,
                }
                dispatch('Profil/SyncPersonProductReference', { id_person: person.id, reference_data: reference_data}, { root: true });
                commit('saveAffiliationSuccess', data);
            },
            error => { 
                commit('saveAffiliationFailure', error);
                dispatch('alert/error', { message: 'Ocurrio un error al generar la referencia de pago', code: '' }, { root: true });
            }
        );
    },

};

const mutations = {
    payingProductRequest(state) {
        state.loading = true;
    },
    payingProductSuccess(state) {
        state.loading = false;
    },
    payingProductFailure(state, error) {
        state.loading = false;
        state.product = { error };
        state.card_error = true;
    },
    payingProductProviderRequest(state) {
        state.loading = true;
    },
    payingProductProviderSuccess(state) {
        state.loading = false;
    },
    payingProductProviderFailure(state, error) {
        state.loading = false;
        state.card_error = true;
        state.product = { error };
    },
    validateCodeAgentRequest(state) {
        state.loading = true;
    },
    validateCodeAgentSuccess(state, data) {
        state.loading = true;
        state.agent_code_valid = data[0];
        state.agent_data = data[1];
    },
    validateCodeAgentFailure(state, error) {
        state.loading = false;
        state.product = { error };
        state.agent_code_valid = false;
    },
    UpdateAmountPayment(state, data){
        state.amounts_product = {};
        state.total_amount = data && data.length > 0 ? data[0].fee : 0;        
        state.amounts_product = data && data.length > 0 ? data[0] : {};
        //pruebas
        //state.total_amount = 2;
        //state.amounts_product.fee = 2;
        //
    },
    generateReferenceProviderRequest(state) {
        state.loading = true;
    },
    generateReferenceProviderSuccess(state) {
        state.loading = false;
    },
    generateReferenceProviderFailure(state, error) {
        state.loading = false;
        state.product = { error };
    },
    generateReferenceLendusRequest(state) {
        state.loading = true;
    },
    generateReferenceLendusSuccess(state, data) {
        state.references = data ? data[1] : null;
        state.loading = false;
    },
    generateReferenceLendusFailure(state, error) {
        state.loading = false;
        state.product = { error };
    },
    saveAffiliationRequest(state) {
        state.loading = true;
    },
    saveAffiliationSuccess(state) {
        state.loading = false;
    },
    saveAffiliationFailure(state, error) {
        state.loading = false;
        state.product = { error };
    },

};

export const ProductShow = {
    namespaced: true,
    state,
    actions,
    mutations
};