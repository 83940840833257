import { HttpClient } from './HttpClient' 

export const MessageService = {
    register: (message) => HttpClient.post('/message', message),
    getAll: () => HttpClient.get(`/message`),
    getById: (id) => HttpClient.get(`/message/${id}`),
    update: (message) => HttpClient.put('/message', message),
    delete: (id) => HttpClient.delete(`/message/${id}`),
    getmessagePerson: (id) => HttpClient.get(`/message/person/${id}`), 
    getPaginateMessagePerson: (page, rows, search_value, id_person) => HttpClient.post(`/message/page/`,{page, rows, search_value, id_person}), 
    readAll: (id) => HttpClient.get(`/message/person_readall/${id}`), 
    highlightMessage: (id, highlight, action) => HttpClient.get(`/message/highlight/${id}/${highlight}/${action}`), 
    // Syncmessage: (id) => HttpClient.get(`/sync/message/${id}`),
};
