<template>
    <b-navbar toggleable="lg" class="navbar navbar-marketing navbar-expand-lg navbar-light fixed-top navbar-margin navbar-background" >
       <div class="container">
            <a class="navbar-brand text-white" href="/">
                <img src="@/assets/img/logo-beluva-angel.png" heigth="346"  alt="" class="d-inline-block align-top" loading="lazy">
            </a>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                        
            <b-collapse class="collapse navbar-collapse"  id="nav-collapse" is-nav>
                <ul class="navbar-nav ml-auto mr-lg-5">
                    <li class="nav-item">
                        <router-link to="/home" class="nav-link" id="navbarDropdownDocs">
                            <i class="fa fa-home"></i> Inicio
                        </router-link>
                    </li>
                    <li class="nav-item dropdown dropdown-lg no-caret">
                       <router-link to="/about" class="nav-link" id="navbarDropdownDocs">
                            <i class="fa fa-users"></i> Nosotros
                       </router-link>
                    </li>
                    <li class="nav-item dropdown dropdown-lg no-caret">
                        <router-link to="/positions" class="nav-link" id="navbarDropdownDocs">
                            <i class="fa fa-address-card" aria-hidden="true"></i> Vacantes
                        </router-link>
                    </li>
                    <li class="nav-item no-caret">
                        <router-link to="/contact" class="nav-link" id="navbarDropdownDocs">
                            <i class="fa fa-comments"></i> Contacto
                        </router-link>
                    </li>
                    <!--
                    <b-nav-item-dropdown class="nav-item no-caret" v-if="status.loggedIn" right>
                        <template #button-content>
                            <div style="font-size: 1.2rem; position: relative;">
                                <b-icon  :animation="messages_unread && messages_unread.length? 'cylon':''" icon="bell-fill" :class="['rounded-circle', {'bg-danger':messages_unread && messages_unread.length}, 'p-1']" variant="light"> </b-icon>
                                <span v-if="messages_unread && messages_unread.length" class="badge badge-light" style="position: absolute;right:0px; top: 0px;font-size: .7rem"> {{messages_unread.length}}</span>
                            </div> 
                        </template>
                        <div
                            class=" dropdown-menu-right border-0 shadow animated--fade-in-up"
                            aria-labelledby="navbarDropdownAlerts">
                            <h6 class="dropdown-header dropdown-notifications-header">
                                <i class="fa fa-bell fa-2x"></i> Alerts Center
                            </h6>
                            <a v-for="item of messages_unread" v-bind:key="item.id" class="dropdown-item dropdown-notifications-item" href="#!">
                                <div class="dropdown-notifications-item-icon ">
                                     <b-avatar :src="getImageSrcSession()"  size="2rem" />
                                </div>
                                <div class="dropdown-notifications-item-content ml-1">
                                    <div class="dropdown-notifications-item-content-details">
                                        <small>{{getCreationDate(item)}}</small></div>
                                    <div style="max-width: 200px; text-overflow: ellipsis;" class="dropdown-notifications-item-content-text overflow-hidden">{{item.message_body}}</div>
                                </div>
                            </a>
                            <a class="dropdown-item dropdown-notifications-footer"
                                href="/notification">Ver todas las notificaciones</a>
                        </div>
                    </b-nav-item-dropdown>
                    -->
                    
                </ul>
                <ul class="navbar-nav align-items-center ml-auto">
                    <b-nav-item-dropdown class="nav-item dropdown no-caret mr-2 dropdown-notifications" v-if="status.loggedIn && user.client_code" right>
                        <template class="btn btn-transparent-dark dropdown-toggle" #button-content>
                        <div style="font-size: 1.2rem; position: relative;">
                            <b-icon  :animation="messages_unread && messages_unread.length? 'cylon':''" icon="bell-fill" :class="['rounded-circle', 'p-1']" variant="default"> </b-icon>
                            <span v-if="messages_unread && messages_unread.length" class="badge badge-danger badge-danger" style="position: absolute;right:0px; top: 0px;font-size: .7rem"> {{messages_unread.length}}</span>
                        </div> 
                        </template>
                        <div
                            class="dropdown-menu-right border-0 shadow animated--fade-in-up"
                            aria-labelledby="navbarDropdownAlerts">
                            <h6 class="dropdown-header dropdown-notifications-header">
                                <i class="fa fa-bell fa-2x"></i> Notificaciones
                            </h6>
                            <a v-for="item of messages_unread" v-bind:key="item.id"  class="dropdown-item dropdown-notifications-item" href="/notification">
                                <div class="dropdown-notifications-item-icon bg-warning">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-activity">
                                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                                </div>
                                <div class="dropdown-notifications-item-content">
                                    <div class="dropdown-notifications-item-content-details">{{getCreationDate(item)}}</div>
                                    <div class="dropdown-notifications-item-content-text">{{item.message_body}}</div>
                                </div>
                            </a>
                            <a class="dropdown-item dropdown-notifications-footer"
                                href="/notification"> las notificaciones</a>
                        </div>
                    </b-nav-item-dropdown>
                    <li v-if="!status.loggedIn" class="nav-item dropdown no-caret mr-2 dropdown-user">
                         <a  class="btn btn-icon btn-transparent-dark"
                        href="/login" role="button"
                        aria-haspopup="true" aria-expanded="false"><i class="fa fa-user"></i> 
                        </a>
                    </li> 
                    <b-nav-item-dropdown v-else class="nav-item dropdown no-caret mr-2 " right>
                        <template  #button-content class="btn btn-icon btn-transparent-dark">
                            <i class="fa fa-user"></i> 
                        </template>
                        <div v-if="status.loggedIn"
                            class=" dropdown-menu-right border-0 shadow animated--fade-in-up"
                            aria-labelledby="navbarDropdownUserImage">
                            <h6 class="dropdown-header d-flex align-items-center">
                               
                                <b-avatar class="dropdown-user-img " :src="user.photo || '@/assets/img/user.png'" alt="perfil" size="2.2rem" />
                                <div class="dropdown-user-details " style="padding-left:4px">
                                    <div class="dropdown-user-details-name" style="color:black">{{user.username}}</div>
                                    <div class="dropdown-user-details-email">{{user.email}}</div>
                                </div>
                            </h6>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="/profile">
                                <div class="dropdown-item-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-settings">
                                        <circle cx="12" cy="12" r="3"></circle>
                                        <path
                                            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                </div> Cuenta
                            </a>
                            <a class="dropdown-item" @click="logoutMenu">
                                <div class="dropdown-item-icon" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-log-out">
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                </div> Salir
                            </a>
                        </div>
                    </b-nav-item-dropdown>
                </ul>
            

                <!--
                <router-link v-if="!status.loggedIn" to="/login" class="btn-teal btn rounded-pill px-4 ml-lg-4">Entrar</router-link>
                <b-nav-item-dropdown v-else style="padding:0px" class="btn-teal btn rounded-pill px-4 ml-lg-4" right>
                
                     <template #button-content >
                        <div>
                            <span style="color:white" >{{user.email}}</span> 
                            <b-avatar :src="user.photo || '@/assets/img/user.png'" alt="perfil" size="1.5rem" />
                        </div>
                    </template>
                    <b-dropdown-item href="/profile">Perfil</b-dropdown-item>
                    <b-dropdown-item @click="logoutMenu">Salir</b-dropdown-item>
                  
                </b-nav-item-dropdown>
                -->
               

            </b-collapse>
        </div>
     </b-navbar>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { UtilDate } from '@/helpers';

export default {
    props:{
        menu_active: String
    },
    computed: {
        ...mapState('account', ['status', 'user']),
        ...mapState({
			messages: state => state.Message.messages,
			messages_unread: state => state.Message.messages_unread, 
        }),
    },
    created () {
        this.getConversation(this.user.id_person);
    },
    methods: {
        ...mapActions('account', ['logout']),
        ...mapActions('Message', ['getConversation']),
        logoutMenu() {
            this.logout();
            location.reload(true);
        },
        showMessages() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        },
        getImageSrcSession() {
            return require('@/assets/img/logo-346x80.png');
        },
        getCreationDate: function (item) {
            return UtilDate.frontEndDateTimeFormat(item.created_at);
        },
    }
};
</script>
<style scoped>
.color {
 color: white;
}

</style>
