import { authHeader } from '../helpers';

const post = (url = '', data = '', config = null) => {
    const requestOptions = {
        method: 'POST',
        // headers: authHeader(),
        headers:{ ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api${url}`, config || requestOptions).then(handleResponse);
}

const postJson = (url = '', data = '', config = null) => {
    console.log("enviando >>", data)
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api${url}`, config || requestOptions).then(handleResponse);
}

const get = (url) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api${url}`, requestOptions).then(handleResponse);
}

const put = (url = '', data = '', config = null) => {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api${url}`, config || requestOptions).then(handleResponse);
}

const putFormData = (url = '', formData = '', config = null) => {
    
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() }, /* 'Content-Type': 'multipart/form-data'*/
        body: formData
    };
    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api${url}`, config || requestOptions).then(handleResponse);
}

const del = (url = '', config = null) => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api${url}`, config || requestOptions).then(handleResponse);
}

const HttpClient = {
    post,
    get,
    put,
    putFormData,
    delete: del,
    postJson
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export {HttpClient}