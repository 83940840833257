<template>
  <div class="section" id="registro">
      <div class="registrosoc">
          <BtnLoginProvider class="registrosocial fb" :disabled="status.loggingIn" icon="" provider_name="facebook" action="REGISTER" />
          <BtnLoginProvider class="registrosocial google" :disabled="status.loggingIn" icon="" provider_name="google" action="REGISTER"/>
      </div>
      <form autocomplete="on" @submit.prevent="handleSubmit" class="registro" id="registro">
          <h2>Registro</h2>
          <p class="formfield">
            <AlertMessage v-if="alert.code == 'register_email'"/>
          </p>
          <p class="formfield col-2">
              <label for="registro-nombre">Nombre</label>
              <input v-model="person.name" :class="{ 'is-invalid': submitted && !person.name.length }" class="input-box" type="text" id="registro-nombre" name="registro-nombre" required/>
              <span v-show="submitted && !person.name.length" class="invalid-feedback">Nombre es requerido</span>
          </p>
          <p class="formfield col-2">
              <label for="registro-apellidop">Apellido Paterno</label>
              <input v-model="person.lastname" :class="{ 'is-invalid': submitted && !person.lastname.length }" class="input-box" type="text" id="registro-apellidop" name="registro-apellidop" required>
              <span v-show="submitted && !person.lastname.length" class="invalid-feedback">Apellido Paterno es requerido</span>
          </p>
          <p class="formfield col-2">
              <label for="registro-apellidm">Apellido Materno</label>
              <input v-model="person.second_lastname" class="input-box" type="text" id="registro-apellidm" name="registro-apellidm">
          </p>
          <p class="formfield col-2">
              <label for="registro-fechanac">Fecha de Nacimiento</label>
              <input v-model="person.birthdate" :class="{ 'is-invalid': submitted && birth_date_error.length }" class="input-box" type="date" id="registro-fechanac" name="registro-fechanac" max='9999-12-30' required>
              <span v-show="submitted && birth_date_error.length" class="invalid-feedback">{{birth_date_error}}</span>
          </p>
          <p class="formfield ">
              <label for="registro-email">Email</label>
              <input :class="{ 'is-invalid': (submitted && !user.email.length) || (submitted && !isEmailValid()) }" v-model="user.email" class="input-box" type="email" id="registro-email" name="registro-email" required>
              <span v-show="(submitted && !user.email.length)" class="invalid-feedback">Email es requerido</span>
              <span v-show="(submitted && !isEmailValid())" class="invalid-feedback">El email debe ser válido</span>
          </p>
          <p class="formfield col-2">
              <label for="registro-contrasena">Contraseña</label>
              <input v-model="user.password" :class="{ 'is-invalid': submitted && !user.password.length }" class="input-box" type="password" id="registro-contrasena" name="registro-contrasena" required>
              <span v-show="submitted && !user.password.length" class="invalid-feedback">Contraseña es requerido</span>
          </p>
          <p class="formfield col-2">
              <label for="registro-confcontrasena">Confirma tu contraseña</label>
              <input v-model="user.confirm_password" :class="{ 'is-invalid': submitted && !user.confirm_password.length }" class="input-box" type="password" id="registro-confcontrasena" name="registro-confcontrasena" required>
              <span v-show="submitted && !user.password.length" class="invalid-feedback">Contraseña es requerido</span>
          </p>
          <p class="formfield">
            <vue-recaptcha :sitekey="captchaKey" @verify="onVerify" @expired="onExpired" :loadRecaptchaScript="true"></vue-recaptcha>
          </p>
          <input class="enviar" :disabled="!captcha" type="submit" value="Enviar">
      </form> 
  </div>
  
</template>

<script>
import { mapState, mapActions } from "vuex";
import BtnLoginProvider from '@/components/BtnLoginProvider.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import VueRecaptcha from 'vue-recaptcha';

export default {
  components:{
        BtnLoginProvider,
        AlertMessage,
        VueRecaptcha
  },
  data() {
    return {
      person:{
        name: "",
        lastname: "",
        second_lastname: "",
        birthdate: "",
      },
      user: {
        email: "",
        password: "",        
        confirm_password: "",
      },
      birth_date_error: "",
      submitted: false,
      captcha: false,
      captchaKey: `${process.env.VUE_APP_RECAPTCHA}`
    }
  },
  computed: {
    ...mapState("account", ["status"]),
    ...mapState({
			alert: (state) => state.alert,
		}),
  },
  mounted(){
    this.clear();
  },
  methods: {
    ...mapActions("account", ["register_email"]),
    ...mapActions("alert", ["clear"]),
    handleSubmit() {
      this.clear();
      this.submitted = true;
      const { user, person, captcha } = this;
      
      this.validateBirhtDate();

      if(this.person.name)this.person.name = this.person.name.trim();
      if(this.person.lastname) this.person.lastname = this.person.lastname.trim();
      if(this.person.second_lastname) this.person.second_lastname = this.person.second_lastname.trim();
      
      if (this.person.name && this.person.lastname && this.user.email && this.user.password &&
            this.user.confirm_password && !this.birth_date_error.length && this.user.confirm_password && this.isEmailValid()) {
              //console.log('ResterPage,vue', user, person, captcha);
              
              this.person.notification_email = true;
        this.register_email({user, person, captcha})
      }
    },
    validateBirhtDate(){
      this.birth_date_error = '';
      if(this.person.birthdate){
        const yearCurrent = new Date().getFullYear(); 
        let valueBirthDate = this.person.birthdate.split('-');
        if((parseInt(yearCurrent) - (parseInt(valueBirthDate[0]))) < 18 ){
          this.birth_date_error = 'Debe tener al menos 18 años';
        } 
      }else{
        this.birth_date_error = 'Seleccione fecha de nacimiento';
      }
    },
    isEmailValid() {
      this.user.email = this.user.email.trim();
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.user.email);
    },
    onVerify(){
      this.captcha = true;
    },
    onExpired(){
      this.captcha = false;
    }
  },
};
</script>