<template>
    <div class="container">
            <div class="wrapper">
                <nav>
                    <ul class="app-nav" id="app-nav">
						<li v-for="tab of tabs" v-bind:key="tab.index" :class="['nav_listitem', {'active':_profile.profile.tabindex===tab.index}]" @click="_profile.profile.tabindex=tab.index" >
							<a href="#">
								<i :class="tab.icon"></i>
								<p>{{tab.label}}</p>
							</a>
						</li>
                    </ul>
                </nav>
				
				<template v-for="tab of tabs" >
					<div  v-bind:key="tab.index"  :id="tab.id" v-if=" _profile.profile.tabindex===tab.index" class="tab"> 
						<component v-bind:is="tab.component" ></component>
					</div>
				</template>
            </div>
        </div>
</template>

<script>
import Profile from './Profile';
import Beneficiary from './Beneficiary';
import Balance from './Balance.vue';
import Security from './Security.vue';
import Notification from './Notification.vue';
// import AlertMessage from '@/components/AlertMessage.vue'

import { mapActions, mapState } from 'vuex'
export default {
	data() {
		return {
			// tabIndex: 0,
			tabs: [
				{label: 'Perfil', icon:'fa fa-user fa-2x', index :0 , component: Profile, id: 'perfil'},
				{label: 'Beneficiarios', icon:'fa fa-user-friends fa-2x', index :1, component: Beneficiary, id: 'beneficiarios'},
				{label: 'Saldo', icon:'fas fa-coins fa-2x', index :2, component: Balance, id: 'saldos'},
				{label: 'Preferencias', icon:'fas fa-lock fa-2x', index :3, component: Security, id: 'preferencias'},
				{label: 'Notificaciones', icon:'far fa-comment fa-2x', index :4, component: Notification, id: 'notificaciones'}
			]
		};
	},
    components:{
		Profile,
		Beneficiary,
        Balance,
		Security,
		Notification,
		// AlertMessage
	},
	methods: {
		...mapActions({
			clearAlert: 'alert/clear'
		}),
		...mapActions("alert", ["warning"]),
		...mapActions("ProductShow", ["generateReferenceLendus"]),
		...mapActions('Balance', ['getBalance']),
		generateReferences(){
			if (this.person.affiliation[0]) {
				this.person.affiliation[0].details.forEach(product => {
					if (product.is_active && product.status == 'PREAUTHORIZED') {
						const data = {
							provider: 'all',
							id_credit_account: product._id,
							balance: this.balance
						}

						this.generateReferenceLendus(data);
					}
				});
			}
		},
	},
	watch: {
		"_profile.profile.tabindex": function(){
			this.clearAlert();
			//this.generateReferences();
		},
		person() {
			this.getBalance(this.person.id);
			//this.generateReferences();
		}
	},
    computed: {
        ...mapState({
			_profile: state => state.Profil,
			person: (state) => state.Profil.person.item,
			alert: (state) => state.alert,
			balance: state => state.Balance,
		})
    },
};
</script>