import { authHeader } from '../helpers';
import { HttpClient } from './HttpClient' 

export const userService = {
    login,
    loginProvider,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    register_email,
    confirm_email,
    recover_account,
    confirm_recover_account,
    sendEmail,
    update_password_account,
    resendEmailConfirmation,
    validateExistEmail,
    getUserDataById,
    removeAccount: (id_user) => HttpClient.get(`/user/account/delete/${id_user}`),
    downloadPaymentReceipt,
    getSessionUser: (id_user) => HttpClient.get(`/user/session/${id_user}`),
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/logIn`, requestOptions)
        .then(handleResponseDataJson)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function loginProvider(provider_id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/logIn/provider/${provider_id}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/register`, requestOptions).then(handleResponse);
}

function register_email(user, person, captcha) {
    //console.log('User.service.js', user, person, captcha);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({user: user, person: person, captcha: captcha})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/registerEmail`, requestOptions).then(handleResponse);
}

function confirm_email(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token: token})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/confirmEmail`, requestOptions).then(handleResponse);
}

function recover_account(user, captcha) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({user: user, captcha: captcha})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/recover/account`, requestOptions).then(handleResponse);
}

function confirm_recover_account(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({user: user})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/confirm/recover/account`, requestOptions).then(handleResponse);
}

function update_password_account(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({user: user})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/update/password/account`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/${id}`, requestOptions).then(handleResponse);
}

function sendEmail(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/send_emailcontact`, requestOptions).then(handleResponse);
}

function resendEmailConfirmation(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email: email})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/user/resendEmailConfirmation`, requestOptions).then(handleResponse);
}

function validateExistEmail(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({user: user})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/validate/exist/email`, requestOptions).then(handleResponse);
}

function getUserDataById(id_user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({id: id_user})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/get/user/id`, requestOptions).then(handleResponse);
} 

function downloadPaymentReceipt(data_receipt) {
    //console.log('provider 4: ', provider);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({data_receipt: data_receipt})
    };

    return fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/download/receipt/payment`, requestOptions).then(handleResponse);
} 

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseDataJson(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            return Promise.reject(data);
        }

        return data;
    });
}