<template>
    <b-toast v-model="toast.show" :variant="toast.variant" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">{{toast.title}}</strong>
        </div>
      </template>
      <span v-html="toast.message"></span>
    </b-toast>
</template>

<script>
import { mapState} from 'vuex'

export default {
  computed: {
      ...mapState({
          toast: state => state.toast
      })
  },
}
</script>
