import { fin_ProductService } from '../services';

const state = {
    products: {}
};

const actions = {
    
    getProducts({ commit }) {
        commit('getProductsRequest');
        fin_ProductService.getProductPublish()
        .then(
            data => commit('getProductsSuccess', data),
            error => commit('getProductsFailure', error)
        );
        
    },

};

const mutations = {
    getProductsRequest(state) {
        state.products = { loading: true };
    },
    getProductsSuccess(state, data) {
        state.products = { items: data };
    },
    getProductsFailure(state, error) {
        state.products = { error };
    },

};

export const Home = {
    namespaced: true,
    state,
    actions,
    mutations
};