import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './Alert.module';
import { modal } from './Modal.module';
import { account } from './Account.module';
import { users } from './Users.module';
import { ContactMessage } from './ContactMessage.module';
import { Profil } from './Profil.module';
import { Home } from './Home.module';
import { Beneficiary } from './Beneficiary.module';
import { ProductShow } from './ProductShow.module';
import { Message } from './Message.module';
import { Vacant } from './Vacant.module';
import { Balance } from './Balance.module';
import { toast } from './Toast.module';

Vue.use(Vuex);


export default new Vuex.Store({
    modules: {
        toast,
        alert,
        modal,
        account,
        users,
        ContactMessage,
        Profil,
        Home,
        Beneficiary,
        ProductShow,
        Message,
        Vacant,
        Balance
    }
});