export const UtilDate = {
    frontEndDateFormat,
    frontEndDateTimeFormat,
    calculateAge
};

function frontEndDateFormat(date) {
   
    return date ? date.substring(0,10) : null; 
}

function frontEndDateTimeFormat(date) {
    if(date) {
        var d = new Date(date);
        var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " +
        d.getHours() + ":" + d.getMinutes();
        return datestring
    }
    return  ''
}

function calculateAge(year, month, day) {
    var now = new Date();
    var born = new Date(year, month-1, day);

    var birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
    if (now >= birthday) 
        return now.getFullYear() - born.getFullYear();
    else
        return now.getFullYear() - born.getFullYear() - 1;
}


