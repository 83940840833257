<template>
    <div><AlertMessage v-if="alert.code == 'profile'"/>
		<Toast />
		<h2 class="titulo__tab">Datos Generales</h2>
		<div class="ids">
			<div class="card-fotoperfil">
				<img :src="person && person.avatar? person.avatar : identifications.profile"  alt="">
				<h4>JPG o PNG. Tamaño máximo 5mb</h4>
				<button class="primary btn-file"  :disabled="updating_img_profile">
					<b-spinner v-show="updating_img_profile" small type="grow"></b-spinner>
					<span> {{person && person.avatar?'CAMBIAR': 'SUBIR FOTO'}}</span>
					<b-form-file :disabled="updating_img_profile" class="btn btn-primary btn-file"  v-model="image_profil" accept="image/*"  plain></b-form-file>
				</button>
			</div>
			<template  v-if="person && person.files">
				<div class="card"  v-for="doc of person.files" v-bind:key="doc.id" >
					<img :src="doc.complete_path || (doc.code == 'SYS_RSC_DOC_FRONT_P' ? identifications.front : doc.code == 'SYS_RSC_DOC_BACK_P' ? identifications.back : 'https://source.unsplash.com/QAB-WJcbgJk/300x300')"  id="inefrontal" alt="" style="background-size: cover;width:160px !important; height:120px!important;">
					<h4>{{doc.label}}</h4>

					<button class="primary btn-file btn-ine-profile"  :disabled="doc.updating" style="margin-top:10px;">
						<b-spinner v-show="doc.updating" small type="grow"></b-spinner>
						<span> {{doc.file ? 'SUBIR': 'CAMBIAR'}}</span>
						<b-form-file :disabled="doc.updating || disableMainInputs" class="btn btn-primary btn-file" v-on:input="changeDoc(doc, image_doc)" v-model="image_doc" accept="image/*"  plain></b-form-file>
					</button>
					<!-- <label for="inefrontal">SUBIR</label> -->
				</div>
			</template>
		</div>
		<ValidationObserver   v-slot="{ handleSubmit }" ref="observer" v-if="!_person.loading">
		<form  autocomplete="on" @submit.prevent="handleSubmit(onSubmit)" class="formulario">
			<section class="datosprimarios">
				<p class="formfield">
					<label for="nombre">Nombre</label>
					<ValidationProvider rules="required|min:3" :bails="false" v-slot="{ errors }">
						<input v-model="person.name" v-uppercase :disabled="disableMainInputs"  :class="[{ 'is-invalid':  errors[0] },'input-box']" type="text" id="nombre" name="nombre" required>
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield">
					<label for="apellidop">Apellido Paterno</label>
					<ValidationProvider rules="required|min:3" :bails="false" v-slot="{ errors }">
						<input v-model="person.lastname" v-uppercase :disabled="disableMainInputs" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="text" id="apellidop" name="apellidop" required>
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield">
					<label for="apellidm">Apellido Materno</label>
					<input v-model="person.second_lastname" :disabled="disableMainInputs"  v-uppercase class="input-box" type="text" id="apellidm" name="apellidom">
				</p>
				<p class="formfield">
					<label for="fechanac">Fecha de Nacimiento</label>
					<ValidationProvider rules="required|adult" :bails="false" v-slot="{ errors }">
						<input v-model="person.birthdate" :disabled="disableMainInputs" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="date" id="fechanac" name="fechanac" max='9999-12-30' required>
						<span style="display: block;" class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield">
					<label for="telefono">Teléfono</label>
					<ValidationProvider rules="required|phone" :bails="false" v-slot="{ errors }">
						<input v-model="person.phone1" :disabled="disableMainInputs" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="number" id="telefono" name="telefono" pattern="[0-9]{10}" required>
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield">
					<label for="email">Email</label>
					<ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
						<input v-model="person.email" :disabled="user.email? true:false" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="email" id="email" name="email" required>
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield">
					<label for="calle">Calle</label>
					<ValidationProvider rules="required" :bails="false" v-slot="{ errors }">
						<input v-model="person.address.main_street" v-uppercase  :class="[{ 'is-invalid':  errors[0] },'input-box']" type="text" id="calle" name="calle" required> 
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield col-2">
					<label for="noext">No. Exterior</label>
					<ValidationProvider rules="required" :bails="false" v-slot="{ errors }">
						<input v-model="person.address.exterior_number" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="text" id="noext" name="noext" required>
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield col-2">
					<label for="noint">No. Interior</label>
					<input v-model="person.address.interior_number" class="input-box" type="text" id="noint" name="noint"> 
				</p>
				<p class="formfield">
					<label for="codigopostal">Código Postal</label>
					<ValidationProvider rules="required|length:5" :bails="false" v-slot="{ errors }">
						<input v-model="person.address.post_code" v-on:keyup="filAddresByPostalCode()" :class="[{ 'is-invalid':  errors[0] },'input-box']" type="number" id="codigopostal" name="codigopostal" required>
						<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield" style="display:none">
					<label for="pais">País</label>
					<multiselect 
					:disabled="valid_post_code"
					v-model="person.address.cat_country"
					:options="cat_countries" 
					:loading="loading_catalogs"
					:searchable="true" :close-on-select="true"
					label="label" track-by="id" 
					placeholder="Seleccione una opción"></multiselect>
				</p>
				<p class="formfield">
					<label for="estado">Estado</label>
					<multiselect 
					disabled
					v-model="person.address.cat_state"
					:options="cat_states" 
					:loading="loading_catalogs"
					:searchable="true"
					label="label" track-by="id" 
					:class="{ 'is-invalid': !person.address.cat_municipality && valid_post_code}" 
					placeholder="Seleccione una opción"></multiselect>
				</p>
				<p class="formfield">
					<label for="municipio">Municipio</label>
					<ValidationProvider rules="required_cat" :bails="false" v-slot="{ errors }">
					<multiselect 
					disabled
					v-model="person.address.cat_municipality"
					:options="cat_municipies" 
					:loading="loading_catalogs"
					:searchable="true"
					label="label" track-by="id" 
					:class="{ 'is-invalid': !person.address.cat_municipality && valid_post_code}" 
					placeholder="Seleccione una opción"></multiselect>
					<span class="invalid-feedback" >{{ errors[0] }}</span>
					</ValidationProvider>
				</p>
				<p class="formfield">
					<label for="localidad">Localidad</label>
					<multiselect 
					v-model="person.address.cat_locality"
					:options="cat_localities" 
					:loading="loading_catalogs"
					:searchable="true"
					label="label" track-by="id" 
					:class="{ 'is-invalid': !person.address.cat_locality && valid_post_code}" 
					placeholder="Seleccione una opción"></multiselect>
					<span  v-if="!person.address.cat_locality && valid_post_code" class=" invalid-feedback" >Seleccione una opción</span>
				</p>
				<button class="button" :disabled="_person.updating"><!--   || invalid -->
					<b-spinner v-show="_person.updating" small type="grow"></b-spinner>
					Guardar
				</button>
			</section>
		</form>
		</ValidationObserver>
	</div>   
</template>

<script>
import AlertMessage from '@/components/AlertMessage.vue'
import { Messaging } from '@/helpers';
import { mapState, mapActions } from 'vuex'
import Toast from '@/components/Toast.vue';

export default {
	components:{
        AlertMessage,
		Toast
	},
	data(){
		const now = new Date()
		const maxDate = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate())
		return {
			max_birthdate: maxDate,
			image_profil: null,
			image_doc: null,
			file_selected: null,
			identifications: {
				front: '',
				back: '',
				profile: '',
			}
		}
	},
    computed: {
        ...mapState({
			user: state => state.account.user,
            updating_img_profile: state => state.Profil.person.updating_img_profile,
            person: state => state.Profil.person.item,
            _person: state => state.Profil.person,
			cat_countries: state => state.Profil.cat_addres.cat_countries,
			cat_states: state => state.Profil.cat_addres.cat_states,
            cat_localities: state => state.Profil.cat_addres.cat_localities,
            cat_municipies: state => state.Profil.cat_addres.cat_municipies,
			loading_catalogs: state => state.Profil.cat_addres.requesting,
			alert: (state) => state.alert
		}),
		valid_post_code: function() {
			return this.person.address.post_code &&  this.person.address.post_code.length == 5? true : false;
		},
		disableMainInputs(){
			return this.person && this.person.affiliation && this.person.affiliation[0] ? true: false;
		}
    },
    created () {
		this.getPerson(this.user);
		this.getCatCountry();
		this.identificationsDefault();
    },
    methods: {
		...mapActions("toast", ["makeToast"]),
        ...mapActions('Profil', [
			'getPerson',
			'updatePerson',
			'getCatCountry' ,
			'getCatalogsAddresByPostCode',
			'updateImageProfile',
			'updateDocumentPerson',
			'deleteDocumentPerson',
		]),
		filAddresByPostalCode () {
			if(this.valid_post_code) {
				this.getCatalogsAddresByPostCode(this.person.address.post_code);
			}
		},
		onSubmit () {
			let message = '';
			
			if(this.person && this.person.address && !this.person.address.main_street){
				message +=  'Ingrese nombre de la calle <br>';
			}
			if(this.person && this.person.address && !this.person.address.exterior_number){
				message +=  'Ingrese número exterior <br>';
			}
			if(this.person && this.person.address && !this.person.address.post_code){
				message +=  'Ingrese Código postal <br>';
			}
			if(this.person && this.person.address && !this.person.address.cat_state){
				message +=  'Seleccione el estado <br>';
			}
			if(this.person && this.person.address && !this.person.address.cat_municipality){
				message +=  'Seleccione municipio <br>';
			}
			if(this.person && this.person.address && !this.person.address.cat_locality){
				message +=  'Seleccione localidad <br>';
			}
			
			if(message && message.length > 0){
				this.makeToast( {
					variant: 'danger',
					message: message,
					title: 'Datos incompletos'
				});
				return;
			}

			const data = {...this.person};
			if(this.valid_post_code && data.address.cat_locality){
				this.updatePerson({person: data, sync: false});
			}
		},
		changeDoc (doc, file) {
			const id_person = this.user.id_person;
			const id_cat_catalog_document = doc.cat_row_entity.id;
			this.updateDocumentPerson({id_person, id_cat_catalog_document, file});
		},
		confirmDeleteDoc(doc) {
			this.$bvModal.msgBoxConfirm('Confirme que desea eliminar la imagen.', Messaging.confirmConfig())
			.then(confirmed => {
				if(confirmed)
					this.deleteDocumentPerson({id_person: this.person._id, id_catalog:doc.cat_row_entity.id})
			})
		},
		identificationsDefault(){
			this.identifications.front = require("@/assets/img/ineanverso.png");
			this.identifications.back = require("@/assets/img/inereverso.png");
			this.identifications.profile = "https://www.w3schools.com/howto/img_avatar.png";
		}
	},
	watch: {
		image_profil: function(){
			const file = this.image_profil;
			const id_person = this.user.id_person;
			this.updateImageProfile({id_person, file});
		}
	}
};
</script>

