<template>
  <div v-if="alert.message" :class="`alert alert-${alert.type} alert-dismissible`" role="alert">
    <!-- {{alert.message}}-->
    <span v-html="alert.message"></span>
    <button @click="clearAlert" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!--
  <b-alert :show="alert.message?true:false"  :variant="alert.type"> 
    {{alert.message}}
  </b-alert> --> <!-- dismissible -->
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
      ...mapState({
          alert: state => state.alert
      })
  },
  methods: {
      ...mapActions({
          clearAlert: 'alert/clear' 
      })
  },
  watch: {
      /*
      'alert.state'() {
          // eslint-disable-next-line
          this.$bvToast.toast(`This is toast `, {
          title: 'BootstrapVue Toast',
          autoHideDelay: 5000,
          appendToast: append
        })
      },*/
      // eslint-disable-next-line
      $route (to, from){
          // clear alert on location change
          this.clearAlert();
      }
  } 
}
</script>
