var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",attrs:{"id":"contacto"}},[_c('h2',[_vm._v("Contacto")]),_c('div',{staticClass:"wrapper"},_vm._l((_vm.cat_contact),function(cat){return _c('div',{key:cat.id,staticClass:"card-contacto"},[_c('div',{staticClass:"contacto-titulo"},[_vm._m(0,true),_c('h4',[_vm._v(_vm._s(cat.label))])]),_c('div',{staticClass:"texto-card"},[_c('p',{domProps:{"innerHTML":_vm._s(cat.description)}})]),_c('div',{staticClass:"contactanos"},[_c('a',{staticClass:"custom-btn-gold",attrs:{"href":"#content"},on:{"click":function($event){return _vm.whatsapp(cat)}}},[_vm._v("Contáctanos")])])])}),0),_c('div',{staticClass:"formulario-container"},[_c('h2',[_vm._v("Escríbenos")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"formulario",attrs:{"autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('section',{staticClass:"contacto-form"},[_c('p',{staticClass:"formfield"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name),expression:"data.name"}],staticClass:"input-box",class:{ 'is-invalid':  errors[0] },attrs:{"type":"text","placeholder":"Escriba su Nombre Completo","id":"contactonombre","name":"nombre","required":""},domProps:{"value":(_vm.data.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "name", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"formfield"},[_c('label',{attrs:{"for":"telefono"}},[_vm._v("Teléfono")]),_c('ValidationProvider',{attrs:{"rules":"required|length:10","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],staticClass:"input-box",class:{ 'is-invalid':  errors[0] },attrs:{"type":"tel","placeholder":"Escriba su teléfono","id":"contactotelefono","name":"telefono","required":""},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "phone", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"formfield"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"input-box",class:{ 'is-invalid':  errors[0] },attrs:{"type":"email","id":"contactoemail","name":"email","placeholder":"Escriba su correo","required":""},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"formfield"},[_c('label',{attrs:{"for":"mensaje"}},[_vm._v("Mensaje")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.message),expression:"data.message"}],staticClass:"input-box",attrs:{"placeholder":"Escribe tu mensaje","rows":"5","cols":"33","id":"contactomensaje","name":"mensaje"},domProps:{"value":(_vm.data.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "message", $event.target.value)}}})]),_c('p',{staticClass:"formfield"},[_c('ValidationProvider',{attrs:{"rules":_vm.captcha? '':'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-recaptcha',{class:{ 'is-invalid':  errors[0] },attrs:{"sitekey":_vm.captchaKey,"loadRecaptchaScript":true},on:{"verify":function($event){_vm.captcha=true},"expired":function($event){_vm.captcha=false}}}),(!_vm.captcha)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('button',{staticClass:"enviar custom-btn-gold",attrs:{"type":"submit"}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.status.loading),expression:"status.loading"}],attrs:{"small":"","type":"grow"}}),_vm._v(" Enviar ")],1)])])]}}])}),_c('br'),_c('div',{staticClass:"row text-right",staticStyle:{"font-size":"12px","color":"gray"}},[_c('i',{staticClass:"fa fa-phone-square"}),_vm._v(" "+_vm._s(_vm.organization.long_code)+" | "),_c('i',{staticClass:"fa fa-envelope-square"}),_vm._v(" "+_vm._s(_vm.organization.alias3)+" ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whatsapp"},[_c('i',{staticClass:"fab fa-whatsapp"})])}]

export { render, staticRenderFns }