import { HttpClient } from './HttpClient' 

export const fin_ProductService = {
    getProductPublish: () => HttpClient.get(`/product/publish`),
    validateCodeAgent: (code_agent) => HttpClient.get('/validate/code/agent/' + code_agent),
    payingProduct: (provider, product, person, code_agent, user, response_payment, period_conf, amounts_product, payment_code, affiliation_detail_id, disbursment_date, generate_user, agent_data) => HttpClient.postJson('/paying/product', {provider: provider, product: product, person: person, code_agent: code_agent, user: user, response_payment: response_payment, period_conf:period_conf, amounts_product: amounts_product, payment_code, affiliation_detail_id, disbursment_date: disbursment_date, generate_user: generate_user, agent_data: agent_data}),
    payingProductProvider: (provider, product, person, code_agent, user, card, period_conf, amounts_product, payment_code, affiliation_detail_id, disbursment_date, agent_data) => HttpClient.postJson('/paying/provider/product', {provider: provider, product: product, person: person, code_agent: code_agent, user: user, card: card, period_conf: period_conf, amounts_product: amounts_product, payment_code, affiliation_detail_id, disbursment_date: disbursment_date, agent_data: agent_data}),
    getProductConfigurarionPublish: (id_product, id_period) => HttpClient.get(`/product/amount/period/`+id_product+'/'+id_period),
    generateReferenceProvider: (provider, person, product, code_agent, period_conf, amounts_product) => HttpClient.postJson('/generate/reference/product', {provider: provider, person: person, product: product, code_agent: code_agent, period_conf: period_conf, amounts_product: amounts_product }),
    getContractInfo: (id_credit_account, id_credit_account_detail) => HttpClient.get(`/product/contract/`+id_credit_account+'/'+ id_credit_account_detail),
    getCurrentContractInfo: (id_person) => HttpClient.get(`/product/contract_current/`+id_person),
    generateReferenceLendus: (provider, id_credit_account) => HttpClient.postJson('/generate/reference/product/lendus', {provider: provider, id_credit_account: id_credit_account}),
    saveAffiliation: (product, person, code_agent, period_conf, amounts_product) => HttpClient.postJson('/save/affiliation', {product: product, person: person, code_agent: code_agent, period_conf: period_conf, amounts_product: amounts_product}),
    saveRepuchase: (repuchase) => HttpClient.post('/product/repuchase', repuchase),
    getRepuchaseData: (id_credit_account) => HttpClient.get('/product/repuchase/data/' + id_credit_account),
};