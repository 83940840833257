<template>
    <div class="seccion" id="inicio">
        <div class="wrapper-header">
            <div class="letrashero">
                <h2>Planea anticipadamente</h2>
                <p>protege a los que amas</p>
                <p>por solo</p>
                <template v-if="products && products[0]" >
                    <h3>${{products[0].amount_fee}} MXN Anual</h3>
                    <button  @click="buying(products[0].id)" class="ctaheader" style="cursor:pointer">Contrata Ahora</button>
                </template>
            </div>
        </div>
        <Slider :sliders="sliders" />
        <div class="cta-one">
            <h1>Plan de Gastos Funerarios</h1>
            <p>En esos  momentos tan difíciles de la vida es indispensable tener el respaldo y el apoyo de expertos que puedan auxiliarte y encargarse de todos esos detalles que resultan complicados dada la situación, no te preocupes, estamos en esto juntos.</p>
        </div>
        <div class="planes-container">
            <div class="planes-one" ><!-- planes-hero1 -->
                 <img src="@/assets/img/nodinero.png" class="no-dinero">
                <div class="caracteristicas">
                    <h3>En Beluva por ningún motivo se aceptan pagos en efectivo.</h3>
                </div>
            </div>
            <div class="planes">
                <div @click="buying(product.id)" v-for="(product) of products" :key="product.id"  class="plan" style="cursor:pointer">
                    <img src="@/assets/img/logobelua.png" alt="">
                    <h3>{{product.label}}</h3>
                    <ul>
                        <li v-for="(desc) of JSON.parse(product.extended_description)" :key="desc.id"><i class="fas fa-dove"></i><p class="caracteristica-plan">{{desc.label}}</p></li>
                    </ul>
                    <hr>
                    <div class="precio"><h3>${{product.amount_fee}}</h3><p>/ Anual</p></div>
                </div>
                <img class="pagos-conekta" src="@/assets/img/pagos.png" alt="">
            </div>
            <div class="planes-hero">
                <i class="fas fa-file-medical-alt"></i>
                <div class="caracteristicas">
                    <h3>Cobertura para tu familia</h3>
                    <h3>Presencial Nacional</h3>
                    <h3>Trámite fácil y rápido</h3>
                    <h3>Asistencia Personalizada</h3>
                </div>

            </div>
        </div>

        <div class="boxes-container">
            <div class="boxes">
                <h2>Te Recomendamos lo siguiente:</h2>
                <div class="box-hero box-1">
                    <h3>Comunica a algún familiar cercano que cuentas con una póliza de Gastos Funerarios</h3><i class="fas fa-dove"></i>
                </div>
                <div class="box-hero box-2">
                    <h3>Contar con un testamento actualizado</h3><i class="fas fa-dove"></i>
                </div>
                <div class="box-hero box-3">
                    <h3>Conversa con los beneficiarios sobre los beneficios que tienen debido a la póliza de gastos funerarios</h3><i class="fas fa-dove"></i>
                </div>
            </div>
            <div class="image-desktop">
                <img class="img-one" src="@/assets/img/circle.jpg" alt="">
                <img class="img-two" src="@/assets/img/circletwo.jpg" alt="">
            </div>
        </div>



    </div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import { mapState, mapActions } from 'vuex'

export default {
    components:{
        Slider
    },
    data () {
        return {
            sliders: [
                {
                    source : require("../../assets/img/carousel/slider1.jpg"),
                    title: "Ausencia",
                    description:"El proyecto que necesitas en esos momentos difíciles"
                },
                {
                    source : require("../../assets/img/carousel/slider2.jpg"),
                    title: "Adultos mayores",
                    description:"El camino que todos debemos transitar"
                },
                {
                    source : require("../../assets/img/carousel/slider3.webp"),
                    title: "Pareja",
                    description:"Protección para tu compañero de vida"
                },
                {
                    source : require("../../assets/img/carousel/slider4.jpeg"),
                    title: "Pareja",
                    description:"Protección para tu compañero de vida"
                },
            ],
            coberturas:[
                /* "Ataúd estándar",
                "Sala de Velación",
                "Gestión de trámites",
                "Preparación estética del cuerpo",
                "Cremación del cuerpo y urna estándar en su caso",
                "Traslado dentro de la localidad, Municipio o Zona Conurbada donde éste se encuentre",
            */]
        }
    },
    computed: {
        ...mapState({
            products: state => state.Home.products.items,
            profile: state => state.Profil,
        }),
        ...mapState('account', ['status', 'user']),
    },
    created () {
        this.getProducts();
        this.getPerson(this.user);
    },
    methods: {
        ...mapActions('Home', ['getProducts']),
        ...mapActions('Profil', ['changeTabEvent', 'getPerson']),
        ...mapActions('account', ['userBuying']),
        buying(id_product){
            /*let data = {
                status: true, id_product: id_product
            }
            if(!this.status.loggedIn){
                this.userBuying(data);
                this.$router.push('/login');
            } else */if (this.profile && this.profile.person && this.profile.person.item && this.profile.person.item.affiliation.length > 0) {
                this.changeTabEvent(2);
                this.$router.push('/profile');
            } else {
                this.$router.push('/product/' + id_product);   
            }
        }
    }
};
</script>
