export const UtilFile = {
    choosenFileTobs64,
    FileTobs64
};

function choosenFileTobs64(event) {
    var files = event.target.files || event.dataTransfer.files;
    return  FileTobs64(files[0]);   
}

function FileTobs64(File) {

    return new Promise((resolve, reject) => {
        if( !File){ // native on cahange
            reject('Not files found');    
        }

        let file = {}
        var reader = new FileReader();
        reader.onload = (e) => {
            file.label =File.name;
            // file.size = event.target.files[0].size;
            file.content_type = File.type;
            file.mime_type = File.type;
            file.extention = File.name.split('.').pop();
            file.path_file = e.target.result;
            file.is_blob = false;
            file.default_file = false;
            file.is_image = true; // pendiente sacarlo de la base64
            file.is_private = false;
            resolve(file);
        };
        reader.readAsDataURL(File);
    });
}
