import { HttpClient } from './HttpClient' 

export const cat_CatalogService = {
    getCat_CatalogById: (id) => HttpClient.get(`/cat_catalog/id/${id}`),
    getCat_CatalogByCodeType: (code) => HttpClient.get(`/cat_catalog/code_type/${code}`),
    getCat_CatalogByCode: (code) => HttpClient.get(`/cat_catalog/code/${code}`),
    getCat_CatalogByIdParent: (id) => HttpClient.get(`/cat_catalog/id_parent/${id}`),
    getCat_CatalogAddresByPostCode: (post_code) => HttpClient.get(`/cat_catalog/addresbypostcode/${post_code}`),    
    getOrganization: () => HttpClient.get(`/organization/data`),
};
