
import Vue from 'vue';

export const Messaging = {
    createMessage,
    makeToast,
    confirmConfig,
};

function createMessage(message, type="succes") {
    return {
        type: type,
        message: message,
        autodismiss: false,
    }   
}

function makeToast(message, title, variant = null) {
    const vm = new Vue()
    let title_default = 'Alerta';
    if(variant === 'danger'){
        title_default = 'Error';
    }
    vm.$bvToast.toast(message, {
        title: `${title || title_default}`,
        variant: variant,
        solid: true
    })
}

function confirmConfig(title) {
    return {
        title: title || 'Confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'custom-btn custom-btn danger',
        okTitle: 'Si',
        cancelTitle: 'No',
        cancelVariant: 'custom-btn custom-btn success',
        headerClass: 'custom-modal-header',
        // footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true
    }
}
