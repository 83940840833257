<template>
	<div class="notificaciones">
		<h2>Notificaciones</h2>
		<div></div>
		<div class="notificaciones-wrapper">
			<div v-for="item in messages.items" v-bind:key="item.id"  :class="['notificacion', item.recipients[0].is_remarkable? 'importante': '']">
				<div class="icono">
                    <i @click="highlightMessage({item, action: 'IMPORTANT'})" :class="['fas fa-thumbtack', item.recipients[0].is_remarkable? 'pinned': '']" title="Marcar como importante"></i>
                </div>
                <div class="icono">
                    <i @click="highlightMessage({item, action:'READ'})" :class="item.recipients[0].is_read? 'fas fa-envelope-open-text': 'fas fa-envelope'" title="Marcar como leido"></i>
                </div>
				<div class="mensaje" >
					{{item.message_body}}
				</div>
			</div>
            <div class="">
                <b-pagination
                v-if="messages.pagination.total_rows>0"
                v-model="messages.pagination.current"
                :total-rows="messages.pagination.total_rows"
                :per-page="messages.pagination.row"
                @change="getConversationPage"
                :disabled="messages.loading"
                align="center"
                pills
                ></b-pagination>
            </div>
		</div>
	</div>
</template>

<script>
// import AlertMessage from '@/components/AlertMessage.vue'
import { mapState, mapActions } from 'vuex'

export default {
    data(){
		return {
            image_doc: null,
            file_selected: null,
		}
	},
    computed: {
    ...mapState({
			user: state => state.account.user,
			person: state => state.Message.person,
			person_session: state => state.Message.person_session,
			messages: state => state.Message.messages
        })
    },
    created () {
        this.getConversationPage(1);    
    },
    methods: {
        getConversationPage(page){
            this.getConversation({page, id_person: this.user.id_person});
        },
        ...mapActions('Message', [
                'getConversation',
                'sendComment',
                'readAll',
                'highlightMessage',
                'readMessage'
        ]),
    }
};
</script>