<template>
  <div>
    
    <BeneficiaryForm
        :id="idBeneficiary"
        v-on:updateList="updateList($event)"
        v-on:cancelRegister="cancelRegister($event)"
        v-if="activeFormAdd"
      />
    <div  v-if="!activeFormAdd">
        <h2 class="titulo__tab">
            Beneficiarios
        </h2>
        <table>
            <thead>
              <tr>
                <th scope="col">No. de Afiliación</th>
                <th scope="col">Nombre</th>
                <th scope="col">Fecha de nacimiento</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Relación</th>
                <th scope="col">Status</th>
                <!-- <th scope="col" colspan="2"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in lst_beneficiary" v-bind:key="item.id">
                <td scope="row" data-label="No. de Afiliación">{{key +1}}</td>
                <td data-label="Nombre">{{ item.name_completed}}</td>
                <td data-label="Fecha de nacimiento">{{ item.birthdate | sqlToCustomDate }}</td>
                <td data-label="Teléfono">{{ item.phone1 ? item.phone1 : "" }}</td>
                <td data-label="Relación">{{ item.relation }}</td>
                <td data-label="Status">
                  <span class="badge" v-bind:class="(!item.status || item.status == 'ACTIVE') ? 'badge-success' : 'badge-danger'">
                    {{item.status == 'ACTIVE' ?  'Activo': 'Baja'}}
                  </span>
                </td>
                
                <td style="display:none">
                  <i v-if="item.status == 'ACTIVE'" @click="editBeneficiary(item)" class="fa fa-pen"></i>
                  <i v-if="item.status == 'ACTIVE'" @click="confirmBeneficiary(item)" class="fa fa-times text-danger"></i>
                </td>
                
              </tr>
            </tbody>
          </table>
          <button  v-on:click="addBeneficiary" class="button">Agregar Beneficiario</button>
    </div>
  </div>
</template>

<script>
//import {  mapState, mapActions } from "vuex";
import BeneficiaryForm from "./BeneficiaryForm.vue";
import { mapState, mapActions } from "vuex";
import { Messaging } from '@/helpers';

export default {
  components: {
    BeneficiaryForm,
  },
  data() {
    return {
      person: {
        name: "",
        lastname: "",
        second_lastname: "",
        birthdate: "",
      },
      birth_date_error: "",
      submitted: false,
      captcha: false,
      activeFormAdd: false,
      idBeneficiary: ''
    };
  },
  computed: {
    ...mapState({
      lst_beneficiary: (state) => state.Beneficiary.beneficiarys.items,
      requesting_beneficiary: (state) => state.Beneficiary.beneficiarys.requesting,
      user: state => state.account.user,
    }),
  },
  created() {},
  mounted() {
    this.getallBeneficiarysByPerson(this.user.id_person);
  },
  methods: {
    ...mapActions("Beneficiary", [
      "getallBeneficiarysByPerson",
      "deleteBeneficiaryByPerson"
    ]),
    updateList() {
      this.activeFormAdd = false;
    },
    addBeneficiary() {
      this.idBeneficiary = '';
      this.activeFormAdd = true;
    },
    cancelRegister(){
      this.activeFormAdd = false;
    },
    async confirmBeneficiary(beneficiary) {
      let ts = this;
      let nameBeneficiary = (beneficiary.name ? beneficiary.name : "") + " " + (beneficiary.lastname ? beneficiary.lastname : "") + " " + (beneficiary.second_lastname ? beneficiary.second_lastname : "");
      this.$bvModal.msgBoxConfirm("Desea eliminar al beneficiario: " + nameBeneficiary, Messaging.confirmConfig())
			.then(confirmed => {
        if(confirmed)
          ts.deleteBeneficiaryByPerson({id_person: ts.user.id_person, id_beneficiary: beneficiary.id});
          ts.updateList();
			})
    },
    editBeneficiary(item){
      this.idBeneficiary = item.id;
      this.activeFormAdd = true;
    }
  },
};
</script>