<template>
  <div v-show="modal.show">
    <!--<b-button @click="modalShow = !modalShow">Open Modal</b-button>-->
    <!-- :variant="'primary'"
    :title="modal.title"
    :header-bg-variant="modal.type" -->
    <b-modal
    v-model="modal.show"
    :header-bg-variant="modal.type"
    >

    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      
      <h5 class="text-light">{{modal.title}}</h5>
      <b-icon icon="x-circle" class="text-light" style="cursor:pointer;" @click="close()"></b-icon>
    </template>
    
    {{modal.message}}

    <template #modal-footer="{ ok, close }">
      <b-button size="md" variant="primary" @click="close()">
        Cancelar
      </b-button> 
      <b-button size="md" variant="primary" @click="ok()">
        {{modal.btn_ok}}
      </b-button>      
    </template>
    
    </b-modal>

    

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
      ...mapState({
          modal: state => state.modal
      })
  },
  methods: {
      ...mapActions({
          close: 'modal/close' 
      }),
  },
  watch: {
    // eslint-disable-next-line
      $route (to, from){
          this.close();
      }
  } 
}
</script>