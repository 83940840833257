<template>
    <div class="back">
        <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 750 471" style="enable-background:new 0 0 750 471;" xml:space="preserve">
            <g id="Front">
                <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
            </g>
            <g id="Back">
                <g id="Page-1_2_">
                    <g id="amex_2_">
                        <path id="Rectangle-1_2_" class="darkcolor" :class="`${color}dark`" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z" />
                    </g>
                </g>
                <rect y="61.6" class="st2" width="750" height="78" />
                <g>
                    <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5C707.1,246.4,704.4,249.1,701.1,249.1z" />
                    <rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
                    <rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
                    <path class="st5" d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                </g>
                <text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" class="st6 st7">{{ security }}</text>
                <!--
                <g class="st8">
                    <text transform="matrix(1 0 0 1 518.083 280.0879)" class="st9 st6 st10">security code</text>
                </g>
                -->
                <rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
                <rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
                <!--<text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" class="st12 st13">{{ nameBack }}</text>-->
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        nameBack: {
            type: String,
            default: 'John Doe'
        },
        security: {
            type: String,
            default: '985'
        },
        color: {
            type: String,
            default: 'greydark'
        }
    }
}
</script>

<style scoped>
.vue-credit-card {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.vue-credit-card .card-form-and-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vue-credit-card .card-form-and-image .credit-card-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  max-width: 400px;
  padding: 20px;
  color: #707070;
}
.vue-credit-card .card-form-and-image .credit-card-form .field-group {
  display: flex;
}
.vue-credit-card .card-form-and-image .credit-card-form .field-group .field:first-child {
  margin-right: 10px;
}
.vue-credit-card .card-form-and-image .credit-card-form .field {
  position: relative;
  width: 100%;
  margin: 10px 0;
}
.vue-credit-card .card-form-and-image .credit-card-form .field label {
  padding-bottom: 5px;
  font-size: 13px;
}
.vue-credit-card .card-form-and-image .credit-card-form .field input {
  box-sizing: border-box;
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.vue-credit-card .ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 9px);
  width: 60px;
}
.vue-credit-card .credit-card-image {
  width: 100%;
  max-width: 400px;
  max-height: 251px;
  height: 54vw;
  padding: 20px;
  perspective: 1000px;
}
.vue-credit-card #ccsingle {
  position: absolute;
  right: 15px;
  top: 20px;
}
.vue-credit-card #ccsingle svg {
  width: 100px;
  max-height: 60px;
}
.vue-credit-card .creditcard {
  width: 100%;
  max-width: 400px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}
.vue-credit-card .creditcard .front,
.vue-credit-card .creditcard .back {
  position: absolute;
  width: 100%;
  max-width: 400px;
  backface-visibility: hidden;
  color: #47525d;
}
.vue-credit-card .creditcard .back {
  transform: rotateY(180deg);
}
.vue-credit-card .creditcard.flipped {
  transform: rotateY(180deg);
}
.vue-credit-card .creditcard svg#cardfront,
.vue-credit-card .creditcard svg#cardback {
  width: 100%;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
}
.vue-credit-card .creditcard .lightcolor,
.vue-credit-card .creditcard .darkcolor {
  transition: fill 0.5s;
}
.vue-credit-card .creditcard .lightblue {
  fill: #03A9F4;
}
.vue-credit-card .creditcard .lightbluedark {
  fill: #0288D1;
}
.vue-credit-card .creditcard .red {
  fill: #ef5350;
}
.vue-credit-card .creditcard .reddark {
  fill: #d32f2f;
}
.vue-credit-card .creditcard .purple {
  fill: #ab47bc;
}
.vue-credit-card .creditcard .purpledark {
  fill: #7b1fa2;
}
.vue-credit-card .creditcard .cyan {
  fill: #26c6da;
}
.vue-credit-card .creditcard .cyandark {
  fill: #0097a7;
}
.vue-credit-card .creditcard .green {
  fill: #66bb6a;
}
.vue-credit-card .creditcard .greendark {
  fill: #388e3c;
}
.vue-credit-card .creditcard .lime {
  fill: #d4e157;
}
.vue-credit-card .creditcard .limedark {
  fill: #afb42b;
}
.vue-credit-card .creditcard .yellow {
  fill: #ffeb3b;
}
.vue-credit-card .creditcard .yellowdark {
  fill: #f9a825;
}
.vue-credit-card .creditcard .orange {
  fill: #ff9800;
}
.vue-credit-card .creditcard .orangedark {
  fill: #ef6c00;
}
.vue-credit-card .creditcard .grey {
  fill: #bdbdbd;
}
.vue-credit-card .creditcard .greydark {
  fill: #616161;
}
.vue-credit-card #svgname {
  text-transform: uppercase;
}
.vue-credit-card #cardfront .st2 {
  fill: #FFFFFF;
}
.vue-credit-card #cardfront .st3 {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}
.vue-credit-card #cardfront .st4 {
  font-size: 54.7817px;
}
.vue-credit-card #cardfront .st5 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}
.vue-credit-card #cardfront .st6 {
  font-size: 33.1112px;
}
.vue-credit-card #cardfront .st7 {
  opacity: 0.6;
  fill: #FFFFFF;
}
.vue-credit-card #cardfront .st8 {
  font-size: 24px;
}
.vue-credit-card #cardfront .st9 {
  font-size: 36.5498px;
}
.vue-credit-card #cardfront .st10 {
  font-family: "Source Code Pro", monospace;
  font-weight: 300;
}
.vue-credit-card #cardfront .st11 {
  font-size: 16.1716px;
}
.vue-credit-card #cardfront .st12 {
  fill: #4C4C4C;
}
.vue-credit-card #cardback .st0 {
  fill: none;
  stroke: #0F0F0F;
  stroke-miterlimit: 10;
}
.vue-credit-card #cardback .st2 {
  fill: #111111;
}
.vue-credit-card #cardback .st3 {
  fill: #F2F2F2;
}
.vue-credit-card #cardback .st4 {
  fill: #D8D2DB;
}
.vue-credit-card #cardback .st5 {
  fill: #C4C4C4;
}
.vue-credit-card #cardback .st6 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}
.vue-credit-card #cardback .st7 {
    font-weight: bold;
  font-size: 27px;
}
.vue-credit-card #cardback .st8 {
  opacity: 0.6;
}
.vue-credit-card #cardback .st9 {
  fill: #FFFFFF;
}
.vue-credit-card #cardback .st10 {
  font-size: 24px;
}
.vue-credit-card #cardback .st11 {
  fill: #EAEAEA;
}
.vue-credit-card #cardback .st12 {
  font-family: "Rock Salt", cursive;
}
.vue-credit-card #cardback .st13 {
  font-size: 37.769px;
}
</style>
