const state = {
    type: null,
    message: null,
    code: null,
};

const actions = {
    success({ commit }, alert_data) {
        commit('success', alert_data);
    },
    error({ commit }, alert_data) {
        commit('error', alert_data);
    },
    warning({ commit }, alert_data) {
        commit('warning', alert_data);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, alert_data) {
        state.type = 'success';
        state.message = alert_data.message;
        state.code = alert_data.code;
    },
    error(state, alert_data) {
        state.type = 'danger';
        state.message = alert_data.message;
        state.code = alert_data.code;
    },
    warning(state, alert_data) {
        state.type = 'warning';
        state.message = alert_data.message;
        state.code = alert_data.code;
    },
    clear(state) {
        state.type = null;
        state.message = null;
        state.code = null;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};