<template>
	<ValidationObserver v-slot="{ handleSubmit }">
	<form autocomplete="on"  @submit.prevent="handleSubmit(onSubmit)" class="notificaciones" v-if="person">
		<AlertMessage v-if="alert.code == 'notification'"/>
		<section class="datoscuenta">
			<h2 class="titulo__tab">
				Notificaciones por correo
			</h2>
			<p class="formfield">
				<label for="notiemail">Correo electrónico para notificaciones</label>
				<ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
					<input v-model="person.email" :disabled="user.email? true:false"  :class="[{ 'is-invalid':  errors[0] }, 'input-box']" type="email" id="notiemail" name="notiemail">
					<span class="invalid-feedback" >{{ errors[0] }}</span>
				</ValidationProvider>
			</p>
			<h2 class="titulo__tab">
				Notificaciones al teléfono
			</h2>
			<p class="formfield">
				<label for="notitel">Teléfono para enviar notificaciones</label>
				<ValidationProvider rules="required|length:10" :bails="false" v-slot="{ errors }">
					<input v-model="person.phone1" :class="[{ 'is-invalid':  errors[0] }, 'input-box']"  type="tel" id="notitel" name="notitel">
					<span class="invalid-feedback" >{{ errors[0] }}</span>
				</ValidationProvider>
			</p>

			<h2 class="titulo__tab">
				Preferencias
			</h2>

			<p class="formfield">                           
				<!-- <input class="checkbox" type="checkbox" id="noticorreo" name="noticorreo"> -->
				<b-form-checkbox
				id="checkAutoGroup"
				v-model="person.notification_email"
				name="checkAutoGroup"
				value="true"
				unchecked-value="false"
				>
				Solo recibir notificaciones por correo
				</b-form-checkbox>
			</p>
			<p class="formfield">   
				<b-form-checkbox
				id="checkAutoProduct"
				v-model="person.notification_phone"
				name="checkAutoProduct"
				value="true"
				unchecked-value="false"
				>
				Solo recibir notificaciones al celular
				</b-form-checkbox>			
			</p>
			
			<button class="button" type="submit" >
				<b-spinner v-show="_person.updating" small type="grow"></b-spinner>
				Guardar
			</button>
		</section>
	</form>
    </ValidationObserver>
</template>

<script>
import AlertMessage from '@/components/AlertMessage.vue'
import { mapState, mapActions } from 'vuex'

export default {
	components:{
        AlertMessage
	},
    computed: {
        ...mapState({
			person: (state) => state.Profil.person.item,
			_person: state => state.Profil.person,
			alert: (state) => state.alert,
		}),
		...mapState('account', ['user']),
    },
    created () {
	},
    methods: {
		...mapActions('Profil', [
			'getPerson',
			'updatePreferences'
		]),
		onSubmit () {
			this.updatePreferences({...this.person});
		},
	}
};
</script>