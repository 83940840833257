<template>
  <div id="pago-tarjeta">
        <div class="header"><h2>Compra tu paquete familiar!</h2>
        <div><slot name="form-header" ></slot></div>
        </div>
        
        <div class="card-tarjeta">
          <p style="font-size: 12px; text-align:center;">Transacciones procesadas con</p>
          <img class="pago-seguro" src="@/assets/img/openpay_logo.svg" width="140" height="80" alt="" />
            <div class="card">
              <div class="show-desktop hide-mobile">
                <div class="credit-card-image" v-if="!noCard && direction == 'column'">
                  <div class="creditcard" :class="{flipped}" @click="flipped = !flipped">
                      <Front :cardNumber="form.cardNumber"
                          :expiration="form.expiration"
                          :name="form.name"
                          :isTwoDigitsYear="isTwoDigitsYear"
                          :color="color">
                          <div slot="card-icon" id="ccsingle">
                              <component :is="cardInnerIcon"/>
                          </div>
                      </Front>
                      <Back :nameBack="form.name"
                          :security="form.security"
                          :color="color"/>
                  </div>
                </div>
                <div class="vue-credit-card credit-card-image col-md-6" v-if="!noCard && direction == 'row'">
                  <div class="creditcard" :class="{flipped}" @click="flipped = !flipped">
                      <Front :cardNumber="form.cardNumber"
                          :expiration="form.expiration"
                          :name="form.name"
                          :isTwoDigitsYear="isTwoDigitsYear"
                          :color="color">
                          <div slot="card-icon" id="ccsingle">
                              <component :is="cardInnerIcon"/>
                          </div>
                      </Front>
                      <Back :nameBack="form.name"
                          :security="form.security"
                          :color="color"/>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="pago-tarjeta">
            <div class="formulario">
            <section class="pagotarjeta-form">
        
                <p class="formfield">
                <label for="pago-nombre">Nombre Completo</label>
                <input
                    autocomplete="off"
                    class="input-box"
                    type="text"
                    placeholder="Escriba su Nombre Completo"
                    id="pago-nombre"
                    required
                    v-model="form.name"
                    @focus="flipped = false"
                    :class="['input-box',{ 'is-invalid': error.name && !form.name.length }]"
                    
                />
                </p>
                <p class="formfield">
                <label for="pago-numerotarjeta">Número de tarjeta</label>
                <input
                    autocomplete="off"
                    class="input-box"
                    type="tel"
                    id="pago-numerotarjeta"
                    name="pago-numerotarjeta"
                    required
                    ref="cardNumber"
                    pattern="[0-9 ]*"
                    inputmode="numeric"
                    :placeholder="trans.card.placeholder"
                    @focus="flipped = false"
                    :class="{ 'is-invalid': error.cardNumber && !form.cardNumber.length }"
                />
                <span v-show="error.cardNumber && !form.cardNumber.length" class="invalid-feedback">El número de tarjeta es requerido</span>
                </p>
                <p class="formfield col-2">
                <label for="pago-expiracion">{{ trans.expiration.label}} (mm/{{isTwoDigitsYear ? 'yy' : 'yyyy'}})</label>
                <input
                    autocomplete="off"
                    class="input-box"
                    type="text"
                    id="pago-expiracion"
                    name="pago-expiracion"
                    :placeholder="isTwoDigitsYear ? 'MM/YY' : 'MM/YYYY'"
                    required
                    pattern="[0-9\/]*"
                    ref="expiration"
                    inputmode="numeric"
                    @focus="flipped = false"
                    :class="{ 'is-invalid': error.expiration && !form.expiration.length }"
                />
                <span v-show="error.expiration && !form.expiration.length" class="invalid-feedback">La expiración es requerida</span>
                </p>
                <p class="formfield col-2">
                <label for="pago-codigo">{{ trans.security.label}}</label>
                <input
                    autocomplete="off"
                    class="input-box"
                    type="text"
                    id="pago-codigo"
                    name="pago-codigo"
                    required
                    ref="security"
                    pattern="[0-9]*"
                    :placeholder="trans.security.placeholder"
                    inputmode="numeric"
                    @focus="flipped = true"
                    :class="{ 'is-invalid': error.security && !form.security.length }"
                />
                <span v-show="error.security && !form.security.length" class="invalid-feedback">El código de seguridad es requerido</span>
                </p>
            </section>
            <div class="btns">
              <!--
                <button type="submit" :disabled="!card_error || loading" @click="paying()" class="btn-pago pagar" style="float:left">
                    <b-spinner v-show="loading" small type="grow"></b-spinner> Pagar
                </button>
                <button  @click="close()" formnovalidate class="btn-pago cancelar" >Cancelar</button>
              -->
              <slot name="form-footer" ></slot>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import IMask from 'imask';
import cardTypes from './cardTypes';
import {cardMasks, expirationMask, securityMask} from './masks';
import {Front, Back} from './card_components/cards';
import * as InputIcons from './card_components/icons';
import * as CardIcons from './card_components/singles';

const formDefaults = {
    name: '',
    cardNumber: '',
    expiration: '',
    security: ''
};

const masksDefaults = {
    cardNumberMask: null,
    expirationDateMask: null,
    securityCodeMask: null
};

const defaultColor = 'grey';

const defaultTranslations = {
    name: {
        label: 'Name',
        placeholder: 'Full Name'
    },
    card: {
        label: 'Card Number',
        placeholder: 'Card Number'
    },
    expiration: {
        label: 'Expiration'
    },
    security: {
        label: 'Security Code',
        placeholder: 'Code'
    }
}

export default {
    props: {
        trans: {
            type: Object,
            default: () => (defaultTranslations)
        },
        direction: {
            type: String,
            default: 'column',
            validator(value) {
                return ['row', 'column'].includes(value)
            }
        },
        className: {
            type: String,
            default: ''
        },
        noCard: {
            type: Boolean,
            default: false
        },
        yearDigits: {
            type: Number,
            default: 2,
            validator(value) {
                return [2, 4].includes(value)
            }
        },
        person: {
          type: Object,
          required: false,
        }
    },
    mounted() {
        this.clear();
        this.defineMasks();
        this.setMasksListeners();
    },
    data() {
        return {
            flipped: false,
            cardType: null,
            cardIcon: null,
            cardInnerIcon: null,
            color: 'grey',
            masks: masksDefaults,
            form: formDefaults,
            error: {
              name: false,
              cardNumber: false,
              expiration: false,
              security: false
            }
        };
    },
    methods: {
        clear(){
          this.form.name = '';
          this.form.cardNumber = '';        
          this.form.expiration = '';
          this.form.security = '';
        },
        defineMasks() {
            //Mask the Credit Card Number Input
            this.cardNumberMask = new IMask(this.$refs.cardNumber, cardMasks);

            //Mask the Expiration Date
            this.expirationDateMask = new IMask(this.$refs.expiration, expirationMask(this.isTwoDigitsYear));

            //Mask the security code
            this.securityCodeMask = new IMask(this.$refs.security, securityMask);
        },
        setMasksListeners() {
            // Update expiration date field
            this.expirationDateMask.on('accept', () => this.form.expiration = this.expirationDateMask.value);

            // Update security code field
            this.securityCodeMask.on('accept', () => this.form.security = this.securityCodeMask.value);

            // Update card number field and card icon
            this.cardNumberMask.on('accept', () => {
                const cardName = this.cardNumberMask.masked.currentMask.cardtype;
                this.form.cardNumber = this.cardNumberMask.value;

                if (cardName && cardName != 'Unknown') {
                    const card = cardTypes[cardName];

                    this.cardIcon = InputIcons[card.name];
                    this.cardInnerIcon = CardIcons[card.name];
                    this.cardType = card.name;
                    this.setColor(card.color);
                    return;
                }

                this.resetCardDefaults();
            });
        },
        resetCardDefaults() {
            this.cardIcon = null;
            this.cardInnerIcon = null;
            this.cardType = null;
            this.setColor();
        },
        setColor(colorName) {
            this.color = colorName || defaultColor;
        },
        copy(){
          let complete_name = (this.person.name ? this.person.name : '') + ' ' + (this.person.lastname ? this.person.lastname : '') + ' ' + (this.person.second_lastname ? this.person.second_lastname : '');
          this.form.name = complete_name.trim();
        }
    },
    computed: {
        isTwoDigitsYear() {
            return this.yearDigits === 2;
        },
        fields() {
            return [this.form.name, this.form.cardNumber, this.form.expiration, this.form.security].join('');
        },
    },
    watch: {
        fields() {
            this.$emit('change', Object.assign({}, this.$data.form));
        },
        cardType(val) {
            this.$emit('cardChanged', val);
        },
        'form.name': function () {
          this.error.name = true;
        },
        'form.cardNumber': function () {
          this.error.cardNumber = true;
        },
        'form.expiration': function () {
          this.error.expiration = true;
        },
        'form.security': function () {
          this.error.security = true;
        }
    },
    components: {
        Front,
        Back
    }
}
</script>

<style scoped>
.vue-credit-card {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.vue-credit-card .card-form-and-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vue-credit-card .card-form-and-image .credit-card-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  max-width: 400px;
  color: #707070;
}
.vue-credit-card .card-form-and-image .credit-card-form .field-group {
  display: flex;
}
.vue-credit-card .card-form-and-image .credit-card-form .field-group .field:first-child {
  margin-right: 10px;
}
.vue-credit-card .card-form-and-image .credit-card-form .field {
  position: relative;
  width: 100%;
  margin: 10px 0;
}
.vue-credit-card .card-form-and-image .credit-card-form .field label {
  padding-bottom: 5px;
  font-size: 13px;
}
.vue-credit-card .card-form-and-image .credit-card-form .field input {
  box-sizing: border-box;
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.vue-credit-card .ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 9px);
  width: 60px;
}
.vue-credit-card .credit-card-image {
  width: 100%;
  max-width: 400px;
  max-height: 251px;
  height: 54vw;
  perspective: 1000px;
}
.vue-credit-card #ccsingle {
  position: absolute;
  right: 15px;
  top: 20px;
}
.vue-credit-card #ccsingle svg {
  width: 100px;
  max-height: 60px;
}
.vue-credit-card .creditcard {
  width: 100%;
  max-width: 400px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}
.vue-credit-card .creditcard .front,
.vue-credit-card .creditcard .back {
  position: absolute;
  width: 100%;
  max-width: 400px;
  backface-visibility: hidden;
  color: #47525d;
}
.vue-credit-card .creditcard .back {
  transform: rotateY(180deg);
}
.vue-credit-card .creditcard.flipped {
  transform: rotateY(180deg);
}
.vue-credit-card .creditcard svg#cardfront,
.vue-credit-card .creditcard svg#cardback {
  width: 100%;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
}
.vue-credit-card .creditcard .lightcolor,
.vue-credit-card .creditcard .darkcolor {
  transition: fill 0.5s;
}
.vue-credit-card .creditcard .lightblue {
  fill: #03A9F4;
}
.vue-credit-card .creditcard .lightbluedark {
  fill: #0288D1;
}
.vue-credit-card .creditcard .red {
  fill: #ef5350;
}
.vue-credit-card .creditcard .reddark {
  fill: #d32f2f;
}
.vue-credit-card .creditcard .purple {
  fill: #ab47bc;
}
.vue-credit-card .creditcard .purpledark {
  fill: #7b1fa2;
}
.vue-credit-card .creditcard .cyan {
  fill: #26c6da;
}
.vue-credit-card .creditcard .cyandark {
  fill: #0097a7;
}
.vue-credit-card .creditcard .green {
  fill: #66bb6a;
}
.vue-credit-card .creditcard .greendark {
  fill: #388e3c;
}
.vue-credit-card .creditcard .lime {
  fill: #d4e157;
}
.vue-credit-card .creditcard .limedark {
  fill: #afb42b;
}
.vue-credit-card .creditcard .yellow {
  fill: #ffeb3b;
}
.vue-credit-card .creditcard .yellowdark {
  fill: #f9a825;
}
.vue-credit-card .creditcard .orange {
  fill: #ff9800;
}
.vue-credit-card .creditcard .orangedark {
  fill: #ef6c00;
}
.vue-credit-card .creditcard .grey {
  fill: #bdbdbd;
}
.vue-credit-card .creditcard .greydark {
  fill: #616161;
}
.vue-credit-card #svgname {
  text-transform: uppercase;
}
.vue-credit-card #cardfront .st2 {
  fill: #FFFFFF;
}
.vue-credit-card #cardfront .st3 {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}
.vue-credit-card #cardfront .st4 {
  font-size: 54.7817px;
}
.vue-credit-card #cardfront .st5 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}
.vue-credit-card #cardfront .st6 {
  font-size: 33.1112px;
}
.vue-credit-card #cardfront .st7 {
  opacity: 0.6;
  fill: #FFFFFF;
}
.vue-credit-card #cardfront .st8 {
  font-size: 24px;
}
.vue-credit-card #cardfront .st9 {
  font-size: 36.5498px;
}
.vue-credit-card #cardfront .st10 {
  font-family: "Source Code Pro", monospace;
  font-weight: 300;
}
.vue-credit-card #cardfront .st11 {
  font-size: 16.1716px;
}
.vue-credit-card #cardfront .st12 {
  fill: #4C4C4C;
}
.vue-credit-card #cardback .st0 {
  fill: none;
  stroke: #0F0F0F;
  stroke-miterlimit: 10;
}
.vue-credit-card #cardback .st2 {
  fill: #111111;
}
.vue-credit-card #cardback .st3 {
  fill: #F2F2F2;
}
.vue-credit-card #cardback .st4 {
  fill: #D8D2DB;
}
.vue-credit-card #cardback .st5 {
  fill: #C4C4C4;
}
.vue-credit-card #cardback .st6 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}
.vue-credit-card #cardback .st7 {
  font-size: 27px;
}
.vue-credit-card #cardback .st8 {
  opacity: 0.6;
}
.vue-credit-card #cardback .st9 {
  fill: #FFFFFF;
}
.vue-credit-card #cardback .st10 {
  font-size: 24px;
}
.vue-credit-card #cardback .st11 {
  fill: #EAEAEA;
}
.vue-credit-card #cardback .st12 {
  font-family: "Rock Salt", cursive;
}
.vue-credit-card #cardback .st13 {
  font-size: 37.769px;
}
</style>