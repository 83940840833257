const state = {
    type: null,
    message: null,
    title: null,
    btn_ok: null,
    show: false,
};

const actions = {
    success({ commit }, params) {
        commit('success', params);
    },
    error({ commit }, params) {
        commit('error', params);
    },
    primary({ commit }, params) {
        commit('primary', params);
    },
    close({ commit }) {
        commit('close');
    }
};

const mutations = {
    success(state, params) {
        state.type = 'success';
        state.title = params.title;
        state.message = params.message;
        state.btn_ok = params.btn_ok || 'aceptar';
        state.show = true;
    },
    error(state, params) {
        state.type = 'danger';
        state.title = params.title;
        state.message = params.message;
        state.btn_ok = params.btn_ok || 'aceptar';
        state.show = true;
    },
    primary(state, params) {
        state.type = 'primary';
        state.title = params.title;
        state.message = params.message;
        state.btn_ok = params.btn_ok || 'aceptar';
        state.show = true;
    },
    close(state) {
        state.type = null;
        state.message = null;
        state.show = false;
    }
};

export const modal = {
    namespaced: true,
    state,
    actions,
    mutations
};