<template>
    <div>
        <Header title="Beluva" />
        <div 
        :class="isAcountPage()? 'app-container': ''" 
        :id="isAcountPage() ? 'app-container' : 'static-container'">
            <router-view/>
        </div>
        <Footer/>
    </div>
</template>

<style >
@import './assets/css/styles.css';
@import './assets/css/styles-static.css';
/* @import './assets/css/animate.css';*/
@import './assets/css/custom.css';
@import './assets/css/modales.css';
@import './assets/css/boostrap_part.css';

</style>
<!--
<style lang="scss">
    @import './assets/css/custom.scss';
    @import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>
-->
<script>
import {Footer, Header} from '@/views/template';
import router from '@/router'
export default {
    components:{
        Footer,
		Header
	},
    methods:{
        isAcountPage: function(){
            return router.currentRoute.fullPath.includes('profile');
        }
    }
};
</script>